import { createGlobalStyle } from "styled-components";
import InterRegular from "../assets/fonts/Inter-Regular.ttf";
import InterMedium from "../assets/fonts/Inter-Medium.ttf";
import InterSemiBold from "../assets/fonts/Inter-SemiBold.ttf";

const Typography = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url(${InterRegular});
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${InterMedium});
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBold});
    font-style: normal;
    font-weight: 600;
  }
  
  html{
    font-family: 'Inter';
    color: var(--gray-1);
  }
  *{
    font-family: 'Inter';
  }

  h1,h2,h3,h4,h5,h6{
    font-family: 'Inter'
  }

`;

export default Typography;
