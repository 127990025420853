import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  status: "",
  message: "",
  email: "",
};

const resetPassword = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    genOtpPending: (state) => {
      state.loading = true;
    },
    genOtpFulfilled: (state, { payload }) => {
        state.loading = false;
        state.status = "success";
        state.message = payload.message;
        state.email = payload.email
    },
    updatePassFulfilled: (state, { payload }) => {
      state.loading = false;
      state.status = "success";
      state.message = payload.message;
  },
    genOtpRejected: (state, { payload }) => {
        state.loading = false;
        state.status = "error";
        state.message = payload.message;
     },
  },
});

const {reducer, actions} = resetPassword

export const { 
  genOtpPending, 
  genOtpFulfilled, 
  genOtpRejected,
  updatePassFulfilled
 } = actions;

export default reducer;