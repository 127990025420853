import React from "react";

import styled from "styled-components";

import PropTypes from "prop-types";

function PageHeader({ title, subTitle, Comp }) {
  return (
    <PageHeaderStyles>
      <h2>{title}</h2>
      <h5 style={{ textAlign: "left" }} className="subText">
        {subTitle}
      </h5>
      <Compon>
      {Comp && <Comp />}
      </Compon>
    </PageHeaderStyles>
  );
}

export default PageHeader;

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const PageHeaderStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px dotted var(--gray-1);
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100vw;
    text-align: center;
  }

  h2 {
    color: var(--main-black);
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    margin-left: 38px;
    margin-top: 25px;
  }

  h5 {
    color: var(--gray-3);
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    margin-left: 38px;
    margin-top: -10px;
    text-align: center;
  }

  h5 {
    @media (max-width: 640px) {
      text-align: left !important;
    }
  }
`;
const Compon = styled.div`
width:100%`
