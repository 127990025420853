import React, { useState } from "react";

import styled from "styled-components";

import { VictoryChart, VictoryLine, VictoryTheme } from "victory";

function ChartView() {
  const [selectedChartType, setSelectedChartType] = useState("Sales");

  const handleChartSelection = (e) => {
    setSelectedChartType(e.target.value);
  };

  const downlinesData = [
    { x: "Jan", y: 5 },
    { x: "Feb", y: 4 },
    { x: "Mar", y: 3 },
    { x: "Apr", y: 6 },
    { x: "May", y: 2 },
    { x: "Jun", y: 1 },
    { x: "Jul", y: 0 },
    { x: "Aug", y: 8 },
    { x: "Sep", y: 9 },
    { x: "Oct", y: 10 },
    { x: "Nov", y: 2 },
    { x: "Dec", y: 7 },
  ];

  const salesData = [
    { x: "Jan", y: 15 },
    { x: "Feb", y: 40 },
    { x: "Mar", y: 13 },
    { x: "Apr", y: 6 },
    { x: "May", y: 12 },
    { x: "Jun", y: 11 },
    { x: "Jul", y: 6 },
    { x: "Aug", y: 8 },
    { x: "Sep", y: 90 },
    { x: "Oct", y: 100 },
    { x: "Nov", y: 20 },
    { x: "Dec", y: 70 },
  ];

  const commissionsData = [
    { x: "Jan", y: 1 },
    { x: "Feb", y: 2 },
    { x: "Mar", y: 3 },
    { x: "Apr", y: 6 },
    { x: "May", y: 4 },
    { x: "Jun", y: 5 },
    { x: "Jul", y: 0 },
    { x: "Aug", y: 8 },
    { x: "Sep", y: 9 },
    { x: "Oct", y: 10 },
    { x: "Nov", y: 2 },
    { x: "Dec", y: 7 },
  ];

  const membershipData = [
    { x: "Jan", y: 5 },
    { x: "Feb", y: 4 },
    { x: "Mar", y: 2 },
    { x: "Apr", y: 1 },
    { x: "May", y: 2 },
    { x: "Jun", y: 1 },
    { x: "Jul", y: 0 },
    { x: "Aug", y: 8 },
    { x: "Sep", y: 9 },
    { x: "Oct", y: 3 },
    { x: "Nov", y: 2 },
    { x: "Dec", y: 7 },
  ];

  const allChartsData = [];

  return (
    <ChartViewStyles  className="rowStats1"
    >
      <ChartSelector className="rowStats1">
        <div className="type" >
          <select
            defaultValue={selectedChartType}
            onChange={handleChartSelection}
          >
            <option value="Sales Bonus">Sales</option>
            <option value="Commissions">Commissions</option>
            <option value="Membership">Membership</option>
            <option value="Downlines">Downlines</option>
          </select>
        </div>
        <div className="count">
          <h3>{allChartsData.length}</h3>
          <h5>in total</h5>
        </div>
      </ChartSelector>

      {allChartsData.length <= 0 ? (
        <EmptyChartsData >
          <h5>No data for this graph</h5>
        </EmptyChartsData>
      ) : (
        <VictoryChart theme={VictoryTheme.material} height={370} width={700}>
          <VictoryLine
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            style={{
              data: {
                strokeLinecap: "round",
                strokeWidth: "3px",
                stroke: (() => {
                  switch (selectedChartType) {
                    case "Sales":
                      return "#2ED3F2";
                    case "Commissions":
                      return "#5CFB8E";
                    case "Membership":
                      return "#FBA35C";
                    case "Downlines":
                      return "#5CA2FB";
                    default:
                      return "#2ED3F2";
                  }
                })(),
              },
            }}
            data={(() => {
              switch (selectedChartType) {
                case "Sales":
                  return salesData;
                case "Commissions":
                  return commissionsData;
                case "Membership":
                  return membershipData;
                case "Downlines":
                  return downlinesData;
                default:
                  return salesData;
              }
            })()}
          />
        </VictoryChart>
      )}
    </ChartViewStyles>
  );
}

export default ChartView;

const EmptyChartsData = styled.div`
  height: 400px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    color: var(--main-bg);
    font-weight: 500;
    font-size: 16px;
    margin-top: 40px;
  }
`;

const ChartViewStyles = styled.div`
  // flex: 0.65;
  height: 400px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 48%;

  @media (max-width: 1040px) {
    width: 95%;
  }
`;

const ChartSelector = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 26px;
  padding-bottom: 10px;
  padding-left: 32px;
  gap: 2rem;

  .type {
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 150px;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(4, 4, 6, 0.1);
    box-sizing: border-box;
    border-radius: 8px;

    select {
      outline: none;
      border: none;
      color: var(--main-bg);
      font-size: 14px;

      option {
        border: none;
        color: var(--main-bg);
      }

      &:focus {
        border: none;
      }
    }
  }

  .count {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 1rem;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      color: var(--main-bg);
    }

    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: var(--gray-3);
    }
  }
`;
