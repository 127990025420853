import React, { useState, useEffect } from "react";
import { banks } from "helpers/dataset/banksList";
import { useNavigate,Link } from "react-router-dom";
import Button from "components/common/Button";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";

import styled from "styled-components";

import BioSectionTitle from "components/bio/bio_section_title";
import MLMTextField from "components/common/mlm_text_field1";

function BioBankDetails() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { updateProfile } = useActions();

  const { userId } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
  });

  const [bankNameError, setBankNameError] = useState("");
  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");

  const { bankName, accountName, accountNumber } = bankDetails;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({ ...bankDetails, [name]: value });

    switch (name) {
      case "bankName":
        if (!value || value === "Select Your Bank") {
          setBankNameError("Please select your bank");
        } else {
          setBankNameError("");
        }
        break;
      case "accountName":
        if (!value) {
          setAccountNameError("Please enter your account name");
        } else {
          setAccountNameError("");
        }
        break;
      case "accountNumber":
        if (!value) {
          setAccountNumberError("Please enter a valid account number");
        } else {
          setAccountNumberError("");
        }
        break;
      default:
        setBankNameError("");
        setAccountNameError("");
        setAccountNumberError("");
    }
  };

  const handleSubmitPayment = (event) => {
    event.preventDefault();

    if (
      (!bankName || bankName === "Select Your Bank") &&
      !accountName &&
      !accountNumber
    ) {
      setBankNameError("Please select your bank");
      setAccountNameError("Please enter your account name");
      setAccountNumberError("Please enter a valid account number");
      return;
    }

    if (!bankName || bankName === "Select Your Bank") {
      setBankNameError("Please select your bank");
      return;
    }

    if (!accountName) {
      setAccountNameError("Please enter your account name");
      return;
    }

    if (!accountNumber) {
      setAccountNumberError("Please enter a valid account number");
      return;
    }

    if (bankNameError || accountNumberError || accountNameError) {
      return;
    }
    let x = user.id;


    updateProfile({
      x,
      navigate,
      nextPath: "/dashboard",
      bankName: bankDetails.bankName,
      accountName: bankDetails.accountName,
      accountNumber: bankDetails.accountNumber,
    });
  };

  useEffect(() => {
    if (user) {
      setBankDetails({
        ...bankDetails,
        bankName: user.bankName ? user.bankName : "",
        accountName: user.accountName ? user.accountName : "",
        accountNumber: user.accountNumber ? user.accountNumber : "",
      });
    }

    // eslint-disable-next-line
  }, [user]);

  return (
    <BankDetailsStyles>
      <BioSectionTitle
        title="Bank Details"
        subTitle="Enter your bank details"
      />

      <form onSubmit={handleSubmitPayment}>
        <div style={{ marginBottom: "2rem" }} className="">
          <div
            className="select-container "
            style={{
              borderColor: bankNameError ? theme.palette.secondary.main : null,
            }}
          >
            <select
              name="bankName"
              value={bankDetails.bankName}
              onChange={handleChange}
            >
              <option value="Select Your Bank">Select Your Bank</option>
              {banks.map((bank) => (
                <option key={bank} value={bank}>
                  {bank}
                </option>
              ))}
            </select>
          </div>
          {bankNameError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {bankNameError}
            </small>
          )}
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <MLMTextField
            hintText="Enter account name"
            inputType="text"
            inputTextColor="var(--black)"
            labelText="Account name"
            labelColor="var(--black)"
            name="accountName"
            onChange={handleChange}
            fieldValue={bankDetails.accountName}
            divBorder={
              accountNameError
                ? `1px solid ${theme.palette.secondary.main}`
                : null
            }
          />
          {accountNameError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {accountNameError}
            </small>
          )}
        </div>
        <div>
          <MLMTextField
            hintText="Enter account number"
            inputType="number"
            inputTextColor="var(--black)"
            labelText="Account number"
            labelColor="var(--black)"
            name="accountNumber"
            onChange={handleChange}
            fieldValue={`${bankDetails.accountNumber}`}
            divBorder={
              accountNumberError
                ? `1px solid ${theme.palette.secondary.main}`
                : null
            }
          />
          {accountNumberError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {accountNumberError}
            </small>
          )}
        </div>

        <div className="proceed" style={{ marginBottom: "2rem" }}>
          <Button
            type="submit"
            title="Save Settings"
            background={theme.palette.secondary}
          />
        </div>
      </form>
    </BankDetailsStyles>
  );
}

export default BioBankDetails;

const BankDetailsStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0px 20px;

  form {
    margin-top: 1rem;

    .proceed {
      display: flex;
      flex-direction: row;
      justify-content: end;      margin-top: 2rem;
    }
  }

  .select-container {
    /* width: 93.5%; */
    width: 100%;
    height: 42px;
    background-color: var(--white);
    border: 1px solid var(--gray-1);
    border-radius: 8px;
    display: flex;
    align-content: center;
    padding-left: 10px;
    padding-right: 10px;

    select {
      width: 100%;
      border: none;
      outline: none;
      background-color: var(--white);
      color: var(--main-bg);

      option {
        color: var(--main-bg);
      }
    }
  }
`;
