import React from "react";
import styled from "styled-components";
import NotificationItem from "./notification_item";

function NotificationsMenu({ showNotificationsMenu }) {
  return (
    <NotificationsMenuStyles className="notiV1" showNotificationsMenu={showNotificationsMenu}>
      {/* <NotificationItem
        isUnread={true}
        title="John Hammond created Islar Nubla SOC2 compliance report"
        timeStamp="Wednesday at 11:15am"
      />
      <NotificationItem
        isUnread={false}
        title="John Hammond created Islar Nubla SOC2 compliance report"
        timeStamp="Wednesday at 11:15am"
      />
      <NotificationItem
        isUnread={true}
        title="John Hammond created Islar Nubla SOC2 compliance report"
        timeStamp="Wednesday at 11:15am"
      /> */}
         <p
        style={{ margin: "34% 3%", whiteSpace: "normal", textAlign: "center" }}
      >
        No available notifications
      </p>
    </NotificationsMenuStyles>
  );
}

export default NotificationsMenu;

const NotificationsMenuStyles = styled.div`
  margin-top: 50px;
  margin-left: -120px;
  // top: 100%;
  position: absolute;
  width: 380px;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(4, 4, 6, 0.1);
  min-height: 100px;
  background-color: var(--white);
  z-index: 999;
  display: ${(props) => (props.showNotificationsMenu ? "flex" : "none")};
  flex-direction: column;
  padding-bottom: 1rem;

  @media(max-width: 768px) {
    margin-left: -280px;
  }

  @media(max-width: 640px) {
    margin-left: 210px;                                                                                                                                                                                                                                                                                                                                                                                   
    margin-top: 8px;
    width: 360px !important;
  }

  @media(max-width: 573px) {
    margin-left: 150px;
  }

  @media(max-width: 514px) {
    margin-left: 120px;
  }

  @media(max-width: 486px) {
    margin-left: 80px;
  }

  @media(max-width: 446px) {
    margin-left: 2      0px;
  }
  
`;
