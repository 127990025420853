import React from "react";

import { useLocation } from "react-router-dom";

import styled from "styled-components";

import Address from "components/common/address";
import PersonalDetails from "components/common/personal_details";
import NextOfKin from "components/common/next_kin";
import BankDetails from "components/common/bank_details";

function ProfileMain() {
  const location = useLocation();

  return (
    <ProfileMainStyles>
      {(() => {
        switch (location.pathname) {
          default:
            return <PersonalDetails />;
          case "/profile/personal-details":
            return <PersonalDetails />;
          case "/profile/address":
            return <Address />;
          case "/profile/next-of-kin":
            return <NextOfKin />;
          case "/profile/bank-details":
            return <BankDetails />;
        }
      })()}
    </ProfileMainStyles>
  );
}

export default ProfileMain;

const ProfileMainStyles = styled.div`
  flex: 0.8;
  height: auto;
  min-height: 40vh;
  background-color: var(--white);
  border-left: 1px dotted var(--gray-1);
  padding: 2rem;
  width: 100%;
`;
