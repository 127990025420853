import React from 'react'

function NotFound() {
    return (
        <div>
            There seems to be no route for this
        </div>
    )
}

export default NotFound