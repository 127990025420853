import React from "react";

import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

// import { ReactComponent as PersonAvatar } from "assets/icons/personal_details_icon.svg";
// import { ReactComponent as AddressIcon } from "assets/icons/address_icon.svg";
// import { ReactComponent as NextKinIcon } from "assets/icons/next_kin_icon.svg";

export const bioMenuData = [
  {
    title: "Personal Details",
    icon: "/icons/personal_details_icon.svg",
    path: "/bio/personal-details",
  },
  {
    title: "Address",
    icon: "/icons/address_icon.svg",
    path: "/bio/address",
  },
  {
    title: "Next of Kin",
    icon: "/icons/next_kin_icon.svg",
    path: "/bio/next-of-kin",
  },
  {
    title: "Bank Details",
    icon: "/icons/bank_details_icon.svg",
    path: "/bio/bank-details",
  },
];

function BioNav() {
  const location = useLocation();
  const path = location.pathname;

  return (
    <BioNavStyles>
      <ul>
        {bioMenuData.map((item, index) => {
          return (
            <li key={index} className={path === item.path ? "active" : ""}>
              <Link
                to={item.path}
                className="bio-side-menu-item"
                activeclassname="active"
              >
                {/* {React.createElement(item.icon, {
                  style: { fill: "none" },
                })} */}
                <img src={item.icon} alt="nav icon" />
                <div className="title">{item.title}</div>
              </Link>
            </li>
          );
        })}
      </ul>
    </BioNavStyles>
  );
}

export default BioNav;

const BioNavStyles = styled.nav`
  flex: 0.2;
  padding: 2rem;
  margin-top: 2rem;

  svg {
    height: 20px;
    width: 20px;
    fill: black;
  }

  li {
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    align-content: center;
    align-items: center;
    width: 100%;

    img {
      height: 20px;
      width: 20px;
    }

    .bio-side-menu-item {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-content: center;
      align-items: center;
      padding: 10px;

      .active {
        color: red;
      }

      .title {
        color: black;
        font-size: 15px;
      }
    }
  }

  .active {
    background-color: #e5e5e5;
    border-left: 3px solid var(--button-bg);
  }
`;
