import React from "react";

import styled from "styled-components";
import prettyMoney from "pretty-money";

function BonusesListView(props) {
  const prettyDollarConfig = {
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  console.log(props.bonus, "from props bon");
  return (
    <BonusesListViewStyles className="bonD">
      <h3>Membership Bonuses</h3>
      <div>
        <li>
          <h5>{"Cash bonus"}</h5>
          <h5 className="amount">
            &#8358;
            {props.bonus.total_cashBonus
              ? prettyMoney(prettyDollarConfig, props.bonus.total_cashBonus)
              : 0}
          </h5>
        </li>
        <li>
          <h5>{"Referral bonus"}</h5>
          <h5 className="amount">
            &#8358;
            {props.bonus.total_referralBonus
              ? prettyMoney(prettyDollarConfig, props.bonus.total_referralBonus)
              : 0}
          </h5>{" "}
        </li>
        <li>
          <h5>{"Leadership bonus"}</h5>
          <h5 className="amount">
            &#8358;
            {props.bonus.total_leadershipBonus
              ? prettyMoney(
                  prettyDollarConfig,
                  props.bonus.total_leadershipBonus
                )
              : 0}
          </h5>{" "}
        </li>
        <li>
          <h5>{"Promotional bonus"}</h5>
          <h5 className="amount">
            &#8358;
            {props.bonus.total_promotionalBonus
              ? prettyMoney(
                  prettyDollarConfig,
                  props.bonus.total_promotionalBonus
                )
              : 0}
          </h5>{" "}
        </li>
        <li>
          <h5>{"Performance bonus"}</h5>
          <h5 className="amount">
            &#8358;
            {props.bonus.total_performanceBonus
              ? prettyMoney(
                  prettyDollarConfig,
                  props.bonus.total_performanceBonus
                )
              : 0}
          </h5>{" "}
        </li>
        <li>
          <h5>{"Salary (6 months)"}</h5>
          <h5 className="amount">
            &#8358;
            {props.bonus.total_salary
              ? prettyMoney(prettyDollarConfig, props.bonus.total_salary)
              : 0}
          </h5>{" "}
        </li>
        <li>
          <h5>{"Discounted salary"}</h5>
          <h5 className="amount">
            &#8358;
            {props.bonus
              ? prettyMoney(
                  prettyDollarConfig,
                  props.bonus.total_discountedSalary
                )
              : 0}
          </h5>{" "}
        </li>
      </div>
    </BonusesListViewStyles>
  );
}

export default BonusesListView;

const BonusesListViewStyles = styled.div`
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 48%;

  @media (max-width: 1040px) {
    width: 95%;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: var(--main-bg);
  }

  li {
    display: flex;
    justify-content: space-between;
    padding-top: 13.5px;
    padding-bottom: 13.5px;

    &:not(:last-child) {
      border-bottom: 1px dotted var(--gray-1);
    }

    h5 {
      font-size: 15px;
      font-weight: 400;
    }

    .amount {
      color: black;
      font-size: 16px;
    }
  }
`;
