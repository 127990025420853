import React, { useState } from "react";

import styled from "styled-components";

import PageHeader from "../components/common/page_header";
import { MiniSearchBar } from "../components/common/mini_search_bar";

import FilterIcon from "../assets/icons/filter_icon.svg";
import ExportIcon from "../assets/icons/export_icon.svg";

function Downlines() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const downlinesData = [];

  return (
    <DownlinesStyles className="marginTop">
      <PageHeader
        title="Downlines"
        subTitle="This page displays all your downlines"
      />
      <div className="search-filter-export">
        <MiniSearchBar
          name={searchQuery}
          onChange={handleChange}
          value={searchQuery}
          placeHolder="Search client, name or property..."
        />
        <div className="filter-export">
          <div className="filter">
            <img src={FilterIcon} alt="filter-icon" />
            <h3>Filters</h3>
          </div>
          <div className="export">
            <img src={ExportIcon} alt="export-icon" />
            <h3>Export</h3>
          </div>
        </div>
      </div>
      <table cellSpacing={0}>
        <thead>
          <tr>
            <th>
              <input type="checkbox" />
            </th>

            <th>Date of Reg</th>
            <th>Name</th>
            <th>Phone number</th>
            <th>Email address</th>
            <th>Level</th>
            <th>Member ID</th>
          </tr>
        </thead>
        {downlinesData && downlinesData.length > 0 && (
          <tbody>
            <tr>
              <td>13/09/2021</td>
              <td>Alexander Cooper</td>
              <td>+234908808080</td>
              <td>willie.jennings@example.com</td>
              <td>Associate</td>
              <td>C8340</td>
            </tr>
            <tr>
              <td>09/09/2021</td>
              <td>Jerome Bell</td>
              <td>+234908808080</td>
              <td>bill.sanders@example.com</td>
              <td>Pre-associate</td>
              <td>D3924</td>
            </tr>
            <tr>
              <td>04/09/2021</td>
              <td>Robert Fox</td>
              <td>+234908808080</td>
              <td>nathan.roberts@example.com</td>
              <td>Premium member</td>
              <td>C2831</td>
            </tr>
            <tr>
              <td>13/09/2021</td>
              <td>Alexander Cooper</td>
              <td>+234908808080</td>
              <td>willie.jennings@example.com</td>
              <td>Sapphire Director</td>
              <td>C8340</td>
            </tr>
            <tr>
              <td>13/09/2021</td>
              <td>Alexander Cooper</td>
              <td>+234908808080</td>
              <td>willie.jennings@example.com</td>
              <td>Elite</td>
              <td>C8340</td>
            </tr>
          </tbody>
        )}
      </table>
      {downlinesData.length <= 0 && (
        <EmptyTableDataState>
          <div className="empty-text">
            <h3>No data yet</h3>
            <h5>We have not computed any data for this table yet</h5>
          </div>
        </EmptyTableDataState>
      )}
    </DownlinesStyles>
  );
}

export default Downlines;

const EmptyTableDataState = styled.div`
  height: 289px;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-text {
    text-align: center;
    h3 {
      font-size: 24px;
      font-weight: 500;
      color: var(--main-black);
    }

    h5 {
      font-size: 15px;
      font-weight: 300;
      color: var(--gray-3);
    }
  }
`;

const DownlinesStyles = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: var(--gray-2);
  display: flex;
  flex-direction: column;
  position: relative;

  .search-filter-export {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dotted var(--gray-1);
  }

  .filter-export {
    display: flex;

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background-color: var(--white);
      gap: 1rem;
      padding: 9px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }

  table {
    margin-left: 38px;
    margin-right: 38px;
    margin-top: 10px;

    thead {
      text-align: left;
      tr {
        &:first-child {
          width: 40px;
        }

        &:nth-child(2) {
          min-width: 150px;
          max-width: 180px;
        }

        &:nth-child(4) {
          min-width: 180px;
          max-width: 200px;
        }

        th {
          padding-left: 15px;
          color: var(--main-bg);
          padding-top: 15px;
          padding-bottom: 15px;
          font-size: 15px;
        }
      }
    }

    tbody {
      background-color: var(--white);

      tr {
        border-bottom: 1px solid #ddd;
        cursor: pointer;

        td {
          color: var(--main-bg);

          &:first-child {
            width: 40px;
          }

          &:nth-child(4) {
            min-width: 180px;
            max-width: 200px;
            overflow-x: hidden;
          }

          padding-top: 18px;
          padding-bottom: 18px;
          padding-left: 14.5px;
          padding-right: 14.5px;
          font-size: 15px;
        }
      }
    }
  }
`;
