import React from 'react'
import styled from 'styled-components'

import AuthBkg from '../../assets/images/auth-bg.png'

function NotificationItem({isUnread, title, timeStamp}) {
    return <NotificationItemStyles>
        <div className="indicator-image">
            {
                isUnread ? <div className="indicator"/> : null
            } 
            <img src={AuthBkg} alt="profile-img" />
        </div>
        <div className="title-time-stamp">
            <h3>{title}</h3>
            <h5>{timeStamp}</h5>
        </div>
    </NotificationItemStyles>
}

export default NotificationItem

const NotificationItemStyles = styled.div`
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;

    .indicator-image {
        display: flex;

        .indicator {
            width: 6px;
            height: 6px;
            background-color: red;
            border-radius: 50%;
        }

        img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
        }
    }

    .title-time-stamp {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
            font-weight: 600;
            font-size: 14px;
            color: var(--main-bg);
        }

        h5 {
            font-weight: 200;
        }
    }
`