import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { makeStyles, useTheme } from "@mui/styles";
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useSelector, useDispatch } from "react-redux";
// import { useActions } from "hooks/useActions";
import { passwordUpdate } from "../services/reset_password/resetAction";

import styled from "styled-components";

import AuthImg from "../assets/images/auth-bg.png";
import Logo from "../assets/images/logo.png";

import MLMTextField from "../components/common/mlm_text_field";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "85%",
      background: theme.palette.secondary.main,
      textTransform: "none",
      marginTop: "20px",
      borderRadius: 8,

      "&:hover": {
        background: theme.palette.secondary.light,
      },
      "&:active": {
        background: theme.palette.secondary.dark,
      },
    },
  },
  backBtn: {
    border: `1px solid ${theme.palette.common.grey}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 8,
    cursor: "pointer",
    color: "#fff",
  },
  response: {
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  error: {
    color: theme.palette.secondary.main,
  },

  success: {
    color: theme.palette.success.main,
  },
}));

function UpdatePassword() {
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { error, success } = useSelector((state) => state.auth);

  const { loading, email, message } = useSelector((state) => state.reset);
  const [form, setForm] = useState({
    otp: "",
    new_password: "",
    confirm_password: "",
  });

  const [otpCodeError, setOtpCodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const { otp, new_password, confirm_password } = form;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    switch (event.target.name) {
      case "otp":
        if (event.target.value.length < 6) {
          setOtpCodeError("Please enter a valid OTP");
        } else {
          setOtpCodeError("");
        }
        break;
      case "new_password":
        if (event.target.value.length < 8) {
          setPasswordError("Password must have minimum of 8 characters");
        } else {
          setPasswordError("");
        }
        break;
      case "confirm_password":
        if (event.target.value !== new_password) {
          setConfirmPasswordError("Password does not match");
        } else {
          setConfirmPasswordError("");
        }
        break;
      default:
        setOtpCodeError("");
        setPasswordError("");
        setConfirmPasswordError("");
    }
  };

  const handlePasswordUpdate = (event) => {
    event.preventDefault();

    if (!otp.trim() && !new_password.trim() && !confirm_password.trim()) {
      setPasswordError("Password is required");
      setConfirmPasswordError("Confirm Password is required");
      setOtpCodeError("OTP is required");
      return;
    }

    if (!otp.trim()) {
      setOtpCodeError("OTP is required");
      return;
    }

    if (!new_password.trim()) {
      setPasswordError("Password is required");
      return;
    }

    if (confirm_password !== new_password) {
      setConfirmPasswordError("Password does not match");
      return;
    }

    if (passwordError || confirmPasswordError) return;

    dispatch(passwordUpdate({ email, otp, new_password, confirm_password }));
  };

  if (localStorage.token) return <Navigate to="/dashboard" />;

  return (
    <UpdatePasswordStyles>
      <div className="left">
        <div style={{ marginTop: "1rem", marginBottom: "4rem" }}>
          <Link to="/forgot-password" className={classes.backBtn}>
            Back
          </Link>
        </div>
        <div className="body">
          <div className="logo" />
          <h3 style={{ marginBottom: "2rem" }}>Update Password</h3>
          <br />
          {message !== "successful" && (
            <Alert variant="outlined" severity={"success"} className="alert">
              <strong>Your password has been successfully reset.</strong>
            </Alert>
          )}
          <div
            style={
              message !== "successful"
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <form onSubmit={handlePasswordUpdate}>
              {error ? (
                <p className={`${classes.response} ${classes.error}`}>
                  {error}
                </p>
              ) : null}
              {success ? (
                <p className={`${classes.response} ${classes.success}`}>
                  {success}
                </p>
              ) : null}

              <div style={{ marginBottom: "2rem" }}>
                <MLMTextField
                  midWith={true}
                borderColor={false}
                  name="otp"
                  labelText="OTP"
                  inputType="text"
                  hintText="Enter OTP"
                  fieldValue={otp}
                  onChange={handleChange}
                  hintTextColor={"var(--white)"}
                  divBorder={
                    passwordError
                      ? `1px solid ${theme.palette.secondary.main}`
                      : null
                  }
                />
                {otpCodeError && (
                  <small style={{ color: theme.palette.secondary.main }}>
                    {otpCodeError}
                  </small>
                )}
              </div>
              <div style={{ marginBottom: "2rem" }}>
                <MLMTextField
                  midWith={true}
                borderColor={false}
                  name="new_password"
                  labelText="New Password"
                  inputType="password"
                  hintText="Enter your new password"
                  fieldValue={new_password}
                  onChange={handleChange}
                  hintTextColor={"var(--white)"}
                  divBorder={
                    passwordError
                      ? `1px solid ${theme.palette.secondary.main}`
                      : null
                  }
                />
                {passwordError && (
                  <small style={{ color: theme.palette.secondary.main }}>
                    {passwordError}
                  </small>
                )}
              </div>
              <div>
                <MLMTextField
                  midWith={true}
                borderColor={false}
                  name="confirm_password"
                  labelText="Confirm new password"
                  inputType="password"
                  hintText="Enter your new password"
                  fieldValue={confirm_password}
                  onChange={handleChange}
                  hintTextColor={"var(--white)"}
                  divBorder={
                    confirmPasswordError
                      ? `1px solid ${theme.palette.secondary.main}`
                      : null
                  }
                />
                {confirmPasswordError && (
                  <small style={{ color: theme.palette.secondary.main }}>
                    {confirmPasswordError}
                  </small>
                )}
              </div>
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
              >
                {loading ? (
                  <CircularProgress
                    size={25}
                    style={{ marginRight: "0.5rem" }}
                  />
                ) : null}{" "}
                Update Password
              </Button>
            </form>
          </div>
          <br />
          <br />
          {message !== "successful" && (
            <Link
              to="/dashboard"
              className={message !== "successful" ? "" : "disabled-link"}
            >
              <Button
                type="button"
                variant="contained"
                className={classes.button}
                style={{ width: "35%", fontWeight: "500" }}
              >
                Login
              </Button>
            </Link>
          )}
          <br></br>
        </div>
      </div>

      <div className="right"></div>
    </UpdatePasswordStyles>
  );
}

export default UpdatePassword;

const UpdatePasswordStyles = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: var(--main-bg);
  display: flex;
  flex-direction: row;

  .right {
    flex: 1;
    background-image: url(${AuthImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;

  }

  .left {
    flex: 0.7;
    padding-left: 5rem;
    padding-right: 4.8rem;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @media (max-width: 1114px) {
      flex: 0.9;
    }

    @media (max-width: 768px) {
      flex: none;
      width: 100%;
      padding: 3rem 2rem;
    }

    .body {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;

      .logo {
        height: 40px;
        background-image: url(${Logo});
        background-repeat: no-repeat;
        margin-bottom: 2.5rem;
      }

      h3 {
        font-size: 30px;
        color: var(--white);
        /* margin-bottom: 1rem; */
      }

      .forgot {
        text-decoration: underline;

        p {
          margin-top: 3rem;
          font-size: 13px;
        }
      }

      .login-prompt {
        font-size: 13px;
        margin-top: 2rem;

        .login-prompt-span a {
          color: var(--button-bg);
        }
      }
    }
  }

  .disabled-link {
    pointer-events: none;
  }

  .alert {
    font-size: 1.3rem;
  }
`;
