import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import setAuthorizationToken from "../utils/setAuthToken";
import { BASE_URL } from "../BASE_URL";
import axios from "axios";

const initialState = {
  user: null,
  userIsLoading: false,
  updatingProfile: false,
  first_gen: null,
  first_genIsLoading: false,
  second_gen: null,
  second_genIsLoading: false,
  error: null,
};

const checkConnection = () => {
  if (navigator.onLine) {
    return true;
  } else {
    return false;
  }
};

export const loadUser = createAsyncThunk(
  "user/profile",
  async (thunkAPI, userId) => {
    if (localStorage.token) {
      setAuthorizationToken(localStorage.token);
    }

    if (!checkConnection) return;

    try {
      let x = localStorage.getItem("userId");

      const { data } = await axios.get(`${BASE_URL}/api/users/${x}`);

      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const loadUserFirstGen = createAsyncThunk(
  "user/first_gen",
  async (label, thunkAPI) => {
    if (localStorage.token) {
      setAuthorizationToken(localStorage.token);
    }

    if (!checkConnection) return;

    try {
      let x = localStorage.getItem("userId");

      const { data } = await axios.get(`${BASE_URL}/api/users/${x}/${label}`);
      return Array.isArray(data.data)
        ? data.data
        : Array.isArray(data.data?.data)
        ? data.data?.data
        : [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// export const loadUserFirstGenS = createAsyncThunk(
//   `user/first_gen?search_term=${searchQuery}`,
//   async (thunkAPI, userId) => {
//     if (localStorage.token) {
//       setAuthorizationToken(localStorage.token);
//     }

//     if (!checkConnection) return;

//     try {
//       let x = localStorage.getItem("userId");

//       const { data } = await axios.get(
//         `${BASE_URL}/api/users/${x}/first_gen_downlines`
//       );

//       return data.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

export const loadUserSecondGen = createAsyncThunk(
  "user/second_gen_downlines",
  async (thunkAPI, userId) => {
    if (localStorage.token) {
      setAuthorizationToken(localStorage.token);
    }

    if (!checkConnection) return;

    try {
      let x = localStorage.getItem("userId");

      const { data } = await axios.get(
        `${BASE_URL}/api/users/${x}/second_gen_downlines`
      );
      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/update",
  async (userData, thunkAPI) => {
    const { userId, navigate, nextPath, ...fields } = userData;
    try {
      let x = localStorage.getItem("userId");

      const { data, status } = await axios.patch(`${BASE_URL}/api/users/${x}`, {
        firstName: fields.firstName,
        lastName: fields.lastName,
        email: fields.email,
        dateOfBirth: fields.date,
        mobile: fields.phoneNumber,
        gender: fields.gender,
        country: fields.country,
        state: fields.state,
        city: fields.city,
        address: fields.address,
        nextofkin: fields.nextofkin,
        bankName: fields.bankName,
        accountName: fields.accountName,
        accountNumber: fields.accountNumber,
      });

      if (status === 200) {
        navigate(nextPath);
      }

      return data.data.user;
    } catch (error) {
      return thunkAPI.rejectWithValue("Something went wrong");
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserData: (state) => {
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: {
    [loadUser.pending]: (state) => {
      state.userIsLoading = true;
    },

    [loadUser.fulfilled]: (state, action) => {
      state.userIsLoading = false;
      state.user = action.payload;
      state.error = null;
    },

    [loadUser.rejected]: (state, action) => {
      state.userIsLoading = false;
      state.error = action.payload;
    },

    [loadUserFirstGen.pending]: (state) => {
      state.first_genIsLoading = true;
    },

    [loadUserFirstGen.fulfilled]: (state, action) => {
      state.first_genIsLoading = false;
      state.first_gen = action.payload;
      state.error = null;
    },

    [loadUserFirstGen.rejected]: (state, action) => {
      state.first_genIsLoading = false;
      state.error = action.payload;
    },
    [loadUserSecondGen.pending]: (state) => {
      state.second_genIsLoading = true;
    },

    [loadUserSecondGen.fulfilled]: (state, action) => {
      state.second_genIsLoading = false;
      state.second_gen = action.payload;
      state.error = null;
    },

    [loadUserSecondGen.rejected]: (state, action) => {
      state.second_genIsLoading = false;
      state.error = action.payload;
    },

    [updateProfile.pending]: (state) => {
      state.updatingProfile = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.updatingProfile = false;
      state.user = action.payload;
      state.error = null;
    },
    [updateProfile.rejected]: (state, action) => {
      state.updatingProfile = false;
      state.error = action.payload;
    },
  },
});

export const { clearUserData } = userSlice.actions;

export default userSlice.reducer;
