import { ReactComponent as DashboardIcon } from "assets/icons/Dashboard_icon.svg";
import { ReactComponent as CommisionsIcon } from "assets/icons/commissions_nav_icon.svg";
import { ReactComponent as SettingIcon } from "assets/icons/setting_icon.svg";
import { ReactComponent as PropertiesIcon } from "assets/icons/properties_icon.svg";
import { ReactComponent as DownlinesIcon } from "assets/icons/downlines_nav_icon.svg";
// import { ReactComponent as ReferralsIcon } from "assets/icons/referrals_nav_icon.svg";

export const menus = [
  { id: 0, title: "Dashboard", icon: DashboardIcon, path: "dashboard" },
  {
    id: 1,
    title: "Bonus Withdrawal",
    icon: CommisionsIcon,
    path: "commissions",
  },
  { id: 2, title: "Properties", icon: PropertiesIcon, path: "properties" },
  // { id: 3, title: "Referrals", icon: ReferralsIcon, path: "referrals" },
  {
    id: 4,
    title: "Downlines",
    icon: DownlinesIcon,
    path: "downlines/first-gen",
    dropDowns: [
      { subId: 0, title: "1st Gen", path: "/downlines/first-gen" },
      // { subId: 1, title: "2nd Gen", path: "/downlines/second-gen" },
      // {
      //   subId: 2,
      //   title: "3rd Gen",
      //   path: "/downlines/third-gen",
      // },
      // {
      //   subId: 3,
      //   title: "4th Gen",
      //   path: "/downlines/fourth-gen",
      // },
      // {
      //   subId: 4,
      //   title: "5th Gen",
      //   path: "/downlines/fifth-gen",
      // },
      // {
      //   subId: 5,
      //   title: "6th Gen",
      //   path: "/downlines/sixth-gen",
      // },
      // {
      //   subId: 6,
      //   title: "7th Gen",
      //   path: "/downlines/seventh-gen",
      // },
      // {
      //   subId: 7,
      //   title: "8th Gen",
      //   path: "/downlines/eighth-gen",
      // },
      // {
      //   subId: 8,
      //   title: "9th Gen",
      //   path: "/downlines/ninth-gen",
      // },
      // {
      //   subId: 9,
      //   title: "10th Gen",
      //   path: "/downlines/tenth-gen",
      // },
      // {
      //   subId: 10,
      //   title: "11th Gen",
      //   path: "/downlines/eleventh-gen",
      // },
      // {
      //   subId: 11,
      //   title: "12th Gen",
      //   path: "/downlines/twelveth-gen",
      // },
      // {
      //   subId: 12,
      //   title: "13th Gen",
      //   path: "/downlines/thirteenth-gen",
      // },
    ],
  },
  { id: 5, title: "Settings", icon: SettingIcon, path: "bio/personal-details" },
];
