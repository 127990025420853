import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { Link } from "react-router-dom";
import styled from "styled-components";

import StatCard from "./stat_cards";
import DashboardSubHeader from "./sub_header";
import ChartView from "./chart_view";
import BonusesListView from "./bonuses_list_view";

import SalesBonusIcon from "../../assets/icons/sales_bonus_icon.svg";
import CommissionsIcon from "../../assets/icons/commissions_icon.svg";
import MembershipBonusIcon from "../../assets/icons/membership_bonus_icon.svg";
import DownlinesIcon from "../../assets/icons/downlines_icon.svg";

import BuildingCard from "components/utils/BuildingCard";
import EmptyProperty from "components/utils/EmptyProperty";
import SinglePropertyModal from "components/modals/SingleProperty";
const { default: axios } = require("axios");
const { BASE_URL } = require("../../services/BASE_URL");

const useStyles = makeStyles((theme) => ({
  allProperties: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginTop: "20px",
  },
  button: {
    "&.MuiButton-root": {
      textTransform: "none",
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
      width: 200,
      margin: "0 auto",
      borderRadius: 25,
      padding: ".75rem",

      "&:hover": {
        backgroundColor: "#fff",
      },

      "&:active": {
        backgroundColor: "#fff",
      },
    },
  },
}));

function DashboardBody() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [properties, setProperties] = useState(false);
  const [stats, setStats] = useState(false);
  const [statsD, setStatsD] = useState(false);

  const [bonus, setBonus] = useState(false);

  const [pending, setPending] = useState(false);

  useEffect(() => {
    setPending(true);
    GetProperties()
      .then((data) => {
        if (data.message === "successful") {
          setProperties(data.data.properties.properties);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });
    GetStats()
      .then((data) => {
        if (data.message === "successful") {
          setStats(data.data.result);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });

    GetStatsD()
      .then((data) => {
        console.log("jjjddd", data);

        if (data.message === "successful") {
          console.log("jjjddd", data);
          setStatsD(data.data.result);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });

    GetMBonus()
      .then((data) => {
        if (data.message === "successful") {
          console.log(data, "from setsta");
          setBonus(data.data.result);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });
  }, []);

  const GetProperties = async (searchTerm, filter) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/properties/all${searchTerm || filter ? "?" : ""}${
          searchTerm ? "search_term=" + searchTerm + "&" : ""
        }${filter ? "filter=" : ""}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

  const GetStats = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/users/dashboard/stats`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

  const GetStatsD = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/users/dashboard/stats/downlinescount`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

  const GetMBonus = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/users/dashboard/mymembershipbonuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(data, "from");
      return data;
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log(statsD, "statsssnowddd");

  const statsData = [
    {
      title: "Sales bonus",
      amount: stats.total_sales_bonus ? stats.total_sales_bonus : 0,
      icon: SalesBonusIcon,
      toolTipMessage:
        "Sales bonus is a percentage incentive earned off your downline's commission on a property sales up to your 2nd generation",
    },
    // {
    //   title: "Commissions",
    //   amount: stats.total_commssions ? stats.total_commssions : 0,
    //   icon: CommissionsIcon,
    //   toolTipMessage:
    //     "Commissions are a percentage on a property purchase price that you earn upon a successful property purchase",
    // },
    {
      title: "Membership Bonus",
      amount: stats.total_membership_bonus ? stats.total_membership_bonus : 0,
      icon: MembershipBonusIcon,
      toolTipMessage:
        "Membership Bonus is an incentive earned from your referrals after they've successfully registered on the platform",
    },
    {
      title: "Downlines",
      amountNum: stats.total_downlines ? stats.total_downlines : 0,
      amount: stats.total_downlines ? stats.total_downlines : 0,
      icon: DownlinesIcon,
      toolTipMessage:
        "Downlines refers to members that you have recruited to join the platform under you and whose sales or referrals also generate income for you",
    },
  ];
  return (
    <DashboardBodyStyles>
      <DashboardSubHeader />
      <StatCardsRow className="rowStats">
        {statsData.map((stat, index) => {
          return (
            <StatCard
              className="ts"
              key={index}
              title={stat.title}
              amount={stat.amount}
              icon={stat.icon}
              message={stat.toolTipMessage}
              amountNum={stat.amountNum}
            />
          );
        })}
      </StatCardsRow>
      <ChartBonusesRow>
        <div className="chart_memberDiv">
          <ChartView />
          <BonusesListView bonus={bonus} />
        </div>
      </ChartBonusesRow>
      <hr />
      <div className="title">
        <h2>Available Properties ({properties.length || 0})</h2>
      </div>
      {pending ? (
        <Box
          sx={{
            display: "flex",
            height: "400px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : properties.length > 0 ? (
        <div className={`${classes.allProperties} cardDivG `}>
          {properties.slice(0, 6).map((property) => (
            <BuildingCard
              key={property.id}
              property={property}
              setOpen={setOpen}
              setCurrentProperty={setCurrentProperty}
            />
          ))}
        </div>
      ) : (
        <EmptyProperty />
      )}
      <SinglePropertyModal
        open={open}
        setOpen={setOpen}
        currentProperty={currentProperty}
      />
      {properties.length > 3 && (
        <Link
          to="/properties"
          style={{ textDecoration: "none", display: "flex", marginTop: "10px" }}
        >
          <Button disableRipple variant="contained" className={classes.button}>
            View all properties
          </Button>
        </Link>
      )}
    </DashboardBodyStyles>
  );
}

export default DashboardBody;

const DashboardBodyStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100% - 60px);
  padding-bottom: 50px;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
  hr {
    height: 0px;
    border: 1px solid rgba(4, 4, 6, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 30px;
    margin-left: 15px;
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: var(--main-bg);
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: var(--main-bg);
      margin-left: 10px;
    }
  }
  .properties {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    height: 600px;
  }
`;

const StatCardsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 40px;
  padding: 0px 20px;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const ChartBonusesRow = styled.div`
  height: auto;
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
  margin-top: 30px;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
`;
