import React from "react";

import styled from "styled-components";

import AddCircle from "../../assets/icons/add-circle.svg";

import PropTypes from "prop-types";

function ReferralHeader({ title, subTitle }) {
  return (
    <ReferralHeaderStyles>
      <div className="meta">
        <h3>{title}</h3>
        <h5>{subTitle}</h5>
      </div>
      <NewReferralButtonStyles>
        <h5 style={{ fontSize: 15, fontWeight: 400 }}>New referral</h5>
        <img src={AddCircle} alt="referral-btn-icon" />
      </NewReferralButtonStyles>
    </ReferralHeaderStyles>
  );
}

export default ReferralHeader;

ReferralHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const ReferralHeaderStyles = styled.div`
  width: 100%;
  height: 120px;
  border-bottom: 1px dotted var(--gray-1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  .meta {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 38px;
    padding-top: 25px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }
`;

const NewReferralButtonStyles = styled.div`
  width: 148px;
  height: 42px;
  border-radius: 8px;
  background-color: var(--button-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.8rem;
  cursor: pointer;
  gap: 1rem;
  margin-right: 38px;

  h5 {
    color: white;
  }

  img {
    color: white;
    width: 20px;
    height: 20px;
  }
`;
