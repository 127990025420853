import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { makeStyles, useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";

import styled from "styled-components";

import AuthImg from "../assets/images/auth-bg.png";
import Logo from "../assets/images/logo.png";

import MLMTextField from "../components/common/mlm_text_field";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "100%",
      background: theme.palette.secondary.main,
      textTransform: "none",
      marginTop: "20px",
      borderRadius: 8,

      "&:hover": {
        background: theme.palette.secondary.light,
      },
      "&:active": {
        background: theme.palette.secondary.dark,
      },
    },
  },
  response: {
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  error: {
    color: theme.palette.secondary.main,
  },

  success: {
    color: theme.palette.success.main,
  },
}));

function Register() {
  const classes = useStyles();
  const theme = useTheme();
  const ref = new URLSearchParams(document.location.search).get("ref");

  const { clearMessages, registerUser } = useActions();

  const { loading, error, success } = useSelector((state) => state.auth);

  const [form, setForm] = useState({
    email: "",
    password: "",
    referrerCode: ref ? ref : "",
    myReferralCode: `${Math.floor(Math.random() * 899999 + 100000)}`,
    confirmPassword: "",
  });

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [referrerCodeError, setReferrerCodeError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const { email, password, referrerCode, myReferralCode, confirmPassword } =
    form;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    switch (event.target.name) {
      case "email":
        if (
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)
        ) {
          setEmailError("");
        } else {
          setEmailError("Please enter a valid email");
        }
        break;
      case "password":
        if (event.target.value.length < 8) {
          setPasswordError("Password must have minimum of 8 characters");
        } else {
          setPasswordError("");
        }
        break;
      case "referrerCode":
        if (event.target.value.length < 6) {
          setReferrerCodeError(
            "Please enter a valid referral code of 6 characters"
          );
        } else {
          setReferrerCodeError("");
        }
        break;
      case "confirmPassword":
        if (event.target.value !== password) {
          setConfirmPasswordError("Password does not match");
        } else {
          setConfirmPasswordError("");
        }
        break;
      default:
        setEmailError("");
        setPasswordError("");
        setReferrerCodeError("");
        setConfirmPasswordError("");
    }
  };

  const handleRegister = (event) => {
    event.preventDefault();

    if (
      !email.trim() &&
      !password.trim() &&
      !referrerCode.trim() &&
      !confirmPassword.trim()
    ) {
      setEmailError("Email is required");
      setPasswordError("Password is required");
      setReferrerCodeError("Referral code is required");
      setConfirmPasswordError("Confirm Password is required");
      return;
    }

    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    }

    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    }

    if (!referrerCode.trim()) {
      setReferrerCodeError("Referral code is required");
      return;
    }

    if (!myReferralCode.trim().length > 6) {
      setReferrerCodeError("Reload to generate new referral code");
      return;
    }

    if (!confirmPassword.trim()) {
      setConfirmPasswordError("Password does not match");
      return;
    }

    if (confirmPassword !== password) {
      setConfirmPasswordError("Password does not match");
      return;
    }

    if (
      emailError ||
      passwordError ||
      referrerCodeError ||
      confirmPasswordError
    )
      return;

    registerUser({
      email,
      password,
      referrerCode,
      myReferralCode,
      confirmPassword,
    });
  };

  useEffect(() => {
    clearMessages();

    // eslint-disable-next-line
  }, []);

  if (localStorage.token) return <Navigate to="/profile/personal-details" />;

  return (
    <RegisterStyles>
      <div className="left">
        <div className="body">
          <div className="logo" />

          <form onSubmit={handleRegister} className="authForm">
            <h3 style={{ marginBottom: "2rem" }}>Register</h3>

            {error ? (
              <p className={`${classes.response} ${classes.error}`}>{error}</p>
            ) : null}
            {success ? (
              <p className={`${classes.response} ${classes.success}`}>
                {success}
              </p>
            ) : null}

            <div style={{ marginBottom: "2rem" }}>
              <MLMTextField
                midWith={true}
                borderColor={false}
                name="email"
                labelText="Email Address"
                inputType="email"
                hintText="Enter your email"
                fieldValue={email}
                onChange={handleChange}
                hintTextColor={"var(--white)"}
                // divBorder={
                //   error === "Error: This Email already in use"
                //     ? `1px solid ${theme.palette.secondary.main}`
                //     : emailError
                //     ? `1px solid ${theme.palette.secondary.main}`
                //     : null
                // }
              />
              {emailError && (
                <small style={{ color: theme.palette.secondary.main }}>
                  {emailError}
                </small>
              )}
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <MLMTextField
                midWith={true}
                borderColor={false}
                name="referrerCode"
                labelText="Referrer Code"
                inputType="text"
                hintText="Enter your referrer code"
                fieldValue={referrerCode}
                disabled={ref ? true : false}
                onChange={handleChange}
                hintTextColor={"var(--white)"}
                // divBorder={
                //   passwordError
                //     ? `1px solid ${theme.palette.secondary.main}`
                //     : null
                // }
              />
              {referrerCodeError && (
                <small style={{ color: theme.palette.secondary.main }}>
                  {referrerCodeError}
                </small>
              )}
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <MLMTextField
                midWith={true}
                borderColor={false}
                name="password"
                labelText="Password"
                inputType="password"
                hintText="Enter your password"
                fieldValue={password}
                onChange={handleChange}
                hintTextColor={"var(--white)"}
                // divBorder={
                //   passwordError
                //     ? `1px solid ${theme.palette.secondary.main}`
                //     : null
                // }
              />
              {passwordError && (
                <small style={{ color: theme.palette.secondary.main }}>
                  {passwordError}
                </small>
              )}
            </div>
            <div>
              <MLMTextField
                midWith={true}
                borderColor={false}
                name="confirmPassword"
                labelText="Confirm password"
                inputType="password"
                hintText="Enter your password"
                fieldValue={confirmPassword}
                onChange={handleChange}
                hintTextColor={"var(--white)"}
                // divBorder={
                //   confirmPasswordError
                //     ? `1px solid ${theme.palette.secondary.main}`
                //     : null
                // }
              />
              {confirmPasswordError && (
                <small style={{ color: theme.palette.secondary.main }}>
                  {confirmPasswordError}
                </small>
              )}
            </div>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
            >
              {loading ? (
                <CircularProgress size={25} style={{ marginRight: "0.5rem" }} />
              ) : null}{" "}
              Sign up
            </Button>
            <p className="login-prompt">
              Already have an account? &nbsp;
              <span className="login-prompt-span">
                <Link to="/login">Log in</Link>
              </span>
            </p>
          </form>
          <br></br>
          <br></br>
        </div>
      </div>

      <div className="right"></div>
    </RegisterStyles>
  );
}

export default Register;

const RegisterStyles = styled.div`
  width: 100%;
  height: 160vh;
  overflow: auto;
  background-color: var(--main-bg);
  display: flex;

  .right {
    flex: 1.3;
    background-image: url(${AuthImg});
    background-size: cover;
    background-repeat: no-repeat;
  }

  .left {
    flex: 0.7;
    padding:3rem 5rem;

    @media (max-width: 768px) {
      flex: none;
      width: 100%;
    }

    .body {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;

      .logo {
        height: 40px;
        background-image: url(${Logo});
        background-repeat: no-repeat;
        // margin-bottom: 2.5rem;
        background-size: contain;

      }

      h3 {
        font-size: 30px;
        color: var(--white);
        /* margin-bottom: 1rem; */
      }

      .forgot {
        text-decoration: underline;

        p {
          margin-top: 3rem;
          font-size: 13px;
        }
      }

      .login-prompt {
        font-size: 13px;
        margin-top: 2rem;

        .login-prompt-span a {
          color: var(--button-bg);
        }
      }
    }
  }
`;
