import React from "react";

import { useLocation } from "react-router-dom";

import styled from "styled-components";

import BioPersonalDetails from "components/bio/BioPersonalDetails";
import BioAddress from "components/bio/BioAddress";
import BioNextOfKin from "components/bio/BioNextOfKin";
import BioBankDetails from "components/bio/BioBankDetails";

function BioMain() {
  const location = useLocation();

  return (
    <BioMainStyles>
      {(() => {
        switch (location.pathname) {
          case "/bio":
            return <BioPersonalDetails />;
          case "/bio/personal-details":
            return <BioPersonalDetails />;
          case "/bio/address":
            return <BioAddress />;
          case "/bio/next-of-kin":
            return <BioNextOfKin />;
          case "/bio/bank-details":
            return <BioBankDetails />;
          default:
            return <BioPersonalDetails />;
        }
      })()}
    </BioMainStyles>
  );
}

export default BioMain;

const BioMainStyles = styled.div`
  flex: 0.8;
  height: auto;
  min-height: 40vh;
  background-color: var(--white);

  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;
