import { genOtpPending, genOtpFulfilled, genOtpRejected, updatePassFulfilled } from "./resetSlice";

import { otpRequest, userPasswordUpdate } from "./reset_password_api";

export const sendResetOtp = email => async dispatch => {
    try {
        dispatch(genOtpPending())

        const {status, message} = await otpRequest(email)

        if (status === "success" || "error") {
            return dispatch(genOtpFulfilled({ message, email }))
            
        }
        dispatch(genOtpRejected(message))

    } catch (error) {
        dispatch(genOtpRejected(error.message))
    }
}

export const passwordUpdate = updateData => async dispatch => {
    try {
        dispatch(genOtpPending())

        const {status, message} = await userPasswordUpdate(updateData)

        if (status === "success") {
            return dispatch(updatePassFulfilled(message))
            
        }
        dispatch(genOtpRejected(message))

    } catch (error) {
        dispatch(genOtpRejected(error.message))
    }
}