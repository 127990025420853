import React, { useState, useEffect } from "react";

import { makeStyles, useTheme } from "@mui/styles";

import styled from "styled-components";

import AuthImg from "../assets/images/auth-bg.png";
import Logo from "../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "85%",
      background: theme.palette.secondary.main,
      textTransform: "none",
      marginTop: "20px",
      borderRadius: 8,

      "&:hover": {
        background: theme.palette.secondary.light,
      },
      "&:active": {
        background: theme.palette.secondary.dark,
      },

      "&:disabled": {
        background: "#ffa8b6",
        cursor: "not-allowed !important",
      },
    },
  },
  response: {
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  error: {
    color: theme.palette.secondary.main,
  },

  success: {
    color: theme.palette.success.main,
  },
}));

function How() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div
        style={{ background: "black", textAlign: "left", padding: " 2% 4%" }}
      >
        {" "}
        <img src={Logo} alt="Logo" className="termsI" />
      </div>

      <div
        id="sig"
        style={{
          minWidth: "100%",
          maxWidth: "800px",
          lineHeight: "18px",
          margin: "auto",
          padding: "50px 4%",
          marginTop: "0px",
          fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
          fontSize: "12px",
          background: "white",
          color: "#333333",
        }}
      >
        <p
          style={{
            fontSize: "2.6rem",
            color: "#FF385C",
            textDecoration: "none",
            lineHeight: "40px",
            textTransform: "uppercase",
          }}
        >
          <strong>Whiterose INTERNATIONAL NETWORK (win) HOW IT WORKS?</strong>
        </p>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Whiterose International Network (WIN) marketing platform launched by
          Whiterose International Group (WIG) is an empowerment revolution
          geared towards collective actualization of individual goals on a
          platform that’s best described as incredibly enabling! Propelled by
          the desire to eradicate the prevalent challenges that always
          frustrates beginners in real estate business (of which the founder of
          WIG had a bitter taste of) Whiterose International Network (WIN)
          marketing&nbsp;platform&nbsp;is poised to providing as much as
          practicable, the basic facilities and resources that can propel any
          young driven entrepreneur who aspire to be on the forefront of
          international real estate market in no distant time.&nbsp;
        </p>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          How does Whiterose International Network (WIN)
          marketing&nbsp;platform&nbsp;works?&nbsp;
        </p>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              It begins by you becoming a MEMBER of Whiterose International
              Network (WIN) marketing platform either by referring people to buy
              our product and use part of your first sales bonus to register or
              getting yourself register on Whiterose International Network (WIN)
              marketing platform with a fee of N57,500 Note: N55,000 is the
              registration fee while N2,500 is Whiterose Administrative charge;
              thereafter, your door of endless opportunity to grow beyond your
              widest imagination in international real estate would be flung
              open with no restrictions!
            </p>
          </li>
        </ul>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <p
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>Benefits of membership</strong>
        </p>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              you are guaranteed of 5% of the value of our property As
              Commission 1% sales commission up to your 2nd generation down
              lines.&nbsp;
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={2}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              You are already protected by our transparent and effective
              structure, no circumvention on your referrals.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={3}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              You earn referral bonus of 20% on any of referral I:e your down
              lines on the platform.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={4}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              You have a direct mandate to market our properties on the
              platform.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={5}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              You earn leadership bonus as you are promoted to become a leader
              on the platform.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={6}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              There is also promotional bonus at anytime you are promoted
              according to your performance on the platform.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={7}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              You have and Opportunity to get a car reward and other bonuses. If
              make sales of 200m and in a month on the platform.&nbsp;
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={8}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              You have an Opportunity to own your own dream home, an Opportunity
              for scholarship for your children up to university level, an
              Opportunity for an all expense paid business course in top
              business schools, Free direct mentorship worth 500k, you can have
              access to our facilities for the purposes our marketing our
              properties.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 1<sup>st</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>2</strong>
              <sup>
                <strong>nd</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to a&nbsp;<strong>PRE-ASSOCIATE</strong> while ROSE'S 1
              <sup>st</sup> down-liners becomes Premium members. As a
              Pre-Associate, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N275,000.00</strong> (the sum
              of&nbsp;<strong>N137,500.00</strong> shall be paid cash to ROSE as
              cash bonus while the outstanding bonus is given to ROSE as
              referral bonus, leadership bonus, promotion bonus and performance
              bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N22,916.67</strong> for a duration
              of six (6) months&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              3% Commission from sale of any of our properties by any of her 1
              <sup>st</sup> down-liners&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              2% Commission from sale of any of our properties by any of her 2
              <sup>nd</sup> down-liners.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 2<sup>nd</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>3</strong>
              <sup>
                <strong>rd</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to an&nbsp;<strong>ASSOCIATE</strong> while ROSE'S 1
              <sup>st</sup> and 2<sup>nd</sup> down-liners becomes
              Pre-Associates and Premium members respectively. As an ASSOCIATE,
              ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N1, 375,000.00</strong> (the sum
              of&nbsp;<strong>N687,500.00</strong> shall be paid cash to ROSE as
              cash bonus while the outstanding bonus is given to ROSE as
              referral bonus, leadership bonus, promotion bonus and performance
              bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N57,291.67</strong> for a duration
              of twelve (12) months&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              3% Commission from sale of any of our properties by any of her 1
              <sup>st</sup> down-liners&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              2% Commission from sale of any of our properties by any of her 2
              <sup>nd</sup> down-liners&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              1% Commission from sale of any of our properties by any of her 3
              <sup>rd</sup> down-liners
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 3<sup>rd</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>4</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to an&nbsp;<strong>ELITE</strong> while ROSE'S 1
              <sup>st</sup>, 2<sup>nd</sup> and 3<sup>rd</sup> down-liners
              becomes Associates, Pre-Associates and Premium members
              respectively. As an ELITE, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N6,875,000.00</strong> (the sum
              of&nbsp;<strong>N3,437,500.00</strong> shall be paid cash to ROSE
              as cash bonus while the outstanding bonus is given to ROSE as
              referral bonus, leadership bonus, promotion bonus and performance
              bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              Monthly salary of&nbsp;<strong>N143,229.16</strong> for a duration
              of twenty (24) months&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 4<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>5</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to an&nbsp;<strong>ELITE</strong> while ROSE'S 1
              <sup>st</sup>, 2<sup>nd</sup> and 3<sup>rd</sup> down-liners
              becomes Associates, Pre-Associates and Premium members
              respectively. As an ELITE, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N6,875,000.00</strong> (the sum
              of&nbsp;<strong>N3,437,500.00</strong> shall be paid cash to ROSE
              as cash bonus while the outstanding bonus is given to ROSE as
              referral bonus, leadership bonus, promotion bonus and performance
              bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N143,229.16</strong> for a duration
              of twenty (24) months&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 5<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>6</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to an&nbsp;<strong>EXECUTIVE</strong> while ROSE'S 1
              <sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd,</sup> and 4<sup>th</sup>{" "}
              down-liners becomes Elites, Associates, Pre-Associates and Premium
              members respectively. As an EXECUTIVE, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N34, 375,000.00</strong> (the sum
              of&nbsp;<strong>N17,187,500.00</strong> shall be paid cash to ROSE
              as cash bonus while the outstanding bonus is given to ROSE as
              referral bonus, leadership bonus, promotion bonus and performance
              bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N358, 072.92</strong> for a
              duration of twenty (48) months&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 6<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>7</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to a<strong>&nbsp;MANAGER,</strong> while ROSE'S 1
              <sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd</sup>, 4<sup>th,</sup> and
              5<sup>th</sup> down-liners becomes Executives, Elites, Associates,
              Pre-Associates and Premium members respectively. As a MANAGER,
              ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N171, 875,000.00</strong> (the sum
              of&nbsp;<strong>N85, 937, 500.00</strong> shall be paid cash to
              ROSE as cash bonus while the outstanding bonus is given to ROSE as
              referral bonus, leadership bonus, promotion bonus and performance
              bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N895,182.29&nbsp;</strong>for a
              duration of ninety-six (96) months (e) 5% commission on direct
              sale of any of our properties.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 7<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>8</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to a<strong>&nbsp;DIRECTOR,</strong> while ROSE'S 1
              <sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd</sup>, 4<sup>th</sup> 5
              <sup>th</sup> and 6<sup>th</sup> down-liners becomes Managers,
              Executive, Elites, Associates, Pre-Associates and Premium members
              respectively. As a DIRECTOR, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N859,375,000.00&nbsp;</strong>(the
              sum of&nbsp;<strong>N429,687,500.00</strong> shall be paid cash to
              ROSE as cash bonus while the outstanding bonus is given to ROSE as
              referral bonus, leadership bonus, promotion bonus and performance
              bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N2,237,955.73&nbsp;</strong>for a
              duration of one hundred and ninety-two (192) months&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 8<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>9</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to a<strong>&nbsp;SAPPHIRE DIRECTOR,</strong> while
              ROSE'S 1<sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd</sup>, 4
              <sup>th</sup>, 5<sup>th</sup>, 6<sup>th,</sup> and 7<sup>th</sup>{" "}
              down-liners becomes Directors, Managers, Executive, Elites,
              Associates, Pre-Associates and Premium members respectively. As a
              SAPPHIRE DIRECTOR, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N4,296,875,000.00&nbsp;</strong>(the
              sum of&nbsp;<strong>N2,148,437,500.00&nbsp;</strong>shall be paid
              cash to ROSE as cash bonus while the outstanding bonus is given to
              ROSE as referral bonus, leadership bonus, promotion bonus and
              performance bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N5,594,889.32&nbsp;</strong>for a
              duration of three hundred and eighty-four (384) months&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 9<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>10</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to a<strong>n AMBASSADOR,</strong> while ROSE'S 1
              <sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd</sup>, 4<sup>th</sup>, 5
              <sup>th</sup>, 6<sup>th</sup>, 7<sup>th&nbsp;</sup>and 8
              <sup>th</sup> down-liners becomes Sapphire Directors, Directors,
              Managers, Executive, Elites, Associates, Pre-Associates and
              Premium members respectively. As an AMBASSADOR, ROSE is entitled
              to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N21,484,375,000.00&nbsp;</strong>
              (the sum of&nbsp;<strong>N10,742,187,500.00&nbsp;</strong>shall be
              paid cash to ROSE as cash bonus while the outstanding bonus is
              given to ROSE as referral bonus, leadership bonus, promotion bonus
              and performance bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N13,987,223.30&nbsp;</strong>for a
              duration of seven hundred and sixty -eight (768) months I.e salary
              for life.
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties.
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 10<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>11</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to an<strong>&nbsp;EMERALD AMBASSADOR,</strong> while
              ROSE'S 1<sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd</sup>, 4
              <sup>th</sup>, 5<sup>th</sup>, 6<sup>th</sup>, 7<sup>th</sup>, 8
              <sup>th</sup> and 9<sup>th</sup> down-liners becomes Ambassadors,
              Sapphire Directors, Directors, Managers, Executive, Elites,
              Associates, Pre-Associates and Premium members respectively. As an
              EMERALD AMBASSADOR, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N107,421,875,000.00&nbsp;</strong>
              (the sum of&nbsp;<strong>N53,710,937,500.00&nbsp;</strong>shall be
              paid cash to ROSE as cash bonus while the outstanding bonus is
              given to ROSE as referral bonus, leadership bonus, promotion bonus
              and performance bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N69,936,116.54&nbsp;</strong>for a
              duration of seven hundred and sixty -eight (768) months I.e salary
              for life.
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              When ROSE’s 11<sup>th</sup> down-liners all introduces 5 persons
              each that registers under them respectively (<strong>12</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to a<strong>&nbsp;SAPPHIRE AMBASSADOR,</strong> while
              ROSE'S 1<sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd</sup>, 4
              <sup>th</sup> , 5<sup>th</sup>, 6<sup>th</sup>, 7<sup>th</sup>, 8
              <sup>th</sup>, 9<sup>th</sup> and 10<sup>th</sup> down-liners
              becomes Emerald Ambassadors, Ambassadors, Sapphire Directors,
              Directors, Managers, Executive, Elites, Associates, Pre-Associates
              and Premium members respectively. As a SAPPHIRE AMBASSADOR, ROSE
              is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N537,109,375,000.00&nbsp;</strong>
              (the sum of&nbsp;<strong>N268,554,687,500.00&nbsp;</strong>shall
              be paid cash to ROSE as cash bonus while the outstanding bonus is
              given to ROSE as referral bonus, leadership bonus, promotion bonus
              and performance bonus, which may come as non-cash gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N349,680,582.68&nbsp;</strong>for a
              duration of seven hundred and sixty -eight (768) months I.e salary
              for life.
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties
            </p>
          </li>
        </ol>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          <br />
        </p>
        <ul
          style={{
            marginLeft: "1%",
          }}
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              Finally, when ROSE’s 12<sup>th</sup> down-liners all introduces 5
              persons each that registers under them respectively (
              <strong>13</strong>
              <sup>
                <strong>th</strong>
              </sup>
              <strong>&nbsp;down-liners</strong>) then ROSE is automatically
              promoted to a<strong>&nbsp;DIAMOND AMBASSADOR!</strong> while
              ROSE'S 1<sup>st</sup>, 2<sup>nd</sup>, 3<sup>rd</sup>, 4
              <sup>th</sup>, 5<sup>th</sup>, 6<sup>th</sup>, 7<sup>th</sup>, 8
              <sup>th</sup>, 9<sup>th</sup>, 10<sup>th</sup> and 11<sup>th</sup>{" "}
              down-liners becomes Sapphire Ambassadors, Emerald Ambassadors,
              Ambassadors, Sapphire Directors, Directors, Managers, Executive,
              Elites, Associates, Pre-Associates and Premium members
              respectively. As a DIAMOND AMBASSADOR, ROSE is entitled to:&nbsp;
            </p>
          </li>
        </ul>
        <ol
          style={{
            marginLeft: "3%",
          }}
          type="a"
        >
          <li value={5}>
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              all the benefits of a member&nbsp;
            </p>
          </li>
        </ol>
        <ol
          style={{
            marginLeft: "3%",
          }}
          start={6}
          type="a"
        >
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              a total bonus of&nbsp;<strong>N2,685,546,875,000.00&nbsp;</strong>
              (the sum of&nbsp;<strong>N1,342, 773,437,500.00&nbsp;</strong>
              shall be paid cash to ROSE as cash bonus while the outstanding
              bonus is given to ROSE as referral bonus, leadership bonus,
              promotion bonus and performance bonus, which may come as non-cash
              gifts)&nbsp;
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              monthly salary of&nbsp;<strong>N1,748,402,913.41&nbsp;</strong>for
              a duration of seven hundred and sixty -eight (768) months I.e
              salary for life.
            </p>
          </li>
          <li
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
            <p
              style={{
                fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
                fontSize: "14px",
                color: "#333333",
                textDecoration: "none",
              }}
            >
              5% commission on direct sale of any of our properties.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
}

export default How;
