import { useState, useCallback } from "react";
import EnhancedTable from "components/table-layouts/EnhancedTable1";
import {
  commisssionsHeadCells,
  membershipBonusHeadCells,
  salariesHeadCells,
} from "helpers/dataset/table-header-data";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles, useTheme } from "@mui/styles";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import prettyMoney from "pretty-money";
import DeleteModals from "components/utils/DeleteModals";
import { dateMoment } from "../../utils/Time";
import { BsArrowDown } from "react-icons/bs";
import styled from "styled-components";
import { Modal } from "@mui/material";
import { GiCancel } from "react-icons/gi";
import EmptyTable from "components/table-layouts/EmptyTable";


const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
  moreIcon: {
    cursor: "pointer",
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      fontSize: "1rem",
    },
  },
  paper: {
    "&.MuiPaper-root": {
      boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
    },
  },
  menu: {
    "&.MuiList-root": {
      padding: 0,
    },
  },
  badge: {
    "&.MuiChip-root": {
      borderRadius: 5,
    },
  },
}));

const SalariesTable = ({ com, open, setOpen, request }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleDelete = useCallback(async () => {
    handleCloseDelete();
  });

  const handleCloseDelete = () => setDeleteProperty(false);
  const [deleteProperty, setDeleteProperty] = useState(false);
  const [payoutMessage, setPayoutMessage] = useState({
    status: false,
    message: "",
  });
  const handleOpenDelete = (data) => {
    if (data?.code > 300) {
      if (
        data?.error?.exceptionError ===
        "Error: Payout request ALREADY MADE for this commission."
      ) {
        setPayoutMessage({
          status: data.code < 300 ? true : false,
          message:
            "Your request is currently being processed and you should get your funds into your account within 24-48 hours",
        });
      } else {
        setPayoutMessage({
          status: false,
          message:
            "Sorry we could not make this payout request at the moment, please try again",
        });
      }
    }
    setDeleteProperty(true);
  };

  if (deleteProperty) {
    function greet() {
      setDeleteProperty(false);
      // console.log("Hello world");
    }

    setTimeout(greet, 2000);
  }
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  return (
    <Modal open={open}>
      <Container>
        <IconHolder onClick={() => setOpen(false)}>
          <GiCancel color="white" size={30} />
        </IconHolder>
        {com.length > 0 ? (
          <EnhancedTable
            tab={true}
            headCells={salariesHeadCells}
            rows={com}
            page={page}
            paginationLabel="Commissions per page"
            hasCheckbox={false}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            className="widthIsh1"
          >
            {com.length > 0 &&
              com
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      className="widthIsh"
                    >
                      <TableCell
                        id={labelId}
                        scope="row"
                        className={classes.tableCell}
                      >
                        <input type={"checkbox"} />
                      </TableCell>
                 
                      <TableCell className={classes.tableCell}>
                        {row.member}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                      {prettyMoney(prettyDollarConfig, row.amount)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                      {row ? dateMoment(row.dueDate) : ""}

                      </TableCell>
                    
                      <TableCell>
                        {/* <MoreIcon
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    className={classes.moreIcon}
                  /> */}
                        <MenuItem
                          onClick={() => {
                            request(row).then((res) => {
                              handleOpenDelete(res);
                            });
                          }}
                          className={classes.menuItem}
                          style={{
                            background: "rgba(0, 0, 0, 0.04)",
                            width: "fit-content",
                            cursor: "pointer",
                          }}
                        >
                          Request Payout
                        </MenuItem>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </EnhancedTable>
        ) : (
          <EmptyTable />
        )}
        <DeleteModals
          handleCloseDelete={handleCloseDelete}
          deleteProperty={deleteProperty}
          handleDelete={handleDelete}
          status={payoutMessage.status}
          message={payoutMessage.message}
        />
      </Container>
    </Modal>
  );
};

export default SalariesTable;

const Container = styled.div`
  margin: 10% auto;
`;
const IconHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
`;
