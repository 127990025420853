import { useState } from "react";
import EnhancedTable from "components/table-layouts/EnhancedTable";
import { referralsHeadCells } from "helpers/dataset/table-header-data";
import { referralsRows } from "helpers/dataset/table-rows";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { Rowing } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const CommissionsTable = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  return (
    <EnhancedTable
      headCells={referralsHeadCells}
      rows={referralsRows}
      page={page}
      paginationLabel="Referrals per page"
      hasCheckbox={false}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    >
      {referralsRows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow hover tabIndex={-1} key={row.id}>
              <TableCell id={labelId} scope="row" className={classes.tableCell}>
                <input type={"checkbox"} />
              </TableCell>
              <TableCell id={labelId} scope="row" className={classes.tableCell}>
                {row.date}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.name ? row.name : "no value"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.phone ? row.phone : "no value"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.email ? row.email : "no value"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.level ? row.level : "no value"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.memberId ? row.memberId : "no value"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {row.downlines ? row.downlines : "no value"}
              </TableCell>
            </TableRow>
          );
        })}
    </EnhancedTable>
  );
};

export default CommissionsTable;
