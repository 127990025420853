import React, { useState, useEffect } from "react";
import { banks } from "helpers/dataset/banksList";
import { useNavigate,Link } from "react-router-dom";
import Button from "components/common/Button";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";
import { BASE_URL } from "../../services/BASE_URL";
import axios from "axios";

import styled from "styled-components";

import BioSectionTitle from "components/bio/bio_section_title";
import MLMTextField from "components/common/mlm_text_field1";

import { usePaystackPayment } from "react-paystack";

function BankDetails() {
  const theme = useTheme();

  const { updateProfile } = useActions();
  const { userId } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  const amount = 3000000;

  const [bankDetails, setBankDetails] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
  });

  const [bankNameError, setBankNameError] = useState("");
  const [accountNameError, setAccountNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");

  const { bankName, accountName, accountNumber } = bankDetails;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({ ...bankDetails, [name]: value });

    switch (name) {
      case "bankName":
        if (!value || value === "Select Your Bank") {
          setBankNameError("Please select your bank");
        } else {
          setBankNameError("");
        }
        break;
      case "accountName":
        if (!value) {
          setAccountNameError("Please enter your account name");
        } else {
          setAccountNameError("");
        }
        break;
      case "accountNumber":
        if (!value) {
          setAccountNumberError("Please enter a valid account number");
        } else {
          setAccountNumberError("");
        }
        break;
      default:
        setBankNameError("");
        setAccountNameError("");
        setAccountNumberError("");
    }
  };
  let email = user ? user.email : "";
  console.log(process.env.REACT_APP_PAYMENT_KEY, "jjjj"); //printing it to console

  const paymentConfig = {
    email: email,
    amount: amount,
    publicKey: process.env.REACT_APP_PAYMENT_KEY,
  };

  const initializePayment = usePaystackPayment(paymentConfig);

  const navigate = useNavigate();

  const onSuccess = ({ reference }) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    if (localStorage.token) {
      axios
        .post(
          `${BASE_URL}/api/payment/initialise`,
          {
            userId: parseInt(userId),
            reference: reference,
            amount: amount / 100,
            service: "registration",
            payableTypeId: 0,
            metadata: {},
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          if (data.message === "successful") {
            axios
              .post(
                `${BASE_URL}/api/payment/paystack/hook`,
                {
                  event: "charge.success",
                  data: {
                    id: data.data.payment.id,
                    domain: "string",
                    status: data.data.payment.status,
                    reference: reference,
                    amount: amount / 100,
                    message: data.message,
                    gateway_response: "string",
                    paid_at: "Unknown Type: date",
                    created_at: data.data.payment.createdAt,
                    channel: "string",
                    currency: "string",
                    ip_address: "string",
                    metadata: data.metadata,
                    log: {},
                    fees: "Unknown Type: null",
                    customer: {},
                    authorization: {},
                    plan: "Unknown Type: any",
                  },
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.token}`,
                  },
                }
              )
              .then((response) => {
                const data = response.data;
                if (data.message !== "failed") {
                  navigate("/dashboard");
                }
              })
              .catch((err) => {
                console.log(err.message);
                navigate("/dashboard");
              });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed payment");
  };

  const handleSubmitPayment = (event) => {
    event.preventDefault();

    if (
      (!bankName || bankName === "Select Your Bank") &&
      !accountName &&
      !accountNumber
    ) {
      setBankNameError("Please select your bank");
      setAccountNameError("Please enter your account name");
      setAccountNumberError("Please enter a valid account number");
      return;
    }

    if (!bankName || bankName === "Select Your Bank") {
      setBankNameError("Please select your bank");
      return;
    }

    if (!accountName) {
      setAccountNameError("Please enter your account name");
      return;
    }

    if (!accountNumber) {
      setAccountNumberError("Please enter a valid account number");
      return;
    }

    if (bankNameError || accountNumberError || accountNameError) return;

    updateProfile({
      userId,
      bankName: bankDetails.bankName,
      accountName: bankDetails.accountName,
      accountNumber: bankDetails.accountNumber,
    });
    initializePayment(onSuccess, onClose);
  };

  useEffect(() => {
    if (user) {
      setBankDetails({
        ...bankDetails,
        bankName: user.bankName ? user.bankName : "",
        accountName: user.accountName ? user.accountName : "",
        accountNumber: user.accountNumber ? user.accountNumber : "",
      });
    }

    // eslint-disable-next-line
  }, [user]);

  return (
    <BankDetailsStyles className="prodivSec">
      
      <BioSectionTitle
        title="Bank Details"
        subTitle="Enter your bank details"
      />

      <form onSubmit={handleSubmitPayment}>
        <div style={{ marginBottom: "2rem" }}>
          <div
            className="select-container"
            style={{
              borderColor: bankNameError ? theme.palette.secondary.main : null,
            }}
          >
            <select
              name="bankName"
              value={bankDetails.bankName}
              onChange={handleChange}
            >
              <option value="Select Your Bank">Select Your Bank</option>
              {banks.map((bank) => (
                <option value={bank} key={bank}>
                  {bank}
                </option>
              ))}
            </select>
          </div>
          {bankNameError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {bankNameError}
            </small>
          )}
        </div>

        <div style={{ marginBottom: "2rem" }}>
          <MLMTextField
            hintText="Enter account name"
            inputType="text"
            inputTextColor="var(--black)"
            labelText="Account name"
            labelColor="var(--black)"
            name="accountName"
            onChange={handleChange}
            fieldValue={bankDetails.accountName}
            divBorder={
              accountNameError
                ? `1px solid ${theme.palette.secondary.main}`
                : null
            }
          />
          {accountNameError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {accountNameError}
            </small>
          )}
        </div>
        <div>
          <MLMTextField
            hintText="Enter account number"
            inputType="number"
            inputTextColor="var(--black)"
            labelText="Account number"
            labelColor="var(--black)"
            name="accountNumber"
            onChange={handleChange}
            fieldValue={`${bankDetails.accountNumber}`}
            divBorder={
              accountNumberError
                ? `1px solid ${theme.palette.secondary.main}`
                : null
            }
          />
          {accountNumberError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {accountNumberError}
            </small>
          )}
        </div>

        <div className="proceed">
        <h1><Link to='/dashboard' style={{color: '#000',
    fontSize: '1.2rem',
    fontWeight: 500, marginLeft:'-55%'}}>Skip</Link></h1>
          <Button
            type="submit"
            title="Pay registration fee"
            background={theme.palette.secondary}
          />
        </div>
      </form>
    </BankDetailsStyles>
  );
}

export default BankDetails;

const BankDetailsStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  form {
    margin-top: 1rem;

    .proceed {
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: 2rem;
    }
  }

  .select-container {
    /* width: 93.5%; */
    width: 100%;
    height: 42px;
    background-color: var(--white);
    border: 1px solid var(--gray-1);
    border-radius: 8px;
    display: flex;
    align-content: center;
    padding-left: 0px;
    padding-right: 0px;

    select {
      width: 100%;
      border: none;
      outline: none;
      background-color: var(--white);
      color: var(--main-bg);

      option {
        color: var(--main-bg);
      }
    }
  }
`;
