import styled from "styled-components";

import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import ProfileNav from "components/profile/profile_nav";
import ProfileMain from "components/profile/profile_main";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    minHeight: "160vh",
    display: "flex",
    flexDirection: "column",
    background: "#f5f5f5",
  },
  top: {
    flex: 0.15,
    background: theme.palette.primary.main,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "3rem",
    position: "relative",
  },
  backBtn: {
    border: `1px solid ${theme.palette.common.grey}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 8,
    cursor: "pointer",
    color: "#fff",
  },
  bottom: {
    flex: 0.83,
    marginLeft: "3.5rem",
    marginRight: "3.5rem",
    marginTop: -120,
  },

  metaForm: {
    position: "absolute",
    minHeight: 400,
    width: "100%",
    background: "#fff",
    borderRadius: 16,
    marginTop: 36,
    display: "flex",
  },
}));

const UserProfile = () => {
  const classes = useStyles();

  const { userId } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);

  const { loadUser } = useActions();

  useEffect(() => {
    loadUser(userId && userId);

    // eslint-disable-next-line
  }, [userId]);

  return (
    <ProfileStyles>

    <div className={classes.container}>
      <div className={classes.top}>
        <Link to="/" className={classes.backBtn}>
          Back
        </Link>
        <div
          style={{
            marginTop: "5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: 30, color: "#fff" }}>Registration</h3>
          <p style={{ color: "#fff", whiteSpace: "nowrap", marginLeft: "5%" }}>
            ID: {user?.id}
          </p>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={`${classes.metaForm} firstRContainer firstRContainer2`}>
          <ProfileNav />

          <ProfileMain />
        </div>
      </div>
    </div>
    </ProfileStyles>

  );
};

export default UserProfile;

const ProfileStyles = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-2);

  .top {
    flex: 0.15;
    background-color: var(--main-black);
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3rem;
    position: relative;

    .back-btn {
      border: 1px solid var(--gray-3);
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 8px;
      cursor: pointer;
    }

    h3 {
      color: var(--white);
      font-size: 30px;
      margin-top: 5rem;
    }
  }

  .bottom {
    flex: 0.83;
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    margin-top: -120px;

    .meta-form {
      position: absolute;
      min-height: 400px;
      width: 90vw;
      background-color: var(--white);
      border-radius: 16px;
      margin-top: 36px;
      margin-left: 2.5rem;
      margin-right: 3rem;
      display: flex;
      flex-direction: row;
    }
  }
`;
