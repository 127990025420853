import React from "react";

import styled from "styled-components";

import { AiOutlineInfoCircle } from "react-icons/ai";

import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import prettyMoney from "pretty-money";

function StatCard({ title, amount, amountNum, image, icon, message }) {
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  return (
    <StatCardStyles className="rowStats">
      <div className="top">
        <div className="title" style={{ marginLeft: "0px" }}>
          <h5>{title}</h5>
          <AiOutlineInfoCircle
            data-tip={message}
            data-for="msgTip"
            data-place="top"
          />
          <ReactTooltip id="msgTip" html={true} />
        </div>
        <img src={icon} alt="icon" />
      </div>
      <span style={{ fontSize: "25px" }}>
        {amountNum == 0 || amountNum
          ? amountNum
          : prettyMoney(prettyDollarConfig, amount)}
      </span>
    </StatCardStyles>
  );
}

export default StatCard;

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  // image: PropTypes.,
};

const StatCardStyles = styled.div`
  width: 32%;
  min-width: 180px;
  height: 120px;
  border-radius: 8px;
  background: var(--white);
  border: 1px solid rgba(4, 4, 6, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 2.5rem;
  position: relative;

  @media (max-width: 768px) {
    width: 230px;
  }

  .top {
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;

      h5 {
        min-width: 100px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: var(--gray-3);
      }

      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    img {
      height: 27px;
      width: 27px;
    }
  }

  span {
    font-weight: 600;
    font-size: 35px;
    font-family: Inter;
    font-style: normal;
    color: #191c1f;
    position: absolute;
    bottom: 10px;
  }
`;
