import React from "react";

import { useLocation } from "react-router-dom";
import Commissions from "../../pages/commissions";
import Dashboard from "../../pages/dashboard";
import Downlines from "../../pages/downlines";
import FirstGenDownlines from "../downlines/first_gen";
import SecondGenDownlines from "../downlines/second_gen";
import Properties from "../../pages/properties";
import Referrals from "../../pages/referrals";
import Settings from "../../pages/settings";
import { makeStyles } from "@mui/styles";
import MembershipBonus from "pages/membership_bonus";
import SalesBonus from "pages/sales_bonus";

const useStyles = makeStyles((theme) => ({
  mainStyles: {
    height: "auto",
    minHeight: "100vh",
    width: "100%",
    display: "flex",
  },
}));
function HomeMain({ menuSlideIn }) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <main
      className={classes.mainStyles}
      style={{ paddingLeft: menuSlideIn ? "5rem" : "270.5px" }}
    >

      {(() => {
        switch (location.pathname) {
          case "/dashboard":
            return <Dashboard />;
          case "/commissions":
            return <Commissions />;
          case "/sales-bonus":
            return <SalesBonus />;
          case "/membership-bonus":
            return <MembershipBonus />;
          case "/properties":
            return <Properties />;
          case "/referrals":
            return <Referrals />;
          case "/downlines":
            return <Downlines />;
          case `/downlines/${location.pathname.split("/")[2]}`:
            return <FirstGenDownlines params={location.pathname.split("/")[2]} />;
          case "/settings":
            return <Settings />;
          default:
            return <Dashboard />;
        }
      })()}
    </main>
  );
}

export default HomeMain;
