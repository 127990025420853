import React, { useState } from "react";
import styled from "styled-components";

import AppButton from "../common/app_button";

import CloseIcon from "../../assets/icons/close_icon.svg";

function WithdrawFundsDialog({ toggleWithdrawalDialog }) {
  // eslint-disable-next-line
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleAccountSelection = (e) => {
    setSelectedAccount(e.target.value);
  };

  const initiateWithdrawal = () => {};

  return (
    <WithdrawFundsDialogStyles>
      <div className="row">
        <h3>Withdrawal</h3>
        <img
          src={CloseIcon}
          onClick={toggleWithdrawalDialog}
          alt="close icon"
        />
      </div>
      <hr />
      <h5>Withdraw from which account?</h5>
      <div className="type">
        <select defaultValue="Select Account" onChange={handleAccountSelection}>
          <option selected>Select Account</option>
          <option>Commissions</option>
          <option>Sales Bonus</option>
          <option>Discounted Salary</option>
        </select>
      </div>
      <hr />
      <h5>Amount of withdrawal</h5>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', border: 'solid 1px #ccc', borderRadius: '10px', padding: '15px 0px' }}>
        <h2>
        <span style={{ textDecoration: 'line-through', textDecorationStyle: 'double' }}>N</span>
        <span contentEditable style={{ outline: 'none', border: 'none' }}>0.00</span>
        </h2>
        <p>Amount</p>
      </div>
      <AppButton
        onClick={initiateWithdrawal}
        title="Withdraw fund"
        width="100%"
        bgColor="var(--button-bg)"
      />
    </WithdrawFundsDialogStyles>
  );
}

export default WithdrawFundsDialog;

const WithdrawFundsDialogStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: var(--main-bg);
    }

    img {
      width: 30px;
      height: 30px;
      color: var(--main-bg);
      cursor: pointer;
    }
  }

  hr {
    height: 0px;
    border: 0.5px solid var(--gray-2);
  }

  h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--main-bg);

    &:last-child {
      color: var(--white);
    }
  }

  .type {
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(4, 4, 6, 0.1);
    box-sizing: border-box;
    border-radius: 8px;

    select {
      outline: none;
      border: none;
      width: 100%;
      font-size: 14px;

      option {
        border: none;
      }

      &:focus {
        border: none;
      }
    }
  }

  textarea {
    resize: none;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    border: 1px solid var(--gray-1);
    border-radius: 8px;
    padding: 15px;
    color: var(--main-bg);
    background-color: white;
    margin-bottom: 1rem;

    &:focus {
      outline: none;
    }
  }
`;
