import { useState } from "react";
import EnhancedTable from "components/table-layouts/EnhancedTable";
import { downlinesHeadCells } from "helpers/dataset/table-header-data";
// import { downlinesRows } from "helpers/dataset/table-rows";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { dateMoment } from "../../utils/Time";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const DownlinesTable = ({ downlinesRows }) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  console.log("jjj", downlinesRows);

  return (
    <EnhancedTable
      headCells={downlinesHeadCells}
      rows={downlinesRows}
      page={page}
      paginationLabel="Downlines per page"
      hasCheckbox={false}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
    >
      {downlinesRows[0].name.length > 0 &&
        downlinesRows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow hover tabIndex={-1} key={index}>
                <TableCell
                  id={labelId}
                  scope="row"
                  className={classes.tableCell}
                >
                  <input type={"checkbox"} />
                </TableCell>

                <TableCell
                  id={labelId}
                  scope="row"
                  className={classes.tableCell}
                >
                  {row ? dateMoment(row.date_of_reg) : "No Value"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row
                    ? (`${row.name}`.trim() === "")
                      ? "No Value"
                      : row.name
                    : "No Value"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row
                    ? (row.phone_number === "")
                      ? "No Value"
                      : row.phone_number
                    : "No Value"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row
                    ? (`${row.email}`.trim() === "")
                      ? "No Value"
                      : row.email
                    : "No Value"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row
                    ? (`${row.level}`.trim() === "")
                      ? "No Value"
                      : row.level
                    : "No Value"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row
                    ? (`${row.member_id}`.trim() === "")
                      ? "No Value"
                      : row.member_id
                    : "No Value"}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.status === "inactive" ? (
                    <div className="SIA">{row.status}</div>
                  ) : (
                    <div className="SA">
                      {row.status === "active" ? "active" : row.status}
                    </div>
                  )}{" "}
                </TableCell>
              </TableRow>
            );
          })}
    </EnhancedTable>
  );
};

export default DownlinesTable;
