import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import noProperty from "assets/images/No_property_listed_image.png";
import { useTheme } from "@mui/styles";

const EmptyProperty = () => {
  const theme = useTheme();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ width: "100%", height: 400 }} >
      <Grid item>
        <img src={noProperty} alt="No Property Listed" />
      </Grid>
      <Grid item>
        <Typography variant="h4" color="primary" style={{ fontSize: "1.35rem" }} gutterBottom >
          No Property listed
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" color={theme.palette.common.grey} style={{ fontSize: "15px" }} className='proTextE' >
          Properties will be shown as soon as they are being added
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyProperty;
