import { useState, useCallback } from "react";
import EnhancedTable from "components/table-layouts/EnhancedTable";
import {
  commisssionsHeadCells,
  membershipBonusHeadCells,
} from "helpers/dataset/table-header-data";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles, useTheme } from "@mui/styles";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import prettyMoney from "pretty-money";
import DeleteModals from "components/utils/DeleteModals";
import { dateMoment } from "../../utils/Time";
import { BsArrowDown } from "react-icons/bs";
import SalariesTable from "components/salariesTable/TableData";
import BonuseView from "components/otherBonuses";
import { useSelector } from "react-redux";
import { BASE_URL } from "services/BASE_URL";
const { default: axios } = require("axios");

const commissionsRows = [];

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
  moreIcon: {
    cursor: "pointer",
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      fontSize: "1rem",
    },
  },
  paper: {
    "&.MuiPaper-root": {
      boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
    },
  },
  menu: {
    "&.MuiList-root": {
      padding: 0,
    },
  },
  badge: {
    "&.MuiChip-root": {
      borderRadius: 5,
    },
  },
}));

const MembershipTable = ({ com }) => {
  console.log("com", com);
  const classes = useStyles();
  const theme = useTheme();
  const [openSalary, setShowSalary] = useState(false);
  const [openCashBonus, setShowCashBonus] = useState(false);
  const [openDiscount, setShowDiscount] = useState(false);
  const [CashBonusData, setCashBonusData] = useState({});
  const [DiscountedSalaryData, setDiscountedSalaryData] = useState({});

  const [salaryData, setSalaryData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(true);
  const handleDelete = useCallback(async () => {
    handleCloseDelete();
  });

  const handleCloseDelete = () => setDeleteProperty(false);
  const [deleteProperty, setDeleteProperty] = useState(false);
  const [payoutMessage, setPayoutMessage] = useState({
    status: false,
    message: "",
  });
  const handleOpenDelete = (data) => {
    if (data?.code > 300) {
      if (
        data?.error?.exceptionError ===
        "Error: Payout request ALREADY MADE for this commission."
      ) {
        setPayoutMessage({
          status: data.code < 300 ? true : false,
          message:
            "Your request is currently being processed and you should get your funds into your account within 24-48 hours",
        });
      } else {
        setPayoutMessage({
          status: false,
          message:
            "Sorry we could not make this payout request at the moment, please try again",
        });
      }
    }
    setDeleteProperty(true);
  };
  const { user } = useSelector((state) => state.user);
  // console.log("user", user);

  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  const cashBonuses = (el) => ({
    Date: dateMoment(el.createdAt) ,
    Client: el.member,
    "Client Code": el.memberCode,
    Amount: prettyMoney(prettyDollarConfig, el.amount),
  });

  const requestPayoutForMembership = async (d, payoutType) => {
    const datat = {
      // salesBonusId: d.id,
      referrerCode: user.referrerCode,
      uplineLevel: d.uplineLevel,
      ammount: `${d.bonus}`,
      payoutType,
      status: user.statu,
      bank: user.bankName,
      bankAccountName: user.accountName,
      accountNumber: user.accountNumber,
      membershipBonusId: d.membershipBonusId,
      memberFullname: d.member,
    };
    try {
      const { data } = await axios.post(`${BASE_URL}/api/payouts/new`, datat, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return data;
    } catch (error) {
      return error.response.data;
    }
  };
  const requestPayout = async (d) => {
    const datat = {
      // salesBonusId: d.id,
      referrerCode: user.referrerCode,
      memberFullname: d.member,
      uplineLevel: d.uplineLevel,
      ammount: `${d.bonus}`,
      payoutType: "salary",
      status: user.statu,
      bank: user.bankName,
      bankAccountName: user.accountName,
      accountNumber: user.accountNumber,
      membershipBonusId: d.membershipBonusId,
    };
    try {
      const { data } = await axios.post(`${BASE_URL}/api/payouts/new`, datat, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return data;
    } catch (error) {
      return error.response.data;
    }
  };
  return (
    <>
      <EnhancedTable
        tab={true}
        headCells={membershipBonusHeadCells}
        rows={com}
        page={page}
        paginationLabel="Commissions per page"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        className="widthIsh1"
        style={{ color: "red" }}
      >
        {com.length > 0 &&
          com
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow hover tabIndex={-1} key={row.id} className="widthIsh">
                  <TableCell
                    id={labelId}
                    scope="row"
                    className={classes.tableCell}
                  >
                    <input type={"checkbox"} />
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    className={classes.tableCell}
                  >
                    {row ? dateMoment(row.createdAt) : ""}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.referralCode}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prettyMoney(prettyDollarConfig, row.referralBonus)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <ManageSalariesBtn
                      data={salaryData}
                      onClick={() => {
                        setShowDiscount(true);
                        setDiscountedSalaryData(
                          row.discountedsalary ? row.discountedsalary : {}
                        );
                      }}
                    />
                  </TableCell>
                  {/* <TableCell className={classes.tableCell}>
                    <ManageSalariesBtn
                      data={salaryData}
                      onClick={() => {
                        setShowCashBonus(true);
                        setCashBonusData(row.cashbonus ? row.cashbonus : {});
                      }}
                    />
                  </TableCell> */}
                  <TableCell
                    style={{ position: "relative", zIndex: 0 }}
                    className={classes.tableCell}
                  >
                    <ManageSalariesBtn
                      data={salaryData}
                      onClick={() => {
                        setShowSalary(true);
                        setSalaryData(
                          row.salaries?.length > 0 ? row.salaries : []
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Chip
                      label={row.paid ? "Paid" : "unpaid"}
                      className={classes.badge}
                      style={{
                        background: row.paid
                          ? theme.palette.common.lightGreen
                          : theme.palette.common.lightYellow,
                        color: row.paid
                          ? theme.palette.success.main
                          : theme.palette.warning.main,
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
      </EnhancedTable>
      <DeleteModals
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
        handleDelete={handleDelete}
        status={payoutMessage.status}
        message={payoutMessage.message}
      />
      <SalariesTable
        open={openSalary}
        setOpen={setShowSalary}
        com={salaryData}
        request={requestPayout}
      />
      <BonuseView
        title={"Cash Bonus"}
        chashBonus={CashBonusData}
        data={
          Object.keys(CashBonusData).length > 0
            ? cashBonuses(CashBonusData)
            : null
        }
        open={openCashBonus}
        setOpen={setShowCashBonus}
        request={() => {
          requestPayoutForMembership(CashBonusData, "cashbonus").then(() => {
            handleOpenDelete();
          });
        }}
      />
      <BonuseView
        title={"Discounted Salary"}
        discount={DiscountedSalaryData}
        data={
          Object.keys(DiscountedSalaryData).length > 0
            ? cashBonuses(DiscountedSalaryData)
            : null
        }
        open={openDiscount}
        setOpen={setShowDiscount}
        request={() => {
          requestPayoutForMembership(DiscountedSalaryData,"discountedsalary").then((res) => {
            setTimeout(() => handleOpenDelete(res), 500);
            // handleOpenDelete(res);
          });
        }}
      />
    </>
  );
};

const ManageSalariesBtn = (props) => {
  const classes = useStyles();

  return (
    <MenuItem
      {...props}
      // onClick={() => handleOpenDelete(row.id)}
      className={`${classes.menuItem}`}
      style={{
        background: "rgba(0, 0, 0, 0.04)",
        width: "fit-content",
        cursor: "pointer",
        // position: "relative",
      }}
    >
      View
      <BsArrowDown />
      {/* <SalariesTable com={data} /> */}
    </MenuItem>
  );
};
export default MembershipTable;
