import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import PageRoutes from "components/Routes/Routes";
import ScrollToTop from "components/common/scroll_to_top";
import { useActions } from "hooks/useActions";
import { theme } from "./components/theme";
import setAuthToken from "services/utils/setAuthToken";
import './index.css'
function App() {
  const { loadUser } = useActions();
  const { userId } = useSelector((state) => state.auth);

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      loadUser(userId && userId);
    }

    // eslint-disable-next-line
  }, [userId]);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <PageRoutes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
