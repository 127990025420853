import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";

import styled from "styled-components";
import DashboardHeader from "components/dashboard/headerD";

import DashboardBody from "../components/dashboard/body";
import '../index.css'

function Dashboard() {
  const { loadUser } = useActions();
  const { userId } = useSelector((state) => state.auth);

  useEffect(() => {
    loadUser(userId && userId);

    // eslint-disable-next-line
  }, [userId]);
  return (
    <> 

    <DashboardStyles>
      
      <DashboardHeader />
      <DashboardBody />
    </DashboardStyles>
    </>
   
  );
}

export default Dashboard;

const DashboardStyles = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-left: -270px;
  }
`;
