export const commisssionsHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date",
  },
  {
    id: 2,
    label: "Client",
  },
  {
    id: 3,
    label: "Client ID",
  },
  {
    id: 4,
    label: "Property",
  },
  {
    id: 5,
    label: "Commission",
  },
  {
    id: 6,
    label: "Status",
  },
  {
    id: 7,
    label: "Action",
  },
];

export const salesBonusHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date",
  },
  {
    id: 2,
    label: "Up Line",
  },
  {
    id: 3,
    label: "Client ID",
  },
  {
    id: 4,
    label: "Property",
  },
  {
    id: 5,
    label: "Commission",
  },
  {
    id: 5,
    label: "Bonus",
  },
  {
    id: 6,
    label: "Status",
  },
  {
    id: 6,
    label: "Action",
  },
];
export const salariesHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },

  {
    id: 2,
    label: "Member Name",
  },
  {
    id: 3,
    label: "Salary",
  },
  {
    id: 4,
    label: "Due Date",
  }
  ,
  {
    id: 5,
    label: "Action",
  }
];
export const membershipBonusHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date Earned",
  },
  {
    id: 2,
    label: "Referral Code",
  },
  {
    id: 2,
    label: "Referral Bonus",
  },
  {
    id: 7,
    label: "Discounted Salary",
  },
  {
    id: 7,
    label: "Salaries",
  },
  {
    id: 6,
    label: "Status",
  },
];
// <<<----- REFERRALS ------->>>
export const referralsHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date of Reg",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 3,
    label: "Phone Number",
  },
  {
    id: 4,
    label: "Email Address",
  },
  {
    id: 5,
    label: "Level",
  },
  {
    id: 6,
    label: "Member ID",
  },
  {
    id: 7,
    label: "Downlines",
  },
];

// <<<----- Downlines First Gen ------->>>
export const downlinesHeadCells = [
  {
    id: 0,
    label: <input type="checkbox" />,
  },
  {
    id: 1,
    label: "Date of Reg",
  },
  {
    id: 2,
    label: "Name",
  },
  {
    id: 3,
    label: "Phone Number",
  },
  {
    id: 4,
    label: "Email Address",
  },
  {
    id: 5,
    label: "Level",
  },
  {
    id: 6,
    label: "Member ID",
  },
  {
    id: 7,
    label: "Status",
  },
];
