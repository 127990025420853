import { useState, useCallback } from "react";
import EnhancedTable from "components/table-layouts/EnhancedTable";
import { commisssionsHeadCells } from "helpers/dataset/table-header-data";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles, useTheme } from "@mui/styles";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import prettyMoney from "pretty-money";
import DeleteModals from "components/utils/DeleteModals";
import { dateMoment } from "../../utils/Time";

const commissionsRows = [];

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
  moreIcon: {
    cursor: "pointer",
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      fontSize: "1rem",
    },
  },
  paper: {
    "&.MuiPaper-root": {
      boxShadow: "-1px 0px 10px -2px rgba(0,0,0,0.15)",
    },
  },
  menu: {
    "&.MuiList-root": {
      padding: 0,
    },
  },
  badge: {
    "&.MuiChip-root": {
      borderRadius: 5,
    },
  },
}));

const CommissionsTable = ({ com, request }) => {
  console.log(com, "connn");
  const classes = useStyles();
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(true);
  const handleDelete = useCallback(async () => {
    handleCloseDelete();
  });
  const [payoutMessage, setPayoutMessage] = useState({
    status: false,
    message: "",
  });
  const handleCloseDelete = () => setDeleteProperty(false);
  const [deleteProperty, setDeleteProperty] = useState(false);
  const handleOpenDelete = (data) => {
    if (data?.code > 300) {
      if (
        data?.error?.exceptionError ===
        "Error: Payout request ALREADY MADE for this commission."
      ) {
        setPayoutMessage({
          status: data.code < 300 ? true : false,
          message:
            "Your request is currently being processed and you should get your funds into your account within 24-48 hours",
        });
      } else {
        setPayoutMessage({
          status: false,
          message:
            "Sorry we could not make this payout request at the moment, please try again",
        });
      }
    }
    setDeleteProperty(true);
  };

  // if (deleteProperty) {
  //   function greet() {
  //     setDeleteProperty(false);
  //     // console.log("Hello world");
  //   }

  //   setTimeout(greet, 2000);
  // }
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  return (
    <>
      <EnhancedTable
        tab={true}
        headCells={commisssionsHeadCells}
        rows={com}
        page={page}
        paginationLabel="Commissions per page"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        className="widthIsh1"
      >
        {com.length > 0 &&
          com
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow hover tabIndex={-1} key={row.id} className="widthIsh">
                  <TableCell
                    id={labelId}
                    scope="row"
                    className={classes.tableCell}
                  >
                    <input type={"checkbox"} />
                  </TableCell>
                  <TableCell
                    id={labelId}
                    scope="row"
                    className={classes.tableCell}
                  >
                    {row ? dateMoment(row.createdAt) : ""}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.clientFullName}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.clientId}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.propertyName
                      ? `${row.propertyName.slice(0, 30)}...`
                      : "no property"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prettyMoney(prettyDollarConfig, row.commission)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Chip
                      label={row.paid ? "Paid" : "unpaid"}
                      className={classes.badge}
                      style={{
                        background: row.paid
                          ? theme.palette.common.lightGreen
                          : theme.palette.common.lightYellow,
                        color: row.paid
                          ? theme.palette.success.main
                          : theme.palette.warning.main,
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    {/* <MoreIcon
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    className={classes.moreIcon}
                  /> */}
                    <MenuItem
                      onClick={() => {
                        request(row).then((res) => {
                          setTimeout(() => handleOpenDelete(res), 500);
                        });
                      }}
                      className={classes.menuItem}
                      style={{
                        background: "rgba(0, 0, 0, 0.04)",
                        width: "fit-content",
                        cursor: "pointer",
                      }}
                    >
                      Request Payout
                    </MenuItem>
                  </TableCell>
                </TableRow>
              );
            })}
      </EnhancedTable>
      <DeleteModals
        handleCloseDelete={handleCloseDelete}
        deleteProperty={deleteProperty}
        handleDelete={handleDelete}
        status={payoutMessage.status}
        message={payoutMessage.message}
      />
    </>
  );
};

export default CommissionsTable;
