import React, { useState, useEffect } from "react";
import Button from "components/common/Button";
import { useTheme } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";

import styled from "styled-components";

import BioSectionTitle from "components/bio/bio_section_title";
import MLMTextField from "components/common/mlm_text_field1";
import PhoneNumberInput from "components/common/PhoneNumberInput";

function BioNextOfKin() {
  const theme = useTheme();
  const navigate = useNavigate();
  
  const { updateProfile } = useActions();
  const { userId } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  const [kinDetails, setKinDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    relationship: "",
    address: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");

  // ERROR MESSAGE STATES
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [relationshipError, setRelationshipError] = useState("");
  const [addressError, setAddressError] = useState("");

  const { firstName, lastName, email, relationship, address } = kinDetails;

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);

    if (!value) {
      setPhoneNumberError("Enter next of kin phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setKinDetails({ ...kinDetails, [name]: value });

    switch (name) {
      case "firstName":
        if (!value) {
          setFirstNameError("Enter next of kin first name");
        } else {
          setFirstNameError("");
        }
        break;
      case "lastName":
        if (!value) {
          setLastNameError("Enter next of kin last name");
        } else {
          setLastNameError("");
        }
        break;
      case "email":
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          setEmailError("");
        } else {
          setEmailError("Please enter a valid email");
        }
        break;
      case "relationship":
        if (!value) {
          setRelationshipError(
            "Please state your relationship with your next of kin"
          );
        } else {
          setRelationshipError("");
        }
        break;
      case "address":
        if (!value) {
          setAddressError("Enter next of kin address");
        } else {
          setAddressError("");
        }
        break;
      default:
        setFirstNameError("");
        setLastNameError("");
        setEmailError("");
        setRelationshipError("");
        setAddressError("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !firstName.trim() &&
      !lastName.trim() &&
      !email.trim() &&
      !relationship.trim() &&
      !phoneNumber &&
      !address
    ) {
      setFirstNameError("Enter next of kin first name");
      setLastNameError("Enter next of kin last name");
      setEmailError("Enter next of kin email");
      setRelationshipError(
        "Please state your relationship with your next of kin"
      );
      setPhoneNumberError("Enter next of kin phone number");
      setAddressError("Enter next of kin address");
      return;
    }

    if (!firstName.trim()) {
      setFirstNameError("Enter next of kin first name");
      return;
    }

    if (!lastName.trim()) {
      setLastNameError("Enter next of kin last name");
      return;
    }

    if (!email.trim()) {
      setEmailError("Enter next of kin email");
      return;
    }

    if (!phoneNumber.trim()) {
      setPhoneNumberError("Enter next of kin phone number");
      return;
    }

    if (!relationship.trim()) {
      setRelationshipError(
        "Please state your relationship with your next of kin"
      );
    }

    if (!address.trim()) {
      setPhoneNumberError("Please enter your phone number");
    }

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      phoneNumberError ||
      addressError ||
      relationshipError
    ){
      return;
    }
    updateProfile({
      userId,
      navigate,
      nextPath: "/bio/bank-details",
      nextofkin: {
        firstName: kinDetails.firstName,
        lastName: kinDetails.lastName,
        email: kinDetails.email,
        phone: phoneNumber,
        relationship: kinDetails.relationship,
        address: kinDetails.address
      }
    });
  };

  
  useEffect(() => {
    if (user) {
      setKinDetails({
        ...kinDetails,
        firstName: user.nextofkin?.firstName ? user.nextofkin?.firstName : kinDetails.firstName,
        lastName: user.nextofkin?.lastName ? user.nextofkin?.lastName : kinDetails.lastName,
        email: user.nextofkin?.email ? user.nextofkin?.email : kinDetails.email,
        relationship: user.nextofkin?.relationship ? user.nextofkin?.relationship : kinDetails.relationship,
        address: user.nextofkin?.address ? user.nextofkin?.address : kinDetails.address,
      });
      setPhoneNumber(user.nextofkin?.phone ? user.nextofkin?.phone : phoneNumber)
    }

    // eslint-disable-next-line
  }, [user]);


  return (
    <NextOfKinStyles>
      <BioSectionTitle
        title="Next of Kin"
        subTitle="Enter the details of your next of kin"
      />

      <div style={{ marginBottom: "2rem" }}>
        <Row>
          <div style={{ width: "100%" }}>
            <MLMTextField
              width="100%"
              hintText="Enter first name"
              inputType="text"
              inputTextColor="var(--black)"
              labelText="First name"
              labelColor="var(--black)"
              name="firstName"
              onChange={handleChange}
              fieldValue={kinDetails.firstName}
              divBorder={
                firstNameError
                  ? `1px solid ${theme.palette.secondary.main}`
                  : null
              }
            />
            {firstNameError && (
              <small style={{ color: theme.palette.secondary.main }}>
                {firstNameError}
              </small>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <MLMTextField
              width="100%"
              hintText="Enter last name"
              inputType="text"
              inputTextColor="var(--black)"
              labelText="Last name"
              labelColor="var(--black)"
              name="lastName"
              onChange={handleChange}
              fieldValue={kinDetails.lastName}
              divBorder={
                lastNameError
                  ? `1px solid ${theme.palette.secondary.main}`
                  : null
              }
            />
            {lastNameError && (
              <small style={{ color: theme.palette.secondary.main }}>
                {lastNameError}
              </small>
            )}
          </div>
        </Row>
      </div>

      <div className="row" style={{ marginBottom: "2rem" }}>
        <div style={{ width: "100%" }}>
          <MLMTextField
            width="100%"
            hintText="Enter email address"
            inputType="email"
            inputTextColor="var(--black)"
            labelText="Email Address"
            labelColor="var(--black)"
            name="email"
            onChange={handleChange}
            fieldValue={kinDetails.email}
            divBorder={
              emailError ? `1px solid ${theme.palette.secondary.main}` : null
            }
          />
          {emailError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {emailError}
            </small>
          )}
        </div>
        <div style={{ width: "100%" }}>
          <label
            style={{
              fontSize: "15px",
              display: "block",
              marginBottom: "1rem",
              fontWeight: 700,
              color: theme.palette.primary.main,
            }}
          >
            Phone Number
          </label>

          <PhoneNumberInput
            handlePhoneChange={handlePhoneChange}
            phoneNumber={phoneNumber}
            error={phoneNumberError}
          />
          {phoneNumberError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {phoneNumberError}
            </small>
          )}
        </div>

        {/* <InternationalPhoneInput /> */}
      </div>

      <div style={{ width: "100%" }}>
        <MLMTextField
          hintText="State your relationship"
          inputType="text"
          inputTextColor="var(--black)"
          labelText="Relationship"
          labelColor="var(--black)"
          name="relationship"
          onChange={handleChange}
          fieldValue={kinDetails.relationship}
          divBorder={
            relationshipError
              ? `1px solid ${theme.palette.secondary.main}`
              : null
          }
        />
        {relationshipError && (
          <small style={{ color: theme.palette.secondary.main }}>
            {relationshipError}
          </small>
        )}
      </div>

      <div>
        <h6 className="title">Address</h6>
        <textarea
          name="address"
          value={kinDetails.address}
          onChange={handleChange}
          placeholder="Enter address of residency"
          style={{
            border: addressError
              ? `1px solid ${theme.palette.secondary.main}`
              : null,
          }}
        ></textarea>
        {addressError && (
          <small style={{ color: theme.palette.secondary.main }}>
            {addressError}
          </small>
        )}
      </div>

      <div className="proceed">
        <Button
          type="submit"
          title="Save and proceed"
          background={theme.palette.secondary}
          onClick={handleSubmit}
        />
      </div>
    </NextOfKinStyles>
  );
}

export default BioNextOfKin;

const NextOfKinStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0px 20px;

  .title {
    color: black;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 15px;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }

  .row {
      @media (max-width: 640px) {
        flex-direction: column;
        width: 100%;
      }
  }

  textarea {
    resize: none;
    width: 100%;
    height: 125px;
    min-height: 100px;
    max-height: 125px;
    border: 1px solid var(--gray-1);
    border-radius: 8px;
    padding: 15px;
    font-size: 15px;
    color: #171717;
    background-color: white;
    margin-bottom: 0.25rem;

    &:focus {
      outline: none;
    }
  }

  .proceed {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 3rem;
  margin-top: 2rem;

  @media (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
`;
