import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      background: (props) => props.background.main,
      borderRadius: 10,
      textTransform: "none",
      padding: 10,
      fontSize: 14,
      fontWeight: 300,

      "&:hover": {
        background: (props) => props.background.light,
      },

      "&:active": {
        background: (props) => props.background.dark,
        boxShadow: "none",
      },
    },
  },
}));

const CustomButton = ({ title, background, ...rest }) => {
  const classes = useStyles({ background });
  return (
    <Button variant="contained" {...rest} className={classes.button}>
      {title}
    </Button>
  );
};

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.object,
};

export default CustomButton;
