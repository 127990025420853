export const commissionsRows = [
  {
    id: 0,
    date: "09/09/2021",
    name: "John Doe",
    clientId: "C8340",
    propertyName: "Grandview Mansion",
    commission: "₦75,000",
    paid: true,
  },
  {
    id: 1,
    date: "13/09/2021",
    name: "Bessie Cooper",
    clientId: "D3924",
    propertyName: "Mayfair Building",
    commission: "₦23,650",
    paid: false,
  },
  {
    id: 2,
    date: "04/09/2021",
    name: "Brooklyn Simmons",
    clientId: "C2831",
    propertyName: "Vidco Home Villa",
    commission: "₦123,050",
    paid: true,
  },
  {
    id: 3,
    date: "01/09/2021",
    name: "Darlene Robertson",
    clientId: "C6237",
    propertyName: "Mangifera Series",
    commission: "₦54,200",
    paid: true,
  },
  {
    id: 4,
    date: "30/08/2021",
    name: "Jacob Jones",
    clientId: "C8340",
    propertyName: "Beach Home Villa",
    commission: "₦435,000",
    paid: false,
  },
  {
    id: 5,
    date: "09/09/2021",
    name: "Darlene Robertson",
    clientId: "C5126",
    propertyName: "Grandview Mansion",
    commission: "₦75,000",
    paid: true,
  },
];

// <<<----- REFERRALS ------->>>
export const referralsRows = [
  {
    id: 0,
    date: "13/09/2021",
    name: "Alexander Cooper",
    phone: "+2348011223344",
    email: "willie.jennings@example.com",
    level: "Associate",
    memberId: "C8340",
    downlines: 34,
  },
  {
    id: 1,
    date: "09/09/2021",
    name: "Jerome Bell",
    phone: "+2348011223344",
    email: "bill.sanders@example.com",
    level: "Pre-associate",
    memberId: "D3924",
    downlines: 84,
  },
  {
    id: 2,
    date: "04/09/2021",
    name: "Robert Fox",
    phone: "+2348011223344",
    email: "nathan.roberts@example.com",
    level: "Premium member",
    memberId: "C2831",
    downlines: 86,
  },
  {
    id: 3,
    date: "01/09/2021",
    name: "Wade Warren",
    phone: "+2348011223344",
    email: "debra.holt@example.com",
    level: "Sapphire Director",
    memberId: "C6237",
    downlines: 19,
  },
  {
    id: 4,
    date: "30/08/2021",
    name: "Floyd Miles",
    phone: "+2348011223344",
    email: "deanna.curtis@example.com",
    level: "Elite",
    memberId: "C5126",
    downlines: 40,
  },
];

// <<<----- DOWNLINES - FIRST GEN ------->>>
// export const downlinesRows = [
//   {
//     id: 0,
//     date: "13/09/2021",
//     name: "Alexander Cooper",
//     phone: "+2348011223344",
//     email: "willie.jennings@example.com",
//     level: "Associate",
//     memberId: "C8340",
//   },
//   {
//     id: 1,
//     date: "09/09/2021",
//     name: "Jerome Bell",
//     phone: "+2348011223344",
//     email: "bill.sanders@example.com",
//     level: "Pre-associate",
//     memberId: "D3924",
//   },
//   {
//     id: 2,
//     date: "04/09/2021",
//     name: "Robert Fox",
//     phone: "+2348011223344",
//     email: "nathan.roberts@example.com",
//     level: "Premium member",
//     memberId: "C2831",
//   },
//   {
//     id: 3,
//     date: "01/09/2021",
//     name: "Wade Warren",
//     phone: "+2348011223344",
//     email: "debra.holt@example.com",
//     level: "Sapphire Director",
//     memberId: "C6237",
//   },
//   {
//     id: 4,
//     date: "30/08/2021",
//     name: "Floyd Miles",
//     phone: "+2348011223344",
//     email: "deanna.curtis@example.com",
//     level: "Elite",
//     memberId: "C5126",
//   },
//   {
//     id: 5,
//     date: "30/08/2021",
//     name: "Eleanor Pena",
//     phone: "+2348011223344",
//     email: "sara.cruz@example.com",
//     level: "Executive",
//     memberId: "C3290",
//   },
//   {
//     id: 6,
//     date: "30/08/2021",
//     name: "Brooklyn Simmons",
//     phone: "+2348011223344",
//     email: "debbie.baker@example.com",
//     level: "Director",
//     memberId: "C1297",
//   },
//   {
//     id: 7,
//     date: "30/08/2021",
//     name: "Cameron Williamson",
//     phone: "+2348011223344",
//     email: "willie.jennings@example.com",
//     level: "Sapphire Director",
//     memberId: "C6237",
//   },
// ];

// export const downlinesRows = [];
