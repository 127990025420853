import React from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BioNav from "components/bio/bio_nav";
import BioMain from "components/bio/bio_main";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "auto",
    minHeight: "160vh",
    display: "flex",
    flexDirection: "column",
    background: "#f5f5f5",
  },
  top: {
    flex: 0.15,
    background: theme.palette.primary.main,
    paddingLeft: "3rem",
    paddingRight: "3rem",
    paddingTop: "3rem",
    position: "relative",
  },
  backBtn: {
    border: `1px solid ${theme.palette.common.grey}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 8,
    cursor: "pointer",
    color: "#fff",
  },
  bottom: {
    flex: 0.83,
    marginLeft: "3.5rem",
    marginRight: "3.5rem",
    marginTop: -120,
  },

  metaForm: {
    position: "absolute",
    minHeight: 400,
    width: "90vw",
    background: "#fff",
    borderRadius: 16,
    marginTop: 36,
    display: "flex",
  },
}));

const Bio = () => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);

  return (
    <div className={`${classes.container} `}>
      <div className={classes.top}>
        <Link to="/" className={classes.backBtn}>
          Back
        </Link>
        <div
          style={{
            marginTop: "5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: 30, color: "#fff" }}>Profile</h3>
          <p style={{ color: "#fff" }}>ID: {user?.id}</p>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={`${classes.metaForm} proDiv`}>
          <BioNav />

          <BioMain />
        </div>
      </div>
    </div>
  );
};

export default Bio;
