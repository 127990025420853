import React, { useState } from "react";

import styled from "styled-components";
import PropTypes from "prop-types";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import CalendarIcon from "../../assets/icons/calendar-1.svg";

function MLMTextField({
  name,
  labelText,
  labelColor,
  backgroundColor,
  hintText,
  hintTextColor,
  fieldValue,
  inputTextColor,
  width,
  midWith,
  inputType,
  onChange,
  borderColor,
  isDisabled,
  divBorder,
  ...rest
}) {
  const [showText, setShowText] = useState(false);

  const toggleVisibility = () => {
    setShowText(!showText);
  };

  return (
    <InputStyles
      labelColor={labelColor}
      midWith={midWith}
      inputTextColor={inputTextColor}
      width={width}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      hintTextColor={hintTextColor}
    >
      <h6>{labelText}</h6>
      {inputType !== "password" ? (
        <div className="input-container">
          <input
            style={{ border: divBorder }}
            name={name}
            type={inputType}
            placeholder={hintText}
            value={fieldValue}
            onChange={onChange}
            disabled={isDisabled}
            {...rest}
          />
        </div>
      ) : (
        <div className="input-container-pwd">
          <input
            style={{ border: divBorder }}
            name={name}
            type={showText ? "text" : "password"}
            placeholder={hintText}
            value={fieldValue}
            onChange={onChange}
            disabled={isDisabled}
          />
          <div className="toggle" onClick={toggleVisibility} {...rest}>
            {showText ? (
              <AiOutlineEyeInvisible size={30} />
            ) : (
              <AiOutlineEye size={30} />
            )}
          </div>
        </div>
      )}
    </InputStyles>
  );
}

MLMTextField.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  hintText: PropTypes.string,
  fieldValue: PropTypes.string,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
};

MLMTextField.defaultPropTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  hintText: PropTypes.string,
  fieldValue: PropTypes.string,
  inputType: "text",
  onChange: PropTypes.func,
};

export default MLMTextField;

const InputStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.midWith ? "100%" : "100%")};

  h6 {
    color: ${(props) => (props.labelColor ? props.labelColor : "var(--white)")};
    font-size: 15px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  input[type="date"] {
    cursor: pointer;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 15px;
    margin-left: 1px;
    margin-top: -5px;

    &:hover {
      cursor: pointer;
    }
  }

  .input-container {
    border: none;
    opacity: ${(props) => (props.borderColor ? 0.1 : 1)};
    border-radius: 8px;
    width: "100%";
    box-sizing: border-box;
    height: 42px;
    margin-top: 1rem;
    padding-top: 8px;
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      color: ${(props) => (props.midWith ? "#fff" : "#7d7d7e")};
      border: 1px solid ${(props) => (props.midWith ? "#616060" : "#A2A2A2")};
      padding: 12px 15px;
      margin-top: -9px;
      border-radius: 9px;
      outline: none;
      background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "transparent"};
      font-size: 14px;
      opacity: ${(props) => (props.midWith ? "0.24" : "1")};
      width:   ${(props) => (props.borderColor ? '100%' : '100%')};



      ::placeholder {
        color: A2A2A2;
        font-size: 15px;
      }
    }

    input {
      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }

  .input-container-pwd {
    border: none;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    height: 42px;
    margin-top: 1rem;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    color: ${(props) => (props.midWith ? "#fff" : "#7d7d7e")};
    position: relative;
    opacity: ${(props) => (props.midWith ? "3.24" : "1")};
    width:   ${(props) => (props.borderColor ? '100%' : '100%')};



    svg {
      height: 25px;
      padding-right: 10px;
      padding-top: 3px;
      fill: #a2a2a2;
    }

    input {
      width: 100%;
      color: ${(props) => (props.midWith ? "#fff" : "#7d7d7e")};
      border: 1px solid ${(props) => (props.midWith ? "#616060" : "#A2A2A2")};
      padding: 12px 15px;
      margin-top: -9px;
      border-radius: 9px;
      outline: none;
      background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "transparent"};
      font-size: 14px;
      opacity: ${(props) => (props.midWith ? "0.24" : "1")};
      width:   ${(props) => (props.borderColor ? '100%' : '100%')};


      ::placeholder {
        color: #a2a2a2;
        font-size: 15px;
      }
    }

    .toggle {
      position: absolute;
      right: 5px;
    }

    input {
      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }
`;
