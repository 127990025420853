import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PageHeader from "../components/common/page_header";
import { MiniSearchBar } from "../components/common/mini_search_bar";

import ExportIcon from "../assets/icons/export_icon.svg";
import CommissionsTable from "components/commission/TableData";
import EmptyTable from "components/table-layouts/EmptyTable";
import DashboardHeader from "components/dashboard/header";
import { Link } from "react-router-dom";
import SalesBonusTable from "components/sales-bonus/TableData";
import { useSelector } from "react-redux";
const { default: axios } = require("axios");
const { BASE_URL } = require("../services/BASE_URL");

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    padding: "20px 20px",
    paddingRight: "0.5px",
    width: "99%",
  },
}));

function SalesBonus() {
  const [searchQuery, setSearchQuery] = useState("");

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [com, setSales] = useState(false);
  const [pending, setPending] = useState(false);
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const { user } = useSelector((state) => state.user);
  // console.log("user", user);

  useEffect(() => {
    setPending(true);
    GetSales(searchQuery)
      .then((data) => {
        console.log("data", data);
        if (data.message === "successful") {
          setSales(data.data.salesbonuses);
          setPending(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
      });
  }, [searchQuery]);

  const GetSales = async (searchTerm, filter) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/salesbonuses/mysalesbonuses${
          searchTerm || filter ? "?" : ""
        }${searchTerm ? "search_term=" + searchTerm + "&" : ""}${
          filter ? "filter=" : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error.message);
    }
  };
  const requestPayout = async (d) => {
    const datat = {
      salesBonusId: d.id,
      referrerCode: user.referrerCode,
      property: d.property,
      memberFullname: `${user.firstName} ${user.lastname}`,
      uplineLevel: d.uplineLevel,
      ammount: `${d.bonus}`,
      payoutType: "salesBonus",
      status: user.statu,
      bank: user.bankName,
      bankAccountName: user.accountName,
      accountNumber: user.accountNumber,
    };
    try {
      const { data } = await axios.post(`${BASE_URL}/api/payouts/new`, datat, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return data;
    } catch (error) {
      return error.message;
    }
  };
  // console.log(com, "com");
  return (
    <DashboardStyles>
      <DashboardHeader />
      <SecondGenDownlinesStyles className="marginTop">
        <PageHeader
          title="Sales Bonus"
          subTitle="This page displays the Sales Bonus from property bought"
          Comp={() => (
            <JumbTabs>
              <div className="tabs">
                {document.location.pathname === "/sales-bonus" ? (
                  <>
                    {/* <Link to="/commissions">Commissions</Link> */}
                    <Link to="/sales-bonus" className="active">
                      Sales Bonus
                    </Link>
                    <Link to="/membership-bonus">Membership Bonuses</Link>
                  </>
                ) : (
                  <>
                    {/* <Link to="/commissions">Commissions</Link> */}
                    <Link to="/sales-bonus">Sales Bonus</Link>
                    <Link to="/membership-bonus">Membership Bonuses</Link>
                  </>
                )}
              </div>
            </JumbTabs>
          )}
        />

        <div className="search-filter-export">
          <MiniSearchBar
            name={searchQuery}
            onChange={handleChange}
            value={searchQuery}
            placeHolder="Search client, name or property..."
          />
          {/* <div className="export">
            <img src={ExportIcon} alt="export-icon" />
            <h3>Export</h3>
          </div> */}
        </div>
        {/* 
        <Grid container className={classes.tableWrapper}>
          {pending ? (
            <Box
              sx={{
                display: "flex",
                height: "400px",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="box1"
            >
              <CircularProgress />
            </Box>
          ) : com.length > 0 ? (
            <CommissionsTable com={com} tab={true} />
          ) : (
            <EmptyTable com={true} />
          )}
        </Grid> */}
        <Grid
          container
          style={{ padding: "20px 20px", paddingRight: "2px", width: "99%" }}
        >
          {pending ? (
            <Box
              sx={{
                display: "flex",
                height: "400px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : com?.length > 0 ? (
            <SalesBonusTable com={com} tab={true} requestPayment={requestPayout} />
          ) : (
            <EmptyTable />
          )}
        </Grid>
      </SecondGenDownlinesStyles>
    </DashboardStyles>
  );
}

export default SalesBonus;

const DashboardStyles = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-left: -270px;
    width: 100vw;
  }
`;
const JumbTabs = styled.div`
margin-top: 25px;

.tabs {
  margin-left: 20px;
  display: flex;
  
  a {
    padding: 10px 15px;
    text-decoration: none;
    color: #000;
    font-size: 13px;
    color: #7D7D7E;
  }
  .active {
      border-bottom: 2px solid #FF385C;
      color: #000;
      background: transparent;
  }
`;
const SecondGenDownlinesStyles = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: var(--gray-2);
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: -10px;
    margin-top: 2px !important;  }  

  .search-filter-export {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 10vh;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px dotted var(--gray-1);
  }  





  .filter-export {
    display: flex;
    margin-right: 28px;
    

    .export,
    .filter {
      margin-right: 10px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background-color: var(--white);
      gap: 1rem;
      padding: 9px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }


  .filter-export {
    @media(max-width: 912px) {
      margin-left: 40px; 
    }
    @media(min-width: 770px) {
      margin-left: 40px; 
    }
  }

  .filter {
    @media(max-width: 640px) {
      margin-top: 2px !important;
    }
  }

  .export {
    @media(max-width: 640px) {
      margin-top: 2px !important;
    }
  }
}
`;
