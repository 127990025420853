import React from "react";

import styled from "styled-components";
import PropTypes from "prop-types";

function BioSectionTitle({ title, subTitle }) {
  return (
    <BioSectionTitleStyles>
      <h3>{title}</h3>
      <h5>{subTitle}</h5>
    </BioSectionTitleStyles>
  );
}

export default BioSectionTitle;

BioSectionTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

const BioSectionTitleStyles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;

  h3 {
    color: var(--black);
    font-size: 22px;
    font-weight: 500;
  }

  h5 {
    font-weight: 400;
    font-size: 13px;
    margin-top: 12px;
    color: var(--gray-3);
  }
`;
