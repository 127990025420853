import React from "react";
import styled from "styled-components";
import { displayInitials } from "helpers/displayInitials";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
function LetterDrawable({ toggleProfileMenu }) {
  const { user } = useSelector((state) => state.user);
  
  return (
    <LetterDrawableStyles
      className="letter-drawable"
      onClick={toggleProfileMenu != null ? toggleProfileMenu : null}
    >
      {user?.avatar ? (
        <img 
          src={user?.avatar}
          alt="Profile"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            zIndex: "9999999",
          }}
        />
      ) : (
        <h5>
          {user && user.firstName
            ? displayInitials(user.firstName)
            :<PersonIcon  sx={{ color: 'white' }} />
            }
        </h5>
      )}
    </LetterDrawableStyles>
  );
}

export default LetterDrawable;

const LetterDrawableStyles = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-bg);
  margin-left: 0;

  h5 {
    color: var(--white);
  }

  h5 {
    @media (max-width: 640px) {
      margin-left: -1.5px !important;
    }
  }
`;
