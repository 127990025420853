import React, { useState, useEffect } from "react";

import { makeStyles, useTheme } from "@mui/styles";

import styled from "styled-components";

import AuthImg from "../assets/images/auth-bg.png";
import Logo from "../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "85%",
      background: theme.palette.secondary.main,
      textTransform: "none",
      marginTop: "20px",
      borderRadius: 8,

      "&:hover": {
        background: theme.palette.secondary.light,
      },
      "&:active": {
        background: theme.palette.secondary.dark,
      },

      "&:disabled": {
        background: "#ffa8b6",
        cursor: "not-allowed !important",
      },
    },
  },
  response: {
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  error: {
    color: theme.palette.secondary.main,
  },

  success: {
    color: theme.palette.success.main,
  },
}));

function Terms() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div
        style={{ background: "black", textAlign: "left", padding: " 2% 4%" }}
      >
        {" "}
        <img src={Logo} alt="Logo" className="termsI" />
      </div>

      <div
        id="sig"
        style={{
          minWidth: "100%",
          maxWidth: "800px",
          lineHeight: "18px",
          margin: "auto",
          padding: "50px 4%",
          marginTop: "0px",
          fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
          fontSize: "12px",
          background: "white",
          color: "#333333",
        }}
      >
        <span
          style={{
            fontSize: "2.6rem",
            color: "#FF385C",
            textDecoration: "none",
            lineHeight: "30px",
          }}
        >
          <strong>TERMS AND CONDITIONS</strong>
        </span>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>INTRODUCTION</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          These terms and conditions govern your use of this website; by using
          this website, you accept these terms and conditions in full. If you
          disagree with these terms and conditions or any part of these terms
          and conditions, you must not use this website. You must be at least
          [18] years of age to use this website. By using this website and by
          agreeing to these terms and conditions you warrant and represent that
          you are at least [18] years of age. This website uses cookies. By
          using this website and agreeing to these terms and conditions, you
          consent to our Whiterose Network use of cookies.{" "}
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>MODIFICATION OF AGREEMENT</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Whiterose Network reserves the right, at its sole discretion, to
          modify or replace any of the terms of this Agreement, or change,
          suspend, or discontinue the Service (including without limitation, the
          availability of any feature, database, or content) at any time. Such
          modifications shall become part of this Agreement and are
          automatically effective once posted on the Site or by sending you an
          email. This Agreement may not be orally amended. Whiterose Network may
          also impose limits on certain features and services or restrict your
          access to parts or all of the Service without notice or liability. It
          is your responsibility to check the terms of this Agreement
          periodically for changes. Your continued use of the Service following
          the posting of any changes to the Terms of Use constitutes acceptance
          of those changes.{" "}
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>LICENSE TO USE WEBSITE</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Unless otherwise stated, Whiterose Network and/or its licensors own
          the intellectual property rights in the website and material on the
          website. Subject to the license below, all these intellectual property
          rights are reserved. You may view, download for caching purposes only,
          and print pages from the website for your own personal use, subject to
          the restrictions set out below and elsewhere in these terms and
          conditions.
          <br /> <strong>You must not:</strong>
          <br />
          1. republish material from this website (including republication on
          another website);
          <br />
          2. sell, rent or sub-license material from the website;
          <br />
          3. show any material from the website in public;
          <br />
          4. reproduce, duplicate, copy or otherwise exploit material on this
          website for a commercial purpose;
          <br />
          5. edit or otherwise modify any material on the website; or
          <br />
          6. redistribute material from this website except for content
          specifically and expressly made available for redistribution.
          <br />
          7. Where content is specifically made available for redistribution, it
          may only be redistributed within your organization.
          <br />
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>PROPRIETARY RIGHTS</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          The Whiterose Network logo is a trademarks and service mark of
          Whiterose Investment Group. Whiterose Network or its licensors own and
          retain all proprietary rights in the website, the service and all
          material and information posted thereon (“Content”). The website
          contains the copyrighted material, trademarks and other proprietary
          information of Whiterose Network and its licensors. You may not alter,
          modify, publish, distribute, publicly display or sell any Content or
          other such proprietary information, or otherwise copy or transmit any
          Content or other such proprietary information without the express
          written permission of Whiterose Network.
          <br />
          In addition, to the extent you receive information from Whiterose
          Network, Issuers or other Investors with respect to any Investment
          activity on the website, you may not further disclose or otherwise
          provide such information to another party in any way that allows a
          personal identification of such Issuer or Investor. You agree that
          Whiterose Network may, at its sole discretion and to the extent
          permitted by law, access, read, preserve and disclose your account
          information, usage history and submitted Content in order to: (a)
          comply with any applicable law, regulation, legal process, or
          governmental request; (b) respond to claims that any Content violates
          the rights of third parties, including intellectual property rights;
          (c) enforce this Agreement and investigate potential violations
          thereof; (d) detect, prevent, or otherwise address fraud, security, or
          technical issues; (e) respond to your requests for customer service;
          or (f) protect the rights, property, or personal safety of Whiterose
          Network, its users, or the public.
          <br />
          By using and accessing Whiterose Network, you agree to keep all
          information you learn about companies, funds, and investment
          opportunities on the site private and confidential.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>ACCEPTABLE USE</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          You must not use this website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of the website; or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity.
          <br />
          You must not use this website to copy, store, host, transmit, send,
          use, publish or distribute any material which consists of (or is
          linked to) any spyware, computer virus, Trojan horse, worm, keystroke
          logger, rootkit or other malicious computer software.
          <br />
          You must not conduct any systematic or automated data collection
          activities (including without limitation scraping, data mining, data
          extraction and data harvesting) on or in relation to this website
          without Whiterose Network express written consent.
          <br />
          You must not use this website to transmit or send unsolicited
          commercial communications.
          <br />
          You must not use this website for any purposes related to marketing
          without Whiterose Network express written consent.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>RESTRICTED ACCESS</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Access to certain areas of this website is restricted. Whiterose
          Network reserves the right to restrict access to other areas of this
          website, or indeed this entire website, at Whiterose Network
          discretion.
          <br />
          If Whiterose Network provides you with a user ID and password to
          enable you to access restricted areas of this website or other content
          or services, you must ensure that the user ID and password are kept
          confidential.
          <br />
          Whiterose Network may disable your user ID and password in Whiterose
          Network sole discretion without notice or explanation.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>USER CONTENT</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          In these terms and conditions, “your user content” means material
          (including without limitation text, images, audio material, video
          material and audio-visual material) that you submit to this website,
          for whatever purpose.
          <br />
          You grant to Whiterose Network a worldwide, irrevocable,
          non-exclusive, royalty-free license to use, reproduce, adapt, publish,
          translate and distribute your user content in any existing or future
          media. You also grant to Whiterose Network the right to sub-license
          these rights, and the right to bring an action for infringement of
          these rights.
          <br />
          Your user content must not be illegal or unlawful, must not infringe
          any third party’s legal rights, and must not be capable of giving rise
          to legal action whether against you or Whiterose Network or a third
          party (in each case under any applicable law).
          <br />
          You must not submit any user content to the website that is or has
          ever been the subject of any threatened or actual legal proceedings or
          other similar complaint.
          <br />
          Whiterose Network reserves the right to edit or remove any material
          submitted to this website, or stored on Whiterose Network servers, or
          hosted or published upon this website.
          <br />
          Notwithstanding Whiterose Network rights under these terms and
          conditions in relation to user content, Whiterose Network does not
          undertake to monitor the submission of such content to, or the
          publication of such content on, this website.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>NO WARRANTIES</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          This website is provided “as is” without any representations or
          warranties, express or implied. Whiterose Network makes no
          representations or warranties in relation to this website or the
          information and materials provided on this website.
          <br />
          Without prejudice to the generality of the foregoing paragraph,
          Whiterose Network does not warrant that:
          <br />
          1. this website will be constantly available, or available at all; or
          <br />
          2. the information on this website is complete, true, accurate or
          non-misleading.
          <br />
          Nothing on this website constitutes, or is meant to constitute, advice
          of any kind. If you require advice in relation to any legal, financial
          or medical matter you should consult an appropriate professional.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>LIMITATIONS OF LIABILITY</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Whiterose Network will not be liable to you (whether under the law of
          contact, the law of torts or otherwise) in relation to the contents
          of, or use of, or otherwise in connection with, this website:
          <br />
          1. to the extent that the website is provided free-of-charge, for any
          direct loss; <br />
          2. for any indirect, special or consequential loss; or <br />
          3. for any business losses, loss of revenue, income, profits or
          anticipated savings, loss of contracts or business relationships, loss
          of reputation or goodwill, or loss or corruption of information or
          data.
          <br />
          These limitations of liability apply even if Whiterose Network has
          been expressly advised of the potential loss.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>EXCEPTIONS</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Nothing in this website disclaimer will exclude or limit any warranty
          implied by law that it would be unlawful to exclude or limit; and
          nothing in this website disclaimer will exclude or limit Whiterose
          Network liability in respect of any:
          <br />
          1. death or personal injury caused by Whiterose Network negligence;
          <br />
          2. fraud or fraudulent misrepresentation on the part of Whiterose
          Network ; or
          <br />
          3. matter which it would be illegal or unlawful for Whiterose Network
          to exclude or limit, or to attempt or purport to exclude or limit, its
          liability.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>REASONABLENESS</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          By using this website, you agree that the exclusions and limitations
          of liability set out in this website disclaimer are reasonable. <br />
          If you do not think they are reasonable, you must not use this
          website.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>OTHER PARTIES</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          You accept that, as a limited liability entity, Whiterose Network has
          an interest in limiting the personal liability of its officers and
          employees. You agree that you will not bring any claim personally
          against Whiterose Network agents or employees in respect of any losses
          you suffer in connection with the website. <br />
          Without prejudice to the foregoing paragraph,] you agree that the
          limitations of warranties and liability set out in this website
          disclaimer will protect Whiterose Network officers, employees, agents,
          subsidiaries, successors, assigns and sub-contractors as well as
          Whiterose Network.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>COPYRIGHT POLICY</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          If you believe that any material on the Site violates this Agreement
          or your intellectual property rights, please notify Whiterose Network
          as soon as possible by sending an email to info@whiterosenetwork.com,
          with the following information in accordance with Section 512(c) of
          the Copyright Act: (i) a physical or electronic signature of the
          copyright owner or a person authorized to act on their behalf; (ii)
          identification of the copyrighted work claimed to have been infringed;
          (iii) identification of the material that is claimed to be infringing
          or to be the subject of infringing activity and that is to be removed
          or access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material; (iv) your contact
          information, including your address, telephone number, and an email
          address; (v) a statement by you that you have a good faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law; and (vi) a statement that
          the information in the notification is accurate, and, under penalty of
          perjury, that you are authorized to act on behalf of the copyright
          owner.
          <br />
          We reserve the right to remove Content alleged to be infringing or
          otherwise illegal without prior notice and at our sole discretion. In
          appropriate circumstances, Whiterose Network will also terminate a
          User’s account if the user is determined to be a repeat infringer.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>UNENFORCEABLE PROVISIONS</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          If any provision of this website disclaimer is, or is found to be,
          unenforceable under applicable law, that will not affect the
          enforceability of the other provisions of this website disclaimer.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>TERMINATION</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Whiterose Network may terminate your access to all or any part of the
          website and service at any time, with or without cause, with or
          without notice, effective immediately, which may result in the
          forfeiture and destruction of all information associated with your
          membership. If you wish to terminate your account, you may do so by
          following the instructions on the Site. All provisions of this
          Agreement shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>INDEMNITY</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          You shall defend, indemnify, and hold harmless Whiterose Network, its
          affiliates and each of its, and its affiliates employees, contractors,
          directors, suppliers and representatives from all liabilities, claims,
          and expenses, including reasonable attorneys’ fees, that arise from or
          relate to your use or misuse of, or access to, the Site, Service,
          Content or otherwise from your User Submissions, violation of the
          Agreement, or infringement by you, or any third party using the your
          account, of any intellectual property or other right of any person or
          entity. Whiterose Network reserves the right to assume the exclusive
          defense and control of any matter otherwise subject to indemnification
          by you, in which event you will assist and cooperate with Whiterose
          Network in asserting any available defenses.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>BREACHES OF THESE TERMS AND CONDITIONS</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Without prejudice to Whiterose Network other rights under these terms
          and conditions, if you breach these terms and conditions in any way,
          Whiterose Network may take such action as Whiterose Network deems
          appropriate to deal with the breach, including suspending your access
          to the website, prohibiting you from accessing the website, blocking
          computers using your IP address from accessing the website, contacting
          your internet service provider to request that they block your access
          to the website and/or bringing court proceedings against you.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>VARIATION</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Whiterose Network may revise these terms and conditions from
          time-to-time. Revised terms and conditions will apply to the use of
          this website from the date of the publication of the revised terms and
          conditions on this website. Please check this page regularly to ensure
          you are familiar with the current version.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>ASSIGNMENT</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Whiterose Network may transfer, sub-contract or otherwise deal with
          Whiterose Network rights and/or obligations under these terms and
          conditions without notifying you or obtaining your consent. <br />
          You may not transfer, sub-contract or otherwise deal with your rights
          and/or obligations under these terms and conditions.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>REGISTRATION DATA; ACCOUNT SECURITY; FUNDS TRANSFER</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          In consideration of your use of the Site, you agree to (a) provide
          accurate, current and complete information about you as may be
          prompted by any registration forms on the Site (“Registration Data”);
          (b) maintain the security of your password and identification; (c)
          maintain and promptly update the registration Data, and any other
          information that you provide to Whiterose Network, to keep it
          accurate, current and complete; and (d) accept all risks of
          unauthorized access to the Registration Data and any other information
          you provide to Whiterose Network. By entering into this agreement, you
          agree to the terms of service of Paystack (our financial partner)
          found here which are incorporated herein by reference.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>SEVERABILITY</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          This Agreement is the entire agreement between the user and Whiterose
          Network with respect to the Service and use of the website, and
          supersede all prior or contemporaneous communications and proposals
          (whether oral, written or electronic) between the user and Whiterose
          Network with respect to the website. If any provision of the Terms of
          Use is found to be unenforceable or invalid, that provision will be
          limited or eliminated to the minimum extent necessary so that the
          Agreement will otherwise remain in full force and effect and
          enforceable. The failure of either party to exercise in any respect
          any right provided for herein shall not be deemed a waiver of any
          further rights hereunder.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>ENTIRE AGREEMENT</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          These terms and conditions, together with the privacy policy
          constitute the entire agreement between you and Whiterose Network in
          relation to your use of this website, and supersede all previous
          agreements in respect of your use of this website.
        </p>
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>GOVERNING LAW AND ARBITRATION</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        &nbsp;
        <br />
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          These terms and conditions will be governed by and construed in
          accordance with GOVERNING LAW of the Federal Republic of Nigeria, and
          any disputes relating to these terms and conditions will be subject to
          the [non-]exclusive jurisdiction of the courts of Lagos, Nigeria.
        </p>
        <br />
        <br />
        <br />
        <span
          style={{
            fontSize: "1.3rem",
            color: "#111",
            textDecoration: "none",
          }}
        >
          <strong>WHITEROSE NETWORK DETAILS</strong>
        </span>
        <br />
        <span style={{ fontSize: "12px", color: "#FF385C" }}>
          <strong>_</strong>
        </span>
        <p
          style={{
            fontFamily: '"Lucida ", Verdana, Arial, Sans-Serif',
            fontSize: "14px",
            color: "#333333",
            textDecoration: "none",
          }}
        >
          Whiterose Network is registered in Lagos, Nigeria. <br /> <br />
          Whiterose Network contact address is: 6, Adebimpe Adewunmi Street,
          Lekki Phase I, Lagos, Nigeria.
        </p>
        <br />
      </div>
    </>
  );
}

export default Terms;
