import React, { useState } from "react";
import TableData from "components/referrals/TableData";
import Grid from "@mui/material/Grid";

import styled from "styled-components";

import ReferralHeader from "../components/referrals/header";
import { MiniSearchBar } from "../components/common/mini_search_bar";

import FilterIcon from "../assets/icons/filter_icon.svg";
import ExportIcon from "../assets/icons/export_icon.svg";
import EmptyTable from "components/table-layouts/EmptyTable";

const referralsRows = [];

function Referrals() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <ReferralsStyles>
      <ReferralHeader
        title="Referrals"
        subTitle="This displays the refered persons"
      />
      <div className="search-filter-export">
        <MiniSearchBar
          name={searchQuery}
          onChange={handleChange}
          value={searchQuery}
          placeHolder="Search client, name or property..."
        />
        <div className="filter-export">
          <div className="filter">
            <img src={FilterIcon} alt="icon" />
            <h3>Filters</h3>
          </div>
          <div className="export">
            <img src={ExportIcon} alt="icon" />
            <h3>Export</h3>
          </div>
        </div>
      </div>

      <Grid container style={{ padding: "20px 20px", paddingRight: "2px" }}>
        {referralsRows.length === 0 ? <EmptyTable /> : <TableData />}
      </Grid>
    </ReferralsStyles>
  );
}

export default Referrals;

const ReferralsStyles = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 120vh;
  height: 100%;
  width: 100%;
  background-color: var(--gray-2);

  .search-filter-export {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px dotted var(--gray-1);
  }

  .filter-export {
    display: flex;

    .export,
    .filter {
      margin-right: 38px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background-color: var(--white);
      gap: 1rem;
      padding: 9px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }
`;
