import React, { useState } from "react";
import Button from "components/common/Button";
import { useTheme } from "@mui/styles";
import styled from "styled-components";
import WithdrawFundsDialog from "./withdraw_funds_dialog";
import { useSelector } from "react-redux";

import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const customStyles = {
  overlay: {
    position: "fixed",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(180, 180, 180, 0.55)",
  },
  content: {
    margin: "auto",
    height: "390px",
    width: "300px",
    backgroundColor: "var(--white)",
    borderRadius: "8px",
  },
};

Modal.setAppElement(document.getElementById("root"));

function DashboardSubHeader() {
  const [showDialog, setShowDialog] = useState(false);
  const theme = useTheme();
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  // For snackbar
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const toggleWithdrawalDialog = () => {
    // setShowDialog(!showDialog);
    navigate("/commissions", { replace: true });
  };

  const copyReferralLink = () => {
    if (user) {
      let refLink = `${document.location.origin}/register?ref=${user?.myReferralCode}`;
      navigator.clipboard.writeText(refLink).then(function () {
        handleClick();
      });
    }
    return;
  };
  // const action = (
  //   <>
  //     <IconButton
  //       size="small"
  //       aria-label="close"
  //       color="inherit"
  //       onClick={handleClose}
  //     >
  //       <CloseIcon fontSize="small" />
  //     </IconButton>
  //   </>
  // );

  return (
    <DashboardSubHeaderStyles>
      <div className="welcome-level">
        <h3>{`Hello ${user && user.firstName ? user.firstName : "User"}`}</h3>
        <h5 className="subTextD">
          Here's what we have for you{" "}
          <span style={{ textTransform: "capitalize" }}>
            {user && user.level
              ? user.level.replace(/_/g, " ") + " "
              : "Member "}
          </span>
        </h5>
      </div>
      <div className="actions">
        <Button
          title="Bonus withdrawal"
          style={{
            boxShadow: "none",
            height: 45,
            fontWeight: 400,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 14,
            borderRadius: 20,
            marginTop: 20,
            width: "200px",
          }}
          background={theme.palette.primary}
          onClick={toggleWithdrawalDialog}
        />
        <Modal
          isOpen={showDialog}
          onRequestClose={toggleWithdrawalDialog}
          style={customStyles}
        >
          <WithdrawFundsDialog
            toggleWithdrawalDialog={toggleWithdrawalDialog}
          />
        </Modal>
        <Button
          title="Copy referral link"
          style={{
            boxShadow: "none",
            height: 45,
            fontWeight: 400,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 14,
            borderRadius: 20,
            marginTop: 20,
            width: "200px",
          }}
          background={theme.palette.secondary}
          onClick={copyReferralLink}
        />
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" className="snack-bar">
            Refferal link copied!
          </Alert>
        </Snackbar>
      </div>
    </DashboardSubHeaderStyles>
  );
}

export default DashboardSubHeader;

const DashboardSubHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }

  .welcome-level {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    h3 {
      color: var(--main-black);
      font-size: 32px;
      font-weight: 600;
      font-style: normal;
    }

    h5 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .snack-bar {
    font-size: 1rem;
  }
`;
