import { useState } from "react";
import EnhancedTable from "components/table-layouts/EnhancedTable";
import { downlinesHeadCells } from "helpers/dataset/table-header-data";
// import { downlinesRows } from "helpers/dataset/table-rows";
import EmptyTable from "components/table-layouts/EmptyTable";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { dateMoment } from "../../utils/Time";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      fontSize: "14px",
    },
  },
}));

const DownlinesTable = ({ downlinesRows }) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let data = downlinesRows.data;
  // console.log("jjj", data);

  if (typeof data === "string") {
    return <EmptyTable />;
  } else {
    return (
      <EnhancedTable
        headCells={downlinesHeadCells}
        rows={data}
        page={page}
        paginationLabel="Downlines per page"
        hasCheckbox={false}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        {data[0].name.length > 0 &&
          data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow hover tabIndex={-1} key={index}>
                  <TableCell
                    id={labelId}
                    scope="row"
                    className={classes.tableCell}
                  >
                    <input type={"checkbox"} />
                  </TableCell>

                  <TableCell
                    id={labelId}
                    scope="row"
                    className={classes.tableCell}
                  >
                    {row ? dateMoment(row.date_of_reg) : "no value"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row ? row.name : "no value"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row ? row.phone_number : "no value"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row ? row.email : "no value"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {" "}
                    {row ? row.level : "no value"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row ? row.member_id : "no value"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                  {row.status === "inactive" ? (
                    <div className="SIA">{row.status}</div>
                  ) : (
                    <div className="SA">{row.status == 'active' ? 'active' :row.status}</div>
                  )}{" "}
                </TableCell>
                </TableRow>
              );
            })}
      </EnhancedTable>
    );
  }
};

export default DownlinesTable;
