import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { makeStyles } from "@mui/styles";
import prettyMoney from "pretty-money";

// import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  address: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "40ch",

    "&.MuiTypography-root": {
      fontSize: "1.05rem",
    },
  },
}));

const DashboardBuildingCard = ({ property, setOpen, setCurrentProperty }) => {
  const classes = useStyles();
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };

  let pName = property.name;
  let newPName = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

 
  return (
    // <Div>
    <Card
      onClick={() => {
        setOpen(true);
        setCurrentProperty(property);
      }}
      className="cardPro"
      style={{
        flex: "0.8",
        maxWidth: "370px",
        minWidth: "370px",
        margin: "20px",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          style={{ height: "250px" }}
          image={property.images[0]?.url}
          alt="Property"
        />
        <CardContent style={{ height: "155px" }}>
          <div style={{ height: "75px" }}>
            {" "}
            <div>
              <Typography
                gutterBottom
                variant="h4"
                style={{
                  marginBottom: "5px",
                  fontSize: "18px",
                  fontWeight: 400,
                }}
              >
                {newPName(pName, 40)}
              </Typography>
            </div>{" "}
            <div>
              <Typography
                variant="body2"
                color="text.secondary"
                className={classes.address}
              >
                {property.address}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              height: "20px",
            }}
          >
          {property.name == "Lekki Town Square Mall" ? (  <Typography
              gutterBottom
              variant="h4"
              style={{
                marginBottom: "-30px",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              ₦1,350,000 per sqm 

            </Typography>
          ):   <Typography
          gutterBottom
          variant="h4"
          style={{
            marginBottom: "-30px",
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {prettyMoney(prettyDollarConfig, property?.price)}
        </Typography>
      }
      </div>
        </CardContent>
      </CardActionArea>
    </Card>
    // </Div>
  );
};

DashboardBuildingCard.propTypes = {
  setOpen: PropTypes.func.isRequired,
  setCurrentProperty: PropTypes.func.isRequired,
};

export default DashboardBuildingCard;

// const Div = styled.div`

//   .cardPro {
//     width: 300px;
//     margin: 20px 0px;
//   }
//   .cardPro {
//     @media (max-width: 640px) {
//       width: 60%;
//     }
//   }

// `
