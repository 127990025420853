import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { menus } from "../../helpers/dataset/side_menu";
import { makeStyles, useTheme } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/logo.png";
import logoSymbol from "assets/images/logo-symbol.png";
import { ReactComponent as LogoutIcon } from "assets/icons/logout_icon.svg";
import { useActions } from "hooks/useActions";
import styled from "styled-components";
import {Menu} from '@mui/icons-material';
import {Cancel} from '@mui/icons-material';



const useStyles = makeStyles((theme) => ({



  aside: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    paddingTop: "1em",
    paddingBottom: "5em",
    height: "100vh",
    position: "fixed",
    overflowY: "scroll",
    overflowX: "hidden",
    zIndex: theme.zIndex.appBar + 1,
    background: theme.palette.primary.main,
    transition: "all .25s ease-in-out",

    "&:hover": {
      overflowY: "scroll",
    },

    "& .MuiListItemButton-root": {
      marginBottom: "1em",

      "&:hover": {
        backgroundColor: theme.palette.common.darkerGrey,
        borderRadius: 5,
      },
    },

    "& .MuiListItemIcon-root": {
      minWidth: 50,
    },

    "& .MuiSvgIcon-root": {
      fontSize: "2rem",

      "&:hover": {
        color: theme.palette.common.red,
      },
    },

    "& .MuiTypography-root": {
      fontSize: "1rem",
      color: "#fff",
    },

    "& .MuiListItemButton-root.Mui-selected": {
      backgroundColor: theme.palette.common.darkerGrey,
      borderRadius: ".5rem",

      "&:hover": {
        backgroundColor: theme.palette.common.darkerGrey,
      },

      "& .MuiListItemIcon-root": {
        color: theme.palette.common.red,
      },

      "& .MuiTypography-root": {
        color: "#fff",
      },
    },

    "&::-webkit-scrollbar": {
      width: ".85rem",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 1rem rgba(0, 0, 0, 0.2)",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: ".5rem",
      background: theme.palette.common.darkerGrey,
    },
  },
  dropDownMenu: {
    "&.MuiListItemButton-root": {
      "&:hover": {
        background: "transparent",
      },
    },
  },
  logoWrapper: {
    paddingTop: "3em",
    paddingBottom: "3em",
    width: "200px",
  },

  arrowIconWrapper: {
    background: "#262626",
    border: "1px solid #fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 25,
    height: 25,
    borderRadius: ".5rem",
    position: "fixed",
    left: 252,
    top: "5rem",
    cursor: "pointer",
    zIndex: theme.zIndex.appBar + 2,
    color: "#fff",
    transition: "left .25s ease-in-out",

    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  accordion: {
    "&.MuiPaper-root": {
      background: "transparent",
      marginBottom: "1rem",

      "&::before": {
        background: "transparent",
      },
    },
  },
  accordionSummary: {
    "&.MuiAccordionSummary-root": {
      padding: 0,
      paddingRight: 5,

      "& .MuiSvgIcon-root": {
        fill: "#fff",
      },

      "&.Mui-expanded": {
        minHeight: "auto",
      },
    },

    "& .MuiAccordionSummary-content": {
      margin: 0,

      "&.Mui-expanded": {
        margin: 0,
      },
    },

    "&:hover": {
      background: theme.palette.common.darkerGrey,
      borderRadius: 5,
    },
  },
  accordionDetails: {
    marginLeft: 0,
  },

  dropDownSelected: {
    "&.MuiAccordionSummary-root": {
      background: "theme.palette.common.darkerGrey",
      borderRadius: 5,
    },
  },
}));


const SideNav = (props) => {
  const [sideBar, setsideBar] = useState(true);
  const {
    selectedMenu,
    setSelectedMenu,
    menuSlideIn,
    setMenuSlideIn,
    selectedDropdown,
    setSelectedDropdown,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { logoutUser, clearUserData } = useActions();

  const handleLogout = () => {
    logoutUser();
    clearUserData();

    navigate("/", { replace: true });
  };

  useLayoutEffect(() => {
    [...menus].forEach((menu) => {
      switch (location.pathname) {
        case `/${menu.path}`:
          if (menu.id !== selectedMenu) {
            setSelectedMenu(menu.id);
          }
          break;
        default:
          break;
      }
    });

    // eslint-disable-next-line
  }, [location.pathname]);

  useLayoutEffect(() => {
    [...menus].map((menu) => {
      return (
        menu.hasOwnProperty("dropDowns") &&
        menu.dropDowns.forEach((dropdown) => {
          switch (location.pathname) {
            case `${dropdown.path}`:

              if (dropdown.subId !== selectedDropdown) {
                setSelectedDropdown(dropdown.subId);
                setSelectedMenu(menu.id);
              }
              break;
            default:
              break;
          }
        })
      );
    });

    // eslint-disable-next-line
  }, [location.pathname]);

  const handleHideNav = () => {
    let maxWidth = window.matchMedia("(max-width: 768px)")
    if (maxWidth.matches) {
      setsideBar((prev) => false)
    }
  }
  return (
    <ClickAwayListener onClickAway={handleHideNav}>
      <div>
        {sideBar === true ?   
          <Aside
            className={`${classes.aside} ${
              menuSlideIn ? classes.slideInMenu : classes.slideOutMenu
            }`}
            style={{
              display: sideBar === true ? 'block' : 'none',
              width: menuSlideIn ? "5rem" : "270px",
              paddingLeft: menuSlideIn ? ".5em" : null,
              paddingRight: menuSlideIn ? ".5em" : null,
            }}
          >
            <Exit><Cancel onClick={() => setsideBar((prev) => !prev)}/></Exit>
            <div
              className={classes.logoWrapper}
              style={{
                display: "flex",
                width: menuSlideIn ? "35px" : null,
                margin: menuSlideIn ? "0 auto" : null,
              }}
            >
              <img src={menuSlideIn ? logoSymbol : logo} alt="logo" />
              
            </div>
            <div
              className= {`arrowIconWrapper ${classes.arrowIconWrapper} `}
              onClick={() => setMenuSlideIn((prev) => !prev)}
              style={{ left: menuSlideIn ? "3.6rem" : undefined }}
            >
              {menuSlideIn ? (
                <ArrowForwardIosIcon style={{ fontSize: "1rem" }} />
              ) : (
                <ArrowBackIosNewIcon style={{ fontSize: "1rem" }} />
              )}
            </div>
            <List>
              {menus.map((menu) =>
                menu.hasOwnProperty("dropDowns") ? (
                  <Accordion                                                                                                                                                                                                   
                    elevation={0}
                    classes={{ root: classes.accordion }}
                    key={menu.id}
                  >
                    <AccordionSummary
                      expandIcon={!menuSlideIn ? <ExpandMoreIcon /> : null}
                      aria-controls="transaction-content"
                      id="transactions"
                      classes={{ root: classes.accordionSummary }}
                      className={`${
                        selectedMenu === menu.id ? classes.dropDownSelected : null
                      }`}
                    >
                      <ListItemButton
                        disableRipple
                        component={Link}
                        to={menu.path}
                        style={{
                          paddingLeft: menuSlideIn ? 2 : undefined,
                          paddingRight: menuSlideIn ? 2 : undefined,
                          justifyContent: menuSlideIn ? "center" : undefined,
                          marginBottom: 0,
                        }}
                        className={classes.dropDownMenu}
                      >
                        <ListItemIcon
                          style={{ minWidth: menuSlideIn ? "auto" : undefined }}
                        >
                          {React.createElement(menu.icon, {
                            size: 30,
                          })}
                        </ListItemIcon>

                        {!menuSlideIn ? (
                          <ListItemText onClick={handleHideNav}>{menu.title}</ListItemText>
                        ) : null}
                      </ListItemButton>
                    </AccordionSummary>
                    {!menuSlideIn ? (
                      <AccordionDetails
                        classes={{ root: classes.accordionDetails }}
                      >
                        {menu.dropDowns.map((dropdown) => (
                          <ListItemButton
                            key={dropdown.subId}
                            style={{
                              marginBottom: 5,
                              borderRadius: 5,
                              background:
                                location.pathname === `${dropdown.path}` &&
                                selectedDropdown === dropdown.subId
                                  ? theme.palette.common.darkerGrey
                                  : null,
                            }}
                            component={Link}
                            to={dropdown.path}
                            onClick={() => {
                              setSelectedDropdown(dropdown.subId);
                              setSelectedMenu(menu.id);
                            }}
                          >
                            <ListItemText onClick={handleHideNav}>{dropdown.title}</ListItemText>
                          </ListItemButton>
                        ))}
                      </AccordionDetails>
                    ) : null}
                  </Accordion>
                ) : (
                  <ListItemButton
                    disableRipple
                    key={menu.id}
                    onClick={() => {
                      setSelectedMenu(menu.id);
                    }}
                    selected={selectedMenu === menu.id}
                    component={Link}
                    to={menu.path}
                    style={{
                      paddingLeft: menuSlideIn ? 0 : undefined,
                      paddingRight: menuSlideIn ? 0 : undefined,
                      justifyContent: menuSlideIn ? "center" : undefined,
                    }}
                  >
                    <ListItemIcon
                      style={{ minWidth: menuSlideIn ? "auto" : undefined }}
                    >
                      {React.createElement(menu.icon, {
                        className: "menu-icon",
                      })}
                    </ListItemIcon>

                    {!menuSlideIn ? (
                      <ListItemText onClick={handleHideNav}>{menu.title}</ListItemText>
                    ) : null}
                  </ListItemButton>
                )
              )}
              <ListItemButton
                disableRipple
                classes={{ root: classes.logout }}
                style={{
                  paddingLeft: menuSlideIn ? 0 : undefined,
                  paddingRight: menuSlideIn ? 0 : undefined,
                  justifyContent: menuSlideIn ? "center" : undefined,
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  style={{ justifyContent: menuSlideIn ? "center" : "flex-start" }}
                >
                  <LogoutIcon />
                </ListItemIcon>

                {!menuSlideIn ? <ListItemText>Logout</ListItemText> : null}
              </ListItemButton>
            </List>
          </Aside> 
        : null }        
      <Svg><Menu className="mobNav" style={{color: "black", margin: "15px 0px 0px 5px",}} onClick={() => setsideBar((prev) => !prev)}/></Svg>
    </div>
    </ClickAwayListener>
  );
};

SideNav.propTypes = {
  selectedMenu: PropTypes.number.isRequired,
  setSelectedMenu: PropTypes.func.isRequired,
  setSelectedDropdown: PropTypes.func.isRequired,
  selectedDropdown: PropTypes.number.isRequired,
  setMenuSlideIn: PropTypes.func.isRequired,
  menuSlideIn: PropTypes.bool.isRequired,
};

export default SideNav;


const Aside = styled.div`
  @media (max-width: 768px) {
  display: none;   
  position: "fixed";

  .arrowIconWrapper {
    @media (max-width: 768px) {
      display: none;
    }

  }
}

`

const Svg = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    background: inherit;
    height: 60px;
    width: 10vw;
    position: absolute;
    margin-top: 14%;
    margin-left: 2%;

  }
`
const Exit = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    background: inherit;
    float: right;

  }
`


