import React from "react";

import styled from "styled-components";
import prettyMoney from "pretty-money";
import { Modal, Typography } from "@mui/material";
import { GiCancel } from "react-icons/gi";
import MenuItem from "@mui/material/MenuItem";
import Button from "components/common/Button";
import { useTheme } from "@mui/styles";

function BonuseView(props) {
  const theme = useTheme();
  const prettyDollarConfig = {
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  //   console.log(props.discount, "from props bon");
  return (
    <Modal open={props.open}>
      <BonusesListViewStyles className="bonD">
        <IconHolder onClick={() => props.setOpen(false)}>
          <GiCancel color="white" size={30} />
        </IconHolder>
        <h3>{props.title}</h3>
        <div style={{ marginTop: 10 }}>
          {props?.data &&
            Object.keys(props?.data).map((el) => (
              <li>
                <h5>{el}</h5>
                <h5 className="amount">{props.data[el]}</h5>
              </li>
            ))}
        </div>
        {!props.data && (
          <Typography
            style={{ textAlign: "center" }}
            variant="h5"
            component={"h5"}
          >
            Empty
          </Typography>
        )}
        <Button
          title="Withdraw Bonus"
          style={{
            boxShadow: "none",
            height: 45,
            fontWeight: 400,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 14,
            borderRadius: 20,
            marginTop: 20,
            width: "100%",
          }}
          disabled={
            props.title === "Discounted Salary"
              ? props?.discount?.canBeWithdrawn
                ? false
                : true
              : false
          }
          background={theme.palette.primary}
          onClick={() => props.request()}
        />
      </BonusesListViewStyles>
    </Modal>
  );
}

export default BonuseView;

const BonusesListViewStyles = styled.div`
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 55%;
  margin: 10% auto;
  @media (max-width: 1040px) {
    width: 95%;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: var(--main-bg);
  }

  li {
    display: flex;
    justify-content: space-between;
    padding-top: 13.5px;
    padding-bottom: 13.5px;

    &:not(:last-child) {
      border-bottom: 1px dotted var(--gray-1);
    }

    h5 {
      font-size: 15px;
      font-weight: 400;
    }

    .amount {
      color: black;
      font-size: 16px;
    }
  }
`;
const IconHolder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
`;
