import React, { useState } from "react";

import styled from "styled-components";

import HomeMain from "../components/home/home_main";
import SideNav from "../components/home/HomeNav";

function Home() {
  const [menuSlideIn, setMenuSlideIn] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [selectedDropdown, setSelectedDropdown] = useState(0);

  return (
    <>

    <HomeStyles>

      <SideNav
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        selectedDropdown={selectedDropdown}
        setSelectedDropdown={setSelectedDropdown}
        menuSlideIn={menuSlideIn}
        setMenuSlideIn={setMenuSlideIn}
      />
      <HomeMain menuSlideIn={menuSlideIn} />
    </HomeStyles>
    </>
    
  );
}

export default Home;

const HomeStyles = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
`;
