import Dashboard from "pages/dashboard";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "components/Routes/ProtectedRoute";
import Login from "pages/login";
import Terms from "pages/terms";
import How from "pages/how";


import Home from "pages/home";
import Commissions from "pages/commissions";
import NextOfKin from "components/common/next_kin";
import PersonalDetails from "components/common/personal_details";
import Address from "components/common/address";
import BankDetails from "components/common/bank_details";
import Properties from "pages/properties";
import UserProfile from "pages/UserProfile";
import Register from "pages/register";
import Referrals from "pages/referrals";
import FirstGenDownlines from "components/downlines/first_gen";
import SecondGenDownlines from "components/downlines/second_gen";
import Downlines from "pages/downlines";
import Settings from "pages/settings";
import ResetPassword from "pages/reset_password";
import UpdatePassword from "pages/update_password";
import Bio from "pages/bio";
import BioPersonalDetails from "components/bio/BioPersonalDetails";
import BioNextOfKin from "components/bio/BioNextOfKin";
import BioAddress from "components/bio/BioAddress";
import BioBankDetails from "components/bio/BioBankDetails";

import NotFound from "pages/not_found";
import MembershipBonus from "pages/membership_bonus";
import SalesBonus from "pages/sales_bonus";

const PagesRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/how-it-works" element={<How />} />

      <Route path="/register" element={<Register />} />
      <Route path="/register:ref" element={<Register />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<Home />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="commissions" element={<Commissions />} />
          <Route path="sales-bonus" element={<SalesBonus />} />
          <Route path="membership-bonus" element={<MembershipBonus />} />
          <Route path="properties" element={<Properties />} />
          <Route path="referrals" element={<Referrals />} />
          {/* <Route path="downlines" element={<Downlines />}>
            <Route path="first-gen" element={<FirstGenDownlines />} />
            <Route path="second-gen" element={<SecondGenDownlines />} /> */}
          {/* </Route> */}
          <Route path="downlines/:id" element={<FirstGenDownlines />} />

          <Route path="settings" element={<Settings />} />
        </Route>

        <Route path="profile" element={<UserProfile />}>
          <Route path="personal-details" element={<PersonalDetails />} />
          <Route path="address" element={<Address />} />
          <Route path="next-of-kin" element={<NextOfKin />} />
          <Route path="bank-details" element={<BankDetails />} />
        </Route>
        <Route path="bio" element={<Bio />}>
          <Route
            index
            path="personal-details"
            element={<BioPersonalDetails />}
          />
          <Route path="address" element={<BioAddress />} />
          <Route path="next-of-kin" element={<BioNextOfKin />} />
          <Route path="bank-details" element={<BioBankDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default PagesRoutes;
