export const banks = [
  "Access Bank",
  "Access Bank (Diamond)",
  "Accion Microfinance Bank",
  "Citibank Nigeria",
  "Coronation Merchant Bank",
  "Ecobank Nigeria",
  "Empire Trust Microfinance Bank",
  "FBNQuest Merchant Bank",
  "Fidelity Bank",
  "Fina Trust Microfinance Bank",
  "Finca Microfinance Bank",
  "First Bank of Nigeria",
  "First City Monument Bank",
  "FSDH Merchant Bank",
  "Globus Bank",
  "Guaranty Trust Bank",
  "Heritage Bank",
  "Infinity Microfinance Bank",
  "Jaiz Bank Plc",
  "Keystone Bank",
  "Kuda Bank",
  "Mint Finex MFB",
  "Mkobo MFB",
  "Mutual Trust Microfinance Bank",
  "Nova Merchant Bank",
  "Peace Microfinance Bank",
  "Pearl Microfinance Bank",
  "Polaris Bank",
  "Providus Bank",
  "Rand Merchant Bank",
  "Rephidim Microfinance Bank",
  "Rubies Bank",
  "Shepherd Trust Microfinance Bank",
  "Sparkle Bank",
  "Stanbic IBTC Bank",
  "Standard Chartered",
  "Sterling Bank",
  "SunTrust Bank Nigeria",
  "TAJBank",
  "Titan Trust Bank",
  "Union Bank of Nigeria",
  "United Bank for Africa",
  "Unity Bank",
  "VFD MFB",
  "Wema Bank",
  "Zenith Bank",
];
