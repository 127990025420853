import React, { useState, useEffect, Fragment } from "react";
import Button from "components/common/Button";
import { useTheme } from "@mui/styles";
import { countryList } from "helpers/dataset/countriesList";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import BioSectionTitle from "components/bio/bio_section_title";
import MLMTextField from "components/common/mlm_text_field1";
function BioAddress() {
  const navigate = useNavigate();
  const theme = useTheme();

  const { updateProfile } = useActions();
  const { userId } = useSelector((state) => state.auth);
  const { user, updatingProfile } = useSelector((state) => state.user);

  const [addressDetails, setAddressDetails] = useState({
    country: "",
    city: "",
    state: "",
    address: "",
  });

  const [stateError, setStateError] = useState("");
  const [addressError, setAddressError] = useState("");

  const { state, address } = addressDetails;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddressDetails({ ...addressDetails, [name]: value });

    switch (name) {
      case "state":
        if (!value) {
          setStateError("Please enter your state");
        } else {
          setStateError("");
        }
        break;
      case "address":
        if (!value) {
          setAddressError("Please enter your address");
        } else {
          setAddressError("");
        }
        break;

      default:
        setStateError("");
        setAddressError("");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    event.preventDefault();

    if (!state.trim() && !address.trim()) {
      setStateError("Please enter your state");
      setAddressError("Please enter your address");
      return;
    }

    if (!state.trim()) {
      setStateError("Please enter your state");
      return;
    }

    if (!address.trim()) {
      setAddressError("Please enter your address");
      return;
    }

    if (stateError || addressError) {
      return;
    }
    let x = user.id;

    updateProfile({
      x,
      navigate,
      nextPath: "/bio/next-of-kin",
      country: addressDetails.country,
      state: addressDetails.state,
      city: addressDetails.city,
      address: addressDetails.address,
    });

    // navigate("/profile/next-of-kin");
  };

  useEffect(() => {
    if (user) {
      setAddressDetails({
        ...addressDetails,
        country: user.country ? user.country : addressDetails.country,
        state: user.state ? user.state : "",
        city: user.city ? user.city : "",
        address: user.address ? user.address : "",
      });
    }

    // eslint-disable-next-line
  }, [user]);

  return (
    <AddressStyles>
      <BioSectionTitle
        title="Address"
        subTitle="Enter your address of residency"
      />

      <h6 className="title">Country</h6>
      <div className="countries-container">
        <select
          name="country"
          id="countries"
          value={addressDetails.country}
          onChange={handleChange}
        >
          <option selected value={"Nigeria"} key={"Nigeria"}>
            Nigeria
          </option>
          {countryList.map((country, index) => (
            <option value={country} key={index}>
              {country}
            </option>
          ))}
        </select>
      </div>

      <Row>
        <div style={{ width: "100%" }}>
          <MLMTextField
            width="100%"
            hintText="Enter your state"
            inputType="text"
            inputTextColor="var(--black)"
            labelText="State"
            labelColor="var(--black)"
            name="state"
            onChange={handleChange}
            fieldValue={addressDetails.state}
            divBorder={
              stateError ? `1px solid ${theme.palette.secondary.main}` : null
            }
          />
          {stateError && (
            <small style={{ color: theme.palette.secondary.main }}>
              {stateError}
            </small>
          )}
        </div>

        <MLMTextField
          width="100%"
          hintText="Enter your city"
          inputType="text"
          inputTextColor="var(--black)"
          labelText="City"
          labelColor="var(--black)"
          name="city"
          onChange={handleChange}
          fieldValue={addressDetails.city}
        />
      </Row>

      <h6 className="address">Address</h6>
      <textarea
        name="address"
        value={addressDetails.address}
        onChange={handleChange}
        placeholder="Enter address of residency"
        style={{
          border: addressError
            ? `1px solid ${theme.palette.secondary.main}`
            : null,
        }}
      ></textarea>
      {addressError && (
        <small style={{ color: theme.palette.secondary.main }}>
          {addressError}
        </small>
      )}

      <div className="proceed">
        <Button
          type="submit"
          title={
            updatingProfile ? (
              <Fragment>
                <CircularProgress size={25} style={{ marginRight: "0.5rem" }} />
                <Fragment>Save and Proceed</Fragment>
              </Fragment>
            ) : (
              "Save and proceed"
            )
          }
          background={theme.palette.secondary}
          onClick={handleSubmit}
        />
      </div>
    </AddressStyles>
  );
}

export default BioAddress;

const AddressStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0px 20px;
  /* margin-left: 25px; */

  .title {
    color: var(--black);
    margin-bottom: 1rem;
    font-size: 15px;
    margin-top: 3.5rem;
  }

  .countries-container {
    /* width: 93.5%; */
    width: 100%;
    height: 42px;
    background-color: var(--white);
    border: 1px solid var(--gray-1);
    border-radius: 8px;
    display: flex;
    align-content: center;
    padding-left: 10px;
    padding-right: 10px;

    select {
      width: 100%;
      border: none;
      outline: none;
      background-color: var(--white);
      color: var(--main-bg);

      option {
        color: var(--main-bg);
      }
    }
  }

  .address {
    color: var(--black);
    margin-bottom: 1rem;
    font-size: 15px;
    margin-top: 2rem;
  }

  textarea {
    resize: none;
    width: 100%;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    border: 1px solid var(--gray-1);
    border-radius: 8px;
    padding: 15px;
    color: var(--main-bg);
    background-color: white;
    font-size: 14px;
    margin-bottom: 0.25rem;
    /* margin-top: 1rem; */

    &:focus {
      outline: none;
    }
  }

  .proceed {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 2rem;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 1.5rem;

  @media (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
`;
