import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/styles";

import BioSectionTitle from "components/bio/bio_section_title";
import MLMTextField from "components/common/mlm_text_field1";

import SelectImageBkg from "assets/images/file-select-img.png";
import PhoneNumberInput from "components/common/PhoneNumberInput";
import axios from "axios";
import { BASE_URL } from "services/BASE_URL";
// import { getProfileData } from "../../features/profile";

// import jwtDecode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      background: theme.palette.secondary.main,
      borderRadius: 10,
      textTransform: "none",
      padding: 10,
      fontSize: 14,
      fontWeight: 300,

      "&:hover": {
        background: theme.palette.secondary.light,
      },

      "&:active": {
        background: theme.palette.secondary.dark,
        boxShadow: "none",
      },

      "&:disabled": {
        background: theme.palette.secondary.light,
      },
    },
  },
}));
function BioPersonalDetails() {
  const classes = useStyles();
  const theme = useTheme();

  const { updateProfile } = useActions();
  const { updatingProfile, user } = useSelector((state) => state.user);

  //   const savedUser = JSON.parse(localStorage.getItem("user"));
  // console.log(savedUser)

  //   const decodedUser = jwtDecode(savedUser);
  // console.log(decodedUser);

  //   const id = decodedUser.data._id;
  // console.log(id);

  //   const [base64String, setBase64String] = useState()
  //   const [selectedFile, setSelectedFile] = useState(undefined);

  // eslint-disable-next-line
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const [phoneNumber, setPhoneNumber] = useState("");

  const { userId } = useSelector((state) => state.auth);

  // const user = useSelector((state) => state.user)

  const initialStateValues = {
    firstName: "",
    lastName: "",
    email: "",
    date: "",
    gender: "",
  };
  const [personalDetails, setPersonalDetails] = useState(initialStateValues);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDOBError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const { firstName, lastName, date, gender } = personalDetails;

  const navigate = useNavigate();
  const handleUpload = async () => {
    const formData = new FormData(); //Create an instance of FormData
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append("files", selectedFile[i]);
    }
    // console.log(formData);
    await axios
      .patch(`${BASE_URL}/api/users/${user.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    handleUpload();

    if (
      !firstName.trim() &&
      !lastName.trim() &&
      !date.trim() &&
      (!gender || gender === "Select Your Gender") &&
      (!phoneNumber.trim() || phoneNumber === "234")
    ) {
      setFirstNameError("Please enter your first name");
      setLastNameError("Please enter your last name");
      setDOBError("Please enter your date of birth");
      setGenderError("Please select your gender");
      setPhoneNumberError("Please enter your phone number");
      return;
    }

    if (!firstName.trim()) {
      setFirstNameError("Please enter your first name");
      return;
    }

    if (!lastName.trim()) {
      setLastNameError("Please enter your last name");
      return;
    }

    if (!date.trim()) {
      setDOBError("Please enter your date of birth");
      return;
    }

    if (!phoneNumber.trim() || phoneNumber === "234") {
      setPhoneNumberError("Please enter your phone number");
    }

    if (!gender || gender === "Select Your Gender") {
      setGenderError("Please select your gender");
      return;
    }

    if (
      firstNameError ||
      lastNameError ||
      genderError ||
      dobError ||
      phoneNumberError
    )
      return;

    updateProfile({
      userId,
      navigate,
      nextPath: "/bio/address",
      firstName: personalDetails.firstName,
      lastName: personalDetails.lastName,
      email: personalDetails.email,
      phoneNumber: phoneNumber,
      date: personalDetails.date,
      gender: personalDetails.gender,
    });
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile[0]);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPersonalDetails({ ...personalDetails, [name]: value });

    switch (name) {
      case "firstName":
        if (!value) {
          setFirstNameError("Please enter your first name");
        } else {
          setFirstNameError("");
        }
        break;
      case "lastName":
        if (!value) {
          setLastNameError("Please enter your last name");
        } else {
          setLastNameError("");
        }
        break;
      case "date":
        if (!value) {
          setDOBError("Please enter your date of birth");
        } else {
          setDOBError("");
        }
        break;
      case "gender":
        if (!value || value === "Select your gender") {
          setGenderError("Please select your gender");
        } else {
          setGenderError("");
        }
        break;
      default:
        setFirstNameError("");
        setLastNameError("");
        setGenderError("");
        setDOBError("");
    }
  };

  const inputRef = useRef(null);

  const openFileSelectDialog = () => {
    inputRef.current.click();
  };

  // navigate("/profile/address");

  const handlePhoneChange = (value, data) => {
    setPhoneNumber(value);

    if (phoneNumber === data.dialCode) {
      setPhoneNumberError("Please enter your phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  useEffect(() => {
    if (user) {
      setPersonalDetails({
        ...personalDetails,
        firstName: user.firstName ? user.firstName : "",
        lastName: user.lastName ? user.lastName : "",
        email: user.email ? user.email : "",
        date: user.dateOfBirth ? user.dateOfBirth : "",
        gender: user.gender ? user.gender : "",
      });
      setPreview(user.avatar);
      setPhoneNumber(user.mobile ? user.mobile : "");
    }

    // eslint-disable-next-line
  }, [user]);

  return (
    <PersonalDetailsStyle>
      <BioSectionTitle
        title="Personal Details"
        subTitle="Enter your personal details including your profile image"
      />

      <form onSubmit={handleSubmit}>
        <div className="row" style={{ marginBottom: "2rem" }}>
          <div style={{ width: "100%" }}>
            <MLMTextField
              width="100%"
              hintText="Enter first name"
              inputType="text"
              inputTextColor="var(--black)"
              labelText="First name"
              labelColor="var(--black)"
              name="firstName"
              onChange={handleChange}
              fieldValue={personalDetails.firstName || ""}
              hintTextColor={"var(--gray-3)"}
              divBorder={
                firstNameError
                  ? `1px solid ${theme.palette.secondary.main}`
                  : null
              }
            />
            {firstNameError && (
              <small style={{ color: theme.palette.secondary.main }}>
                {firstNameError}
              </small>
            )}
          </div>
          <div style={{ width: "100%" }}>
            <MLMTextField
              width="100%"
              hintText="Enter last name"
              inputType="text"
              inputTextColor="var(--black)"
              labelText="Last name"
              labelColor="var(--black)"
              name="lastName"
              onChange={handleChange}
              fieldValue={personalDetails.lastName || ""}
              hintTextColor={"var(--gray-3)"}
              divBorder={
                lastNameError
                  ? `1px solid ${theme.palette.secondary.main}`
                  : null
              }
            />
            {lastNameError && (
              <small style={{ color: theme.palette.secondary.main }}>
                {lastNameError}
              </small>
            )}
          </div>
        </div>
        <br></br>

        <div style={{ marginBottom: "2rem" }}>
          <MLMTextField
            width="100%"
            hintText="Enter email address"
            inputType="email"
            inputTextColor="var(--black)"
            labelText="Email Address"
            labelColor="var(--black)"
            name="email"
            onChange={handleChange}
            fieldValue={personalDetails.email || ""}
            hintTextColor={"var(--gray-3)"}
            isDisabled={true}
          />
        </div>

        <div className="row">
          <div style={{ width: "100%" }}>
            <MLMTextField
              width="100%"
              inputType="date"
              inputTextColor="var(--black)"
              labelText="Date of birth (optional)"
              labelColor="var(--black)"
              name="date"
              onChange={handleChange}
              fieldValue={personalDetails.date || ""}
              hintTextColor={"var(--gray-3)"}
              divBorder={
                dobError ? `1px solid ${theme.palette.secondary.main}` : null
              }
            />
            {dobError && (
              <small style={{ color: theme.palette.secondary.main }}>
                {dobError}
              </small>
            )}
          </div>

          <div style={{ width: "100%" }}>
            <label
              style={{
                fontSize: "15px",
                display: "block",
                marginBottom: "1rem",
                fontWeight: 700,
                color: theme.palette.primary.main,
              }}
            >
              Phone Number
            </label>

            <PhoneNumberInput
              handlePhoneChange={handlePhoneChange}
              phoneNumber={phoneNumber}
              error={phoneNumberError}
            />
            {phoneNumberError && (
              <small style={{ color: theme.palette.secondary.main }}>
                {phoneNumberError}
              </small>
            )}
          </div>
          {/* <InternationalPhoneInput /> */}
        </div>

        <div className="row">
          <div className="gender">
            <h6>Gender</h6>

            <div style={{ width: "100%" }}>
              <div
                className="select-container"
                style={{
                  border: genderError
                    ? `1px solid ${theme.palette.secondary.main}`
                    : null,
                }}
              >
                <select
                  name="gender"
                  onChange={handleChange}
                  defaultValue={personalDetails.gender}
                >
                  <option value="Select your gender">Select Your Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Prefer Not to Say">Prefer Not to Say</option>
                </select>
              </div>
              {genderError && (
                <small style={{ color: theme.palette.secondary.main }}>
                  {genderError}
                </small>
              )}
            </div>
          </div>

          <div className="referrer-id" style={{ width: "100%" }}>
            <h6>Referrer's ID</h6>
            <input
              type="text"
              disabled
              defaultValue={user ? user.referrerCode : ""}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="profile-pic">
          <h6>Profile picture</h6>
          <div className="upload">
            <div className="circle-input" onClick={openFileSelectDialog}>
              <img
                src={preview != null ? preview : SelectImageBkg}
                className="circle-input-image"
                alt="preview"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <input
                className="input"
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={(e) => setSelectedFile(e.target.files)}
              />
            </div>
          </div>

          <hr />
        </div>

        <div className="proceed" type="submit">
          <Button
            disabled={updatingProfile}
            type="submit"
            variant="contained"
            className={classes.button}
          >
            {updatingProfile ? (
              <CircularProgress size={25} style={{ marginRight: "0.5rem" }} />
            ) : null}{" "}
            Save and proceed
          </Button>
        </div>
      </form>
    </PersonalDetailsStyle>
  );
}

export default BioPersonalDetails;

const PersonalDetailsStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  form {
    margin-top: 2rem;
    padding: 0px 20px;

    .row {
      display: flex;
      gap: 3rem;
    }

    .row {
      @media (max-width: 640px) {
        flex-direction: column;
        width: 100%;
      }
    }

    .row {
      .gender {
        margin-top: 2rem;
        width: 100%;

        h6 {
          color: var(--black);
          margin-bottom: 1rem;
          font-size: 15px;
        }

        .select-container {
          width: 100%;
          position: relative;
          padding-right: 15px;
          padding-left: 5px;
          border: 1px solid var(--gray-1);
          border-radius: 8px;

          select {
            width: 100%;
            height: 42px;
            color: var(--main-bg);
            padding-left: 0.3rem;
            outline: none;
            border: none;
            font-size: 15px;

            option {
              color: var(--main-bg);
            }
          }
        }
      }
    }

    .referrer-id {
      display: flex;
      flex-direction: column;
      align-content: center;
      margin-top: 2.2rem;

      h6 {
        font-size: 15px;
        color: var(--main-bg);
        margin-bottom: 1rem;
      }

      input {
        width: 100%;
        height: 42px;
        border: 1px solid var(--gray-1);
        border-radius: 8px;
        padding-left: 15px;

        &:disabled {
          background-color: var(--gray-4);
        }
      }
    }

    .profile-pic {
      margin-top: 28px;

      h6 {
        color: var(--black);
        font-size: 15px;
      }

      hr {
        margin-top: 36px;
        height: 0px;
        border: 1px solid rgba(4, 4, 6, 0.1);
      }

      .upload {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        gap: 3rem;
        align-items: center;

        .circle-input {
          height: 100px;
          width: 100px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          outline: none;
          background-color: var(--gray-1);
          overflow: hidden;
          /* border: 1px solid var(--gray-1); */

          svg {
            height: 20px;
            cursor: pointer;
          }
        }

        .input {
          background-color: var(--gray-1);
          height: 100px;
          width: 100px;
          border-radius: 50%;
          cursor: pointer;
          outline: none;
          opacity: 0;
          z-index: -1;
          display: none;
        }

        .input::-webkit-file-upload-button {
          visibility: hidden;
        }

        button {
          height: 40px;
          width: 145px;
          background-color: var(--white);
          border-radius: 8px;
          outline: none;
          border: 1px solid var(--gray-1);
          cursor: pointer;
          font-size: 15px;
        }
      }
    }

    hr {
      width: 100%;
      margin-right: 6rem;
    }

    .proceed {
      display: flex;
      flex-direction: row;
      justify-content: end;
      right: 0;
      margin-top: 1rem;
      /* margin-right: 6rem; */
    }
  }
`;
