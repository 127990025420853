import React from "react";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/style.css";
import "./PhoneNumber.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  phone: {
    "&.react-tel-input .flag-dropdown": {
      border: "1px solid green",
    },
  },
}));

const PhoneNumberInput = ({ phoneNumber, handlePhoneChange, error }) => {
  const classes = useStyles();

  return (
    <PhoneInput
      country={"ng"}
      value={phoneNumber}
      onChange={handlePhoneChange}
      enableSearch
      className={classes.phone}
      // style={{ border: error ? "1px solid red" : null, borderRadius: 8 }}
    />
  );
};

PhoneNumberInput.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  handlePhoneChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default PhoneNumberInput;
