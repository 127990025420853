import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import userReducer from "./user/userSlice";
import resetReducer from "./reset_password/resetSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    reset: resetReducer,
  },
});
