import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useActions } from "hooks/useActions";

import styled from "styled-components";
import LetterDrawable from "./letter_drawable";

import CopyIcon from "../../assets/icons/bank_details_icon.svg";
import { Alert, Snackbar } from "@mui/material";


function ProfileMenu({ showProfileMenu, user }) {
  const navigate = useNavigate();
  const { logoutUser } = useActions();
  const refLink = `${document.location.origin}/register?ref=${user?.myReferralCode}`
  const [open, setOpen] = useState(false);


  // For snackbar
  const handleClick = () => {
    setOpen(true);
  };
  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleLogout = () => {
    logoutUser();
    navigate("/login", { replace: true });
  };

  const handleNavigation = () => {
    navigate("/bio/personal-details");
  };

  const copyReferralLink = () => {
    if(user) {
      navigator.clipboard.writeText(refLink).then(function() {
        handleClick();
      });
    }
    return
  };

  return (
    <>
      <ProfileMenuStyles className="viewP" showProfileMenu={showProfileMenu}>
        <h3>Account</h3>
        <div className="row">
          <LetterDrawable />
          <div className="invite-link" onClick={copyReferralLink}>
            <h3>
              <p style={{ fontSize: "12px" }}>{refLink}</p>
              <span>
                <img src={CopyIcon} alt="copy-link-icon" />
              </span>
            </h3>
            <h5>Realtor's invitation link</h5>
          </div>
        </div>
        <hr />
        <h3 onClick={handleNavigation}>View Profile</h3>
        <h3 onClick={handleLogout}> Log out</h3>
      </ProfileMenuStyles>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" style={{ fontSize: "1rem"}}>
          Refferal link copied!
        </Alert>
      </Snackbar>
    </>
  );
}

export default ProfileMenu;

const ProfileMenuStyles = styled.div`
  position: absolute;
  background-color: var(--white);
  display: ${(props) => (props.showProfileMenu ? "flex" : "none")};
  flex-direction: column;
  gap: 1rem;
  z-index: 999;
  width: 342px;
  margin-left: -80px;
  margin-top: 45px;
  min-height: 150px;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(4, 4, 6, 0.1);
  padding: 1rem;

  h3 {
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    color: var(--main-bg);
    cursor: pointer;

    a {
      color: var(--main-bg);
      cursor: pointer;
    }

    &:first-child {
      pointer-events: none;
      cursor: none;
    }
  }

  hr {
    height: 0px;
    border: 1px solid rgba(4, 4, 6, 0.1);
  }

  .row {
    display: flex;
    gap: 1rem;

    .invite-link {
      display: flex;
      flex-direction: column;

      h3 {
        color: var(--invite-link);
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-content: center;

        span {
          img {
            height: 15px;
            width: 15px;
          }
        }
      }

      h5 {
        color: var(--gray-3);
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
      }
    }

    h5 {
      @media (max-width: 640px) {
        margin-left: -2px !important;
      }
    }
  }
`;
