import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles, useTheme } from "@mui/styles";

import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { useActions } from "hooks/useActions";

import styled from "styled-components";

import AuthImg from "../assets/images/auth-bg.png";
import Logo from "../assets/images/logo.png";

import MLMTextField from "../components/common/mlm_text_field";
import Checkbox from "../components/common/checkbox";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "100%",
      background: theme.palette.secondary.main,
      textTransform: "none",
      marginTop: "20px",
      borderRadius: 8,

      "&:hover": {
        background: theme.palette.secondary.light,
      },
      "&:active": {
        background: theme.palette.secondary.dark,
      },

      "&:disabled": {
        background: "#ffa8b6",
        cursor: "not-allowed !important",
      },
    },
  },
  response: {
    textAlign: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  error: {
    color: theme.palette.secondary.main,
  },

  success: {
    color: theme.palette.success.main,
  },
}));

function Login({ history }) {
  const classes = useStyles();
  const theme = useTheme();
  const { clearMessages, loginUser } = useActions();

  const { error, success, loading } = useSelector((state) => state.auth);

  const [form, setForm] = useState({ email: "", password: "", persist: false });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const { email, password } = form;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    switch (event.target.name) {
      case "email":
        if (
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(event.target.value)
        ) {
          setEmailError("");
        } else {
          setEmailError("Please enter a valid email");
        }
        break;
      case "password":
        if (!event.target.value) {
          setPasswordError("Password is required");
        } else {
          setPasswordError("");
        }
        break;
      default:
        setEmailError("");
        setPasswordError("");
    }
  };

  const handlePersistChange = () => {
    setForm({ ...form, persist: !form.persist });
  };

  // const handleLogin = () => {
  //     const {email, password} = form
  //     dispatch(login({email, password}))
  //         .unwrap()
  //         .then(()=>{
  //             navigate('/dashboard', {replace: true})
  //         })
  //         .catch(()=>{})
  // }

  const handleLogin = (event) => {
    event.preventDefault();

    if (!email.trim() && !password.trim()) {
      setEmailError("Email is required");
      setPasswordError("Password is required");
      return;
    }

    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    }

    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    }

    if (emailError || passwordError) return;

    loginUser({ email, password });

    // loadUser(userId && userId);
  };

  useEffect(() => {
    clearMessages();

    // eslint-disable-next-line
  }, []);

  if (localStorage.token && localStorage.status == "active") {
    return <Navigate to="/dashboard" />;
  } else if (localStorage.status == "inactive") {
    return <Navigate to="/dashboard" />;
  }

  // if (localStorage.token) {
  //   return <Navigate to="/dashboard" />;
  // }

  return (
    <LoginStyles>
      <div className="left">
        <div className="body">
          <div className="logo" />

          {/* {error ? (
            <p className={`${classes.response} ${classes.error}`}>{error}</p>
          ) : null}
          {success ? (
            <p className={`${classes.response} ${classes.success}`}>
              {success}
            </p>
          ) : null} */}
          <form onSubmit={handleLogin} className="authForm">
            <h3 style={{ marginTop: "2rem" }}>Sign In</h3>
            <br />
            {error ? (
              <p className={`${classes.response} ${classes.error}`}>{error}</p>
            ) : null}
            {success ? (
              <p className={`${classes.response} ${classes.success}`}>
                {success}
              </p>
            ) : null}
            <br />
            <div style={{ marginBottom: "2rem" }}>
              <MLMTextField
                midWith={true}
                borderColor={false}
                name="email"
                labelText="Email Address"
                inputType="email"
                hintText="Enter your email"
                fieldValue={form.email}
                onChange={handleChange}
                backgroundColor={"var(--main-bg)"}
                inputTextColor={"var(--white)"}
                hintTextColor={"var(--white)"}
                divBorder={
                  error ===
                  "Invalid login details, please enter a valid login details"
                    ? `1px solid ${theme.palette.secondary.main}`
                    : emailError
                    ? `1px solid ${theme.palette.secondary.main}`
                    : null
                }
              />
              {emailError && (
                <small style={{ color: theme.palette.secondary.main }}>
                  {emailError}
                </small>
              )}
            </div>
            <div>
              <MLMTextField
                midWith={true}
                borderColor={false}
                name="password"
                labelText="Password"
                inputType="password"
                hintText="Enter your password"
                fieldValue={form.password}
                onChange={handleChange}
                inputTextColor={"var(--white)"}
                backgroundColor={"var(--main-bg)"}
                hintTextColor={"var(--white)"}
                divBorder={
                  error ===
                  "Invalid login details, please enter a valid login details"
                    ? `1px solid ${theme.palette.secondary.main}`
                    : passwordError
                    ? `1px solid ${theme.palette.secondary.main}`
                    : null
                }
              />
              {passwordError && (
                <small style={{ color: theme.palette.secondary.main }}>
                  {passwordError}
                </small>
              )}
            </div>

            <Checkbox
              label="Keep me logged in"
              value={form.persist}
              onChange={handlePersistChange}
            />

            <Button
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={25} style={{ marginRight: "0.5rem" }} />
              ) : null}{" "}
              Login
            </Button>

            <Link to="/forgot-password" className="forgot">
              <p>Forgot your password?</p>
            </Link>

            <p className="create-account">
              Don't have an account? &nbsp;
              <span className="create-account-span">
                <Link to="/register">Sign Up</Link>
              </span>
            </p>
          </form>
        </div>
      </div>

      <div className="right"></div>
    </LoginStyles>
  );
}

export default Login;

const LoginStyles = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: var(--main-bg);
  display: flex;

  .right {
    flex: 1.3;
    background-image: url(${AuthImg});
    background-size: cover;
    background-repeat: no-repeat;
  }

  .left {
    flex: 0.7;
    padding: 3rem 5rem;

    @media (max-width: 768px) {
      flex: none;
      width: 100%;
    }

    .body {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;

      .logo {
        height: 40px;
        background-image: url(${Logo});
        background-repeat: no-repeat;
        // margin-bottom: 1rem;
        background-size: contain;

      }

      h3 {
        font-size: 30px;
        color: var(--white);
        // margin-bottom: 1rem;
      }

      .forgot {
        text-decoration: none;
        color: #fff;

        p {
          margin-top: 2rem;
          font-size: 13px;
        }
      }

      .create-account {
        font-size: 13px;
        margin-top: 2rem;

        .create-account-span a {
          color: var(--button-bg);
        }
      }
    }
  }
`;
