import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  :root{
    --white : white;
    --black: black;
    --main-bg: #171717;
    --main-black: #040406;
    --button-bg: #ff385c;
    --invite-link: #f8920f;
    --gray-1: #a2a2a2;
    --gray-2: #e5e5e5;
    --gray-3: #7d7d7e;
    --gray-4: #f2f2f2;
  }

  html{
    font-size: 15px;
    font-family: 'Inter';
    background-color: var(--white);
  }

  body {
    background-color: var(--gray-2)
  }
  
  input {
    color: var(--black);
  }
  ul,li{
    list-style: none;
  }

  a{
    text-decoration: none;
  }
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button{
    outline: none
  }

  .container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
  }

/* Smooth Scroll  */
  [data-scrollbar] {
    height: 160vh;
    overflow: hidden;
    background-color: var(--gray-1);
    .scroll-content {
      background-color: var(--main-bg);
    }
    .scrollbar-track.scrollbar-track-y {
      background: var(--main-bg);
      .scrollbar-thumb-y {
        background: var(--gray-1);
      }
    }
  }
`;
export default GlobalStyles;
