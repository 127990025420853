import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components/macro";
import { MiniSearchBar1 } from "../common/mini_search_bar";
import NotificationsMenu from "./notifications_menu";
import ProfileMenu from "./profile_menu";

import NotificationsIcon from "../../assets/icons/notification_icon.svg";
import { GrFormDown } from "react-icons/gr";
import LetterDrawable from "./letter_drawable";

function DashboardHeader() {
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    // const response = await
  };

  const headerRef = useRef();

  const handleClickOutside = (e) => {
    if (headerRef.current && !headerRef.current.contains(e.target)) {
      setShowNotificationsMenu(false);
      setShowProfileMenu(false);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleNotificationsMenu = () => {
    setShowProfileMenu(false);
    setShowNotificationsMenu(!showNotificationsMenu);
  };

  const toggleProfileMenu = () => {
    setShowNotificationsMenu(false);
    setShowProfileMenu(!showProfileMenu);
  };

  return (
    <DashboardHeaderStyles
      showNotificationsMenu={showNotificationsMenu}
      showProfileMenu={showProfileMenu}
      ref={headerRef}
    >
      <MiniSearchBar1
        name={searchQuery}
        marginLeft="40px"
        width="250px"
        onChange={handleChange}
        value={searchQuery}
        placeHolder="Search anything..."
      />

      <div></div>

      <div className="profile-actions-container">
        <div className="profile-actions">
          <div className="bell" onClick={toggleNotificationsMenu}>
            <img src={NotificationsIcon} alt="Notification icon" />
          </div>

          {user?.avatar ? (
            <img
              onClick={toggleProfileMenu}
              src={user?.avatar}
              alt="Profile"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                zIndex: "9999999",
              }}
            />
          ) : (
            <LetterDrawable toggleProfileMenu={toggleProfileMenu} />
          )}

          {/* Part i need to set has hidden */}
          <div className="full__progress">
            <div className="name-progress" onClick={toggleProfileMenu}>
              <div style={{ display: "flex" }}>
                <h5>
                  {user && user.firstName ? user.firstName : "Unknown"}{" "}
                  {user && user.lastName ? user.lastName : "User"}
                </h5>
                <GrFormDown onClick={toggleProfileMenu} />
              </div>

              <div className="progress-whole">
                <div className="current-progress"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Part i need to set has hidden */}

        <NotificationsMenu showNotificationsMenu={showNotificationsMenu} />

        <ProfileMenu showProfileMenu={showProfileMenu} user={user} />
      </div>
    </DashboardHeaderStyles>
  );
}

export default DashboardHeader;

const DashboardHeaderStyles = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  background-color: var(--white);
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 30px;

  @media (max-width: 768px) {
    width: 100vw;
  }

  // svg {
  //   display: none;

  // }

  // svg {
  //   @media (max-width: 768px) {
  //   display: block;
  //   height: 35px;
  //   }
  // }

  .profile-actions-container {
    display: flex;
    flex-direction: column;
    // width: 480px;

    .profile-actions {
      display: flex;
      height: inherit;
      align-items: center;
      gap: 1.5rem;
      cursor: pointer;
      // margin-left: 130px;

      .bell {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--gray-4);

        img {
          height: 25px;
          width: 25px;
          left: 50%;
          top: 50%;
          transform: translate(25%, 35%);
        }
      }

      .letter-drawable {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--button-bg);

        h5 {
          color: var(--white);
        }
      }
      .full__progress {
        @media (max-width: 768px) {
          display: none;
        }
      }

      .name-progress {
        display: flex;
        flex-direction: column;

        h5 {
          font-size: 15px;
          font-weight: 500;
          color: var(--main-bg);
          opacity: 1;
          display: block;
        }

        .progress-whole {
          width: 150px;
          height: 5px;
          background-color: var(--gray-4);
          border-radius: 5px;
          opacity: 1;
          display: block;

          .current-progress {
            width: 50px;
            height: 5px;
            background-color: var(--button-bg);
          }
        }
      }

      svg {
        width: 30px;
        height: 15px;
      }
    }
  }
`;
