import React from "react";

import styled from "styled-components";

function AppButton({ title, onClick, width, bgColor, marginTop }) {
  return (
    <AppButtonStyles
      onClick={onClick}
      width={width}
      bgColor={bgColor}
      marginTop={marginTop}
    >
      <h5>{title}</h5>
    </AppButtonStyles>
  );
}

export default AppButton;

const AppButtonStyles = styled.div`
  width: ${(props) => (props.width ? props.width : "400px")};
  height: 42px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "var(--button-bg)"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "2.5rem")};
  cursor: pointer;

  h5 {
    color: white;
    font-size: 15px;
  }
`;
