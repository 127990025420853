import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import TableData from "./TableData1";

import styled from "styled-components";

import PageHeader from "../common/page_header";
import { MiniSearchBar } from "../common/mini_search_bar";
import EmptyTable from "components/table-layouts/EmptyTable";
import DashboardHeader from "components/dashboard/header";
import { useSelector } from "react-redux";
import { useActions } from "hooks/useActions";

function SecondGenDownlines() {
  const [searchQuery, setSearchQuery] = useState("");
  const { userId } = useSelector((state) => state.auth);
  const { second_gen } = useSelector((state) => state.user);
  const { loadUserSecondGen } = useActions();
  const [pending, setPending] = useState(false);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };
  // console.log(second_gen, "whhuu");
  useEffect(() => {
    setPending(true);
    GetDownline(userId)
      .then((data) => {
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
      });
    //eslint-disable-next-line
  }, [userId]);

  const GetDownline = async (userId) => {
    return await loadUserSecondGen({ userId });
  };
  return (
    <DashboardStyles>
      <DashboardHeader />
      <SecondGenDownlinesStyles className="marginTop">
        <PageHeader
          title="Downlines - 2nd Gen"
          subTitle="This page displays the second generation downlines"
        />
        <div className="search-filter-export">
          <MiniSearchBar
            name={searchQuery}
            onChange={handleChange}
            value={searchQuery}
            placeHolder="Search client, name or property..."
          />
          <div className="filter-export">
            <div className="filter">
              <img src="/icons/filter_icon.svg" alt="icon" />
              <h3>Filters</h3>
            </div>
            <div className="export">
              <img src="/icons/export_icon.svg" alt="icon" />
              <h3>Export</h3>
            </div>
          </div>
        </div>
        <Grid
          container
          style={{ padding: "20px 20px", paddingRight: "2px", width: "99%" }}
        >
          {pending ? (
            <Box
              sx={{
                display: "flex",
                height: "400px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : second_gen? (
            <TableData downlinesRows={second_gen} />
          ) : (
            <EmptyTable />
          )}
        </Grid>
      </SecondGenDownlinesStyles>
    </DashboardStyles>
  );
}

export default SecondGenDownlines;

const DashboardStyles = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-left: -270px;
    width: 100vw;
  }
`;

const SecondGenDownlinesStyles = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  background-color: var(--gray-2);
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: -10px;
    margin-top: 2px !important;  }  

  .search-filter-export {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 10vh;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px dotted var(--gray-1);
  }  





  .filter-export {
    display: flex;
    margin-right: 28px;
    

    .export,
    .filter {
      margin-right: 10px;
      width: 100px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      background-color: var(--white);
      gap: 1rem;
      padding: 9px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      h3 {
        color: var(--gray-3);
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
      }
    }
  }


  .filter-export {
    @media(max-width: 912px) {
      margin-left: 40px; 
    }
    @media(min-width: 770px) {
      margin-left: 40px; 
    }
  }

  .filter {
    @media(max-width: 640px) {
      margin-top: 2px !important;
    }
  }

  .export {
    @media(max-width: 640px) {
      margin-top: 2px !important;
    }
  }
}
`;
