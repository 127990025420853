import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

import styled from "styled-components";

import PageHeader from "../components/common/page_header";
import { MiniSearchBar } from "../components/common/mini_search_bar";

import BuildingCard from "components/utils/BuildingCard";
import SinglePropertyModal from "./../components/modals/SingleProperty";
import EmptyProperty from "components/utils/EmptyProperty";
import DashboardHeader from "components/dashboard/header";
const { default: axios } = require("axios");
const { BASE_URL } = require("../services/BASE_URL");

const useStyles = makeStyles((theme) => ({
  propertiesSection: {
    width: "100%",
  },

  allProperties: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginTop: "20px",
  },
}));

function Properties() {
  const classes = useStyles();
  const theme = useTheme();

  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [properties, setProperties] = useState(false)
  const [pending, setPending] = useState(false)

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };


  useEffect(() => {
    setPending(true)
    GetProperties(searchQuery).then(data => {
      if(data.message === "successful"){
        setProperties(data.data.properties.properties)
        setPending(false)
      }
    }).catch(error => {
      console.log(error);
      setPending(false)
    })
  }, [searchQuery])



  const GetProperties = async (searchTerm, filter) => {
    try {
        const { data } = await axios.get(
          `${BASE_URL}/api/properties/all${searchTerm || filter ? "?" : ""}${searchTerm ? "search_term="+searchTerm+"&" : ""}${filter ? "filter=": ""}`, {
              headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
              }
          }
        );
  
        return data;
      } catch (error) {
          console.log(error.message);
      }
  }


  return (
    <DashboardStyles>
      <DashboardHeader />
      <PropertiesStyles>
        <PageHeader title="Properties" subTitle="See a list of properties managed by Whiterose" />
        <div className="search-sort">
          <MiniSearchBar name={searchQuery} onChange={handleChange} value={searchQuery} placeHolder="Search for a property..." />
          <div className="sort">
            <h3>Sort by</h3>
            <select defaultValue="All">
              <option value="All">
                All
              </option>
              <option value="Apartments">Apartments</option>
              <option value="Condimonium">Condominium</option>
              <option value="Commercial">Commercial</option>
              <option value="Land">Land</option>
              <option value="Others">Others</option>
            </select>
          </div>
        </div>

        <Typography
          variant="h4"
          style={{
            fontSize: 24,
            color: theme.palette.common.dark,
            margin: "20px 0px 0px 40px",
          }}
          className="propertyHeading"
        >
          All Properties ({properties.length || 0})
        </Typography>
        <div className={classes.propertiesSection}>
          <div className={classes.allProperties}>
          {pending ? 
            <Box sx={{ display: 'flex', height: "400px", width: "100%", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          :
            (properties.length > 0 ?
              properties.map((property) => (
              <BuildingCard
                key={property.id}
                property={property}
                setOpen={setOpen}
                setCurrentProperty={setCurrentProperty}
              />
              ))
            :
              <EmptyProperty />
            )}
          </div>
        </div>

        <SinglePropertyModal
          open={open}
          setOpen={setOpen}
          currentProperty={currentProperty}
        />
      </PropertiesStyles>
    </DashboardStyles>
  );
}

export default Properties;

const DashboardStyles = styled.div`
  height: auto;
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-left: -270px;
  }
`;

const PropertiesStyles = styled.div`
  height: 100%;
  min-height: 170vh;
  min-height: ${(props) =>
    props.emptyStateHeightEstimate ? "280px" : "100vh"};
  width: 100%;
  background-color: var(--gray-2);
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: -10px;
    margin-top: 2px !important;  }

  .search-sort {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px dotted var(--gray-1);

    select {
      border-radius: 8px;
    }
  }
  .search-sort {
    @media (max-width: 610px) {
      justify-content: center;
    }
  }

  .sort {
    margin-right: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 9px;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }

    h3 {
      color: var(--gray-3);
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
    }

    select {
      height: 32px;
      padding: 0px 10px;
      // padding-right: 20px;
      border: none;
      outline: none;
    }
  }

  .all-properties {
    margin-left: 38px;
    margin-right: 38px;
    margin-top: 30px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .properties {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    height: 600px;
  }
`;
