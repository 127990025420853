import React from "react";
import PropTypes from "prop-types";
import { Outlet, Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const location = useLocation();

  return localStorage.token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }),
};

export default ProtectedRoute;
