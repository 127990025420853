import PropTypes from "prop-types";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useTheme, makeStyles } from "@mui/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import ExportIcon from "../../assets/icons/export_icon1.svg";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { Cancel } from "@mui/icons-material";
import { Carousel } from "react-carousel-minimal";
import prettyMoney from "pretty-money";

import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  text: {
    "&.MuiTypography-root": {
      fontSize: "14px",
    },
  },
  closeIconWrapper: {
    "&.MuiIconButton-root": {
      background: "#fff",
      position: "absolute",
      top: 33,
      left: -70,

      "&:hover": {
        background: "#fff",
      },
    },
  },
  iconWrapper: {
    borderRadius: "50%",
    background: theme.palette.common.pink,
  },
}));

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const Exit = styled("div")`
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
`;

const style = {
  width: 500,
  height: "100vh",
  bgcolor: "background.paper",
  overflow: "scroll",
  overflowX: "hidden",
  p: 2,
  px: 4,
  pb: 3,
  transition: "all 1s ease-in",
};

const SinglePropertyModal = ({ open, setOpen, currentProperty }) => {
  const theme = useTheme();
  const classes = useStyles();
  const downloadImage = () => {
    let img = currentProperty?.banner;
    saveAs(img, "image.jpg"); // Put your image url here.
  };
  let PropertyDoc = "https://drive.google.com/file/d/1vuIBVSuKorxe287yA7t-VNqPP-DuHJRn/view?usp=share_link"

  const downloadImage1 = () => {
    let img = currentProperty?.propertyDoc;
    saveAs(img, "document.pdf"); // Put your image url here.
  };


  let img = [];
  if (currentProperty) {
    currentProperty.images.map((i) => {
      console.log(i.url);
      img.push({ image: i.url, caption: "" });
    });
  }
  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  const prettyDollarConfig = {
    currency: "₦",
    position: "before",
    spaced: false,
    thousandsDelimiter: ",",
  };
  let show = currentProperty?.banner == "null" ? false : true;
  console.log(show, "cccc");
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        BackdropComponent={Backdrop}
      >
        <Box sx={style} className="proModel">
          <IconButton
            className={classes.closeIconWrapper}
            onClick={() => setOpen(false)}
          >
            <CloseRoundedIcon className={classes.closeIcon} color="primary" />
          </IconButton>
          <Grid container direction="column">
            <Grid item style={{ marginBottom: "10px" }}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.text}
              >
                {currentProperty?.category}
              </Typography>
              <Exit>
                <Cancel
                  onClick={() => setOpen(false)}
                  style={{
                    color: "black",
                    fontSize: "40px",
                    marginTop: "-30px",
                    float: "right",
                  }}
                />
              </Exit>
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                style={{ fontSize: "18px", fontWeight: 700 }}
                color="primary"
                gutterBottom
              >
                {currentProperty?.name}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: "20px" }}>
              <Typography
                variant="body2"
                className={classes.text}
                color={theme.palette.common.grey}
              >
                {currentProperty?.address}
              </Typography>
            </Grid>
            {/* {currentProperty?.images?.map(
              (img, index) =>
                img.url && (
                  <Grid item style={{ marginBottom: "20px" }} key={index}>
                    <img
                      src={img.url}
                      alt={`${currentProperty?.title} view`}
                      style={{ height: "300px" }}
                    />
                  </Grid>
                )
            )} */}
            <Grid item style={{ marginBottom: "20px" }}>
              {currentProperty?.images && (
                // <img
                //   src={prevBuilding?.images[0]?.url || prevBuilding?.images[1]?.url}
                //   alt="Beach Home Villa"
                // />
                <Carousel
                  data={img}
                  time={2000}
                  width="850px"
                  height="500px"
                  captionStyle={captionStyle}
                  radius="10px"
                  // slideNumber={true}
                  // slideNumberStyle={slideNumberStyle}
                  // automatic={true}
                  dots={true}
                  pauseIconColor="white"
                  pauseIconSize="40px"
                  slideBackgroundColor="transparent"
                  slideImageFit="cover"
                  thumbnails={true}
                  thumbnailWidth="100px"
                  style={{
                    textAlign: "center",
                    maxWidth: "850px",
                    maxHeight: "auto",
                    margin: "40px auto",
                  }}
                />
              )}
            </Grid>

            <Grid item style={{ marginTop: "1rem", marginBottom: "1rem " }}>
              <Typography
                variant="body2"
                className={classes.text}
                style={{ textAlign: "justify", whiteSpace: "break-spaces" }}
                color="primary"
              >
                {currentProperty?.description}
              </Typography>
            </Grid>
            {/* <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              pt={2}
              pb={1}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color={theme.palette.common.grey}
                >
                  Unit available
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color="primary"
                >
                  {currentProperty?.units_available}
                </Typography>
              </Grid>
            </Grid>
            <Divider /> */}
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              pt={1}
              pb={1}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color={theme.palette.common.grey}
                >
                  City
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color="primary"
                >
                  {currentProperty?.city}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              pt={1}
              pb={1}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color={theme.palette.common.grey}
                >
                  State
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color="primary"
                >
                  {currentProperty?.state}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            {/* <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              pt={1}
              pb={1}
            >
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color={theme.palette.common.grey}
                >
                  Commission
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  className={classes.text}
                  color="primary"
                >
                  {currentProperty?.commission}%
                </Typography>
              </Grid>
            </Grid> */}
            <Divider />

            {currentProperty?.name === 'Lekki Town Square Mall' ? (



              <>

                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  pt={1}
                  pb={1}
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.text}
                      color={theme.palette.common.grey}
                    >
                      Ground Floor
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontSize: "1rem", fontWeight: 400 }}
                    >
₦1,350,000 per sqm                     </Typography>
                  </Grid>
                </Grid>
                <Divider />


                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  pt={1}
                  pb={1}
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.text}
                      color={theme.palette.common.grey}
                    >
                      First Floor
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontSize: "1rem", fontWeight: 400 }}
                    >
                    ₦1,200,000 per sqm
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />


                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  pt={1}
                  pb={1}
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.text}
                      color={theme.palette.common.grey}
                    >
                      2nd floor
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontSize: "1rem", fontWeight: 400 }}
                    >
                       ₦1,000,000
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />


                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  pt={1}
                  pb={1}
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.text}
                      color={theme.palette.common.grey}
                    >
                      Store size
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontSize: "1rem", fontWeight: 400 }}
                    >
                     38 sqm and above
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                </>)


              : (<>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  pt={1}
                  pb={1}
                >
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.text}
                      color={theme.palette.common.grey}
                    >
                      Price
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontSize: "1rem", fontWeight: 400 }}
                    >
                      {prettyMoney(prettyDollarConfig, currentProperty?.price)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider /></>)}

            {show && (
              <>
                {/* <Grid item style={{ margin: "10px 0px" }}>
                  <h1
                    style={{
                      fontSize: "24px",
                      fontWeight: "600px",
                      color: "#000",
                      margin: "30px 0px",
                    }}
                  >
                    Property Banner
                  </h1>
                  <img
                    src={currentProperty?.banner}
                    alt={`${currentProperty?.title} view`}
                  />
                </Grid> */}
                  <Grid item style={{ display: 'flex' }}>
                <button
                    onClick={downloadImage}
                    style={{
                      display: "flex",
                      background: "#FF385C",
                      border: "none",
                      outline: "none",
                      color: "#fff",
                      padding: "12px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginTop: "13%",
                    }}
                  >
                    <img
                      src={ExportIcon}
                      style={{
                        width: "17px",
                        objectFit: "contain",
                        marginRight: "5px",
                      }}
                      alt="export"
                    />{" "}
Download Image                  </button>

{currentProperty?.name === 'Lekki Town Square Mall' ? ( <button
                    onClick={downloadImage1}
                    style={{
                      display: "flex",
                      background: "rgb(23 23 23)",
                      border: "none",
                      outline: "none",
                      color: "#fff",
                      padding: "12px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginTop: "13%",
                      marginLeft: "5%"
                    }}
                  >
                    <img
                      src={ExportIcon}
                      style={{
                        width: "17px",
                        objectFit: "contain",
                        marginRight: "5px",
                      }}
                      alt="export"
                    />{" "}
Download PDF                  </button>):( <button                     onClick={downloadImage1}

                    style={{
                      display: "flex",
                      background: "rgb(23 23 23)",
                      border: "none",
                      outline: "none",
                      color: "#fff",
                      padding: "12px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      marginTop: "13%",
                      marginLeft: "5%"
                    }}
                  >
                    <img
                      src={ExportIcon}
                      style={{
                        width: "17px",
                        objectFit: "contain",
                        marginRight: "5px",
                      }}
                      alt="export"
                    />{" "}
Download PDF                  </button>)}

                 
                </Grid>
                <Grid
                  item
                  container
                  pt={1}
                  pb={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item style={{ marginTop: "7%" }}>
                    <Typography
                      variant="body1"
                      className={classes.text}
                      color="primary"
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: "900",
                        marginTop: "5px",
                      }}
                    >
                      Share
                    </Typography>
                  </Grid>
                  <Grid item style={{    marginTop: "8%"}}> 
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"

                    >
                      <Grid item className={classes.iconWrapper}>
                        <IconButton>
                          <FaFacebookF size={14} style={{ color: "#fff" }} />
                        </IconButton>
                      </Grid>
                      <Grid item mr={1} ml={1} className={classes.iconWrapper}>
                        <IconButton>
                          <FaWhatsapp size={14} style={{ color: "#fff" }} />
                        </IconButton>
                      </Grid>
                      <Grid item className={classes.iconWrapper}>
                        <IconButton>
                          <FaInstagram size={14} style={{ color: "#fff" }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </StyledModal>
    </div>
  );
};

SinglePropertyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  currentProperty: PropTypes.object,
};

export default SinglePropertyModal;
