import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../BASE_URL";
import axios from "axios";

const initialState = {
  loading: false,
  user: null,
  userId: localStorage.getItem("userId") || null,
  success: "",
  error: null,
};

export const registerUser = createAsyncThunk(
  "auth/signup",
  async (userData, thunkAPI) => {
    try {
      const { data, status } = await axios.post(
        `${BASE_URL}/api/auth/signup`,
        userData
      );

      if (status === 200) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("userId", data.data.user.id);
      }

      return data;
    } catch (error) {
      if (error.response.data.code === 400) {
        return thunkAPI.rejectWithValue(
          error.response.data.error.exceptionError
        );
      } else {
        return thunkAPI.rejectWithValue(error.response.data.error.userMessage);
      }
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const { data, status } = await axios.post(
        `${BASE_URL}/api/auth/login`,
        userData
      ); 

      if (status === 200) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("status", data.data.user.status);

        localStorage.setItem("userId", data.data.user.id);
      }

      return data;
    } catch (error) {
      if (error.response.data.code === 400) {
        return thunkAPI.rejectWithValue(error.response.data.error.userMessage);
      } else if (error.response.data.code === 500) {
        return thunkAPI.rejectWithValue("Something went wrong")
      } else {
        return thunkAPI.rejectWithValue(
          error.response.data.error.exceptionError
        );
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.error = null;
      state.success = "";
    },

    logoutUser: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("status");

      state.user = null;
      state.error = null;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
    },
    [registerUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.success = "Account Created Successfully";
      state.user = action.payload.data.user;
      state.error = null;
    },
    [registerUser.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.isAuthenticated = false;
      state.success = "";
    },
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.success = "You're logged in";
      state.user = action.payload.data.user; 
      state.error = null;
    },
    [loginUser.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
      state.userId = null;
      state.success = "";
    },
  },
});

export const { clearMessages, logoutUser } = authSlice.actions;

export default authSlice.reducer;
