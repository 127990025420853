import { BASE_URL } from "../BASE_URL";
import axios from "axios";

const resetPasswordUrl = `${BASE_URL}/api/auth/password/reset/init`
const updatePasswordUrl = `${BASE_URL}/api/auth/password/reset/complete`

export const otpRequest = email => {
    return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.post(resetPasswordUrl, { email });  
            console.log(data)
            resolve(data) 
        } catch (error) {
            reject(error);
        }
    })
}

export const userPasswordUpdate = passUpdate => {
    return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.post(updatePasswordUrl, passUpdate);  
            console.log(data)
            resolve(data) 
        } catch (error) {
            reject(error);
        }
    })
}