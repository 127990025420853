import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { sendResetOtp } from "../services/reset_password/resetAction";

import styled from "styled-components";

import AuthImg from "../assets/images/auth-bg.png";
import Logo from "../assets/images/logo.png";

import MLMTextField from "../components/common/mlm_text_field";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "85%",
      background: theme.palette.secondary.main,
      textTransform: "none",
      marginTop: "20px",
      borderRadius: 8,

      "&:hover": {
        background: theme.palette.secondary.light,
      },
      "&:active": {
        background: theme.palette.secondary.dark,
      },
    },
  },
  backBtn: {
    border: `1px solid ${theme.palette.common.grey}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 8,
    cursor: "pointer",
    color: "#fff",
  },
}));

function ResetPassword() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const { loading, message } = useSelector((state) => state.reset);

  const classes = useStyles();

  const handleResetSubmit = (event) => {
    event.preventDefault();

    dispatch(sendResetOtp(email));
  };

  // const handleChange = (e) => {
  //   const { email } = e.target;
  //   setEmail(email);
  // };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  if (localStorage.token) return <Navigate to="/update-password" />;
  return (
    <ResetPasswordStyles>
      <div className="left">
        <div style={{ marginTop: "-2rem", marginBottom: "4rem" }}>
          <Link to="/" className={classes.backBtn}>
            Back
          </Link>
        </div>
        <div className="body">
          <div className="logo" />
          <h3>Forgot password</h3>
          <br />
          {message === "successful" && (
            <Alert variant="outlined" severity={"success"} className="alert">
              <strong>OTP has been sent to your email</strong>
            </Alert>
          )}
          <br />
          <div
            style={
              message === "successful"
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <p style={{ marginBottom: "2rem" }}>
              Enter your email address and we will send a code to reset your
              password
            </p>

            <form onSubmit={handleResetSubmit}>
              <div style={{ marginBottom: "1rem" }}>
                <MLMTextField
                  midWith={true}
                borderColor={false}
                  name="email"
                  labelText="Email Address"
                  inputType="email"
                  hintText="Enter your email address"
                  fieldValue={email}
                  value={email}
                  onChange={handleChange}
                  backgroundColor={"var(--main-bg)"}
                  inputTextColor={"var(--white)"}
                  hintTextColor={"var(--white)"}
                />
              </div>

              {/* <AppButton
                title="Confirm email"
                // onClick={navigateFromPage}
              /> */}
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
              >
                {loading ? (
                  <CircularProgress
                    size={25}
                    style={{ marginRight: "0.5rem" }}
                  />
                ) : null}{" "}
                Confirm email
              </Button>
            </form>
          </div>
          <br />
          <br />
          {message === "successful" && (
            <Link
              to="/update-password"
              className={message === "successful" ? "" : "disabled-link"}
            >
              <Button
                type="button"
                variant="contained"
                className={classes.button}
                style={{ width: "35%", fontWeight: "500" }}
              >
                Proceed
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="right"></div>
    </ResetPasswordStyles>
  );
}

export default ResetPassword;

const ResetPasswordStyles = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: var(--main-bg);
  display: flex;
  flex-direction: row;

  .right {
    flex: 1;
    background-image: url(${AuthImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-size: contain;

  }

  .left {
    flex: 0.7;
    padding-left: 5rem;
    padding-right: 4.8rem;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @media (max-width: 1114px) {
      flex: 0.9;
    }

    @media (max-width: 768px) {
      flex: none;
      width: 100%;
      padding: 3rem 2rem;
    }

    .body {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;

      .logo {
        height: 40px;
        background-image: url(${Logo});
        background-repeat: no-repeat;
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 30px;
        color: var(--white);
        margin-bottom: 0.5rem;
      }
    }
  }

  .disabled-link {
    pointer-events: none;
  }

  .alert {
    font-size: 1.3rem;
  }
`;
