import React from "react";

import styled from "styled-components";

import { FiSearch } from "react-icons/fi";

export function MiniSearchBar({
  name,
  onChange,
  value,
  placeHolder,
  width,
  marginLeft,
}) {
  return (
    <MiniSearchBarStyles
      className="searchSI "
      width={width}
      marginLeft={marginLeft}
    >
      <FiSearch />
      <input
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
      />
    </MiniSearchBarStyles>
  );
}


export function MiniSearchBar1({
  name,
  onChange,
  value,
  placeHolder,
  width,
  marginLeft,
}) {
  return (
    <MiniSearchBarStyles
      className=" anyS"
      width={width}
      marginLeft={marginLeft}
    >
      <FiSearch />
      <input
        className=" anySI"
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
      />
    </MiniSearchBarStyles>
  );
}


const MiniSearchBarStyles = styled.div`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "38px")};
  width: ${(props) => (props.width ? props.width : "330px")};
  height: 40px;
  border-radius: 8px;
  display: flex;
  padding: 20px 5px;
  align-items: center;
  background-color: var(--white);
  border: 1px solid rgba(4, 4, 6, 0.1);
   
  @media(max-width: 640px) {
    margin-top: -1px;
  }

  svg {
    width: 30px;
    height: 30px;
    padding-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  input {
    width: ${(props) => (props.width ? "170px" : "320px")};
    height: 25px;
    border: none;
    outline: none;
    border-radius: 8px;
    padding-right: 10px;
    color: var(--main-bg);
  }


`;
